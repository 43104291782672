import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import AuthContext from '../AuthContext'
import '../styles/CompletedAnalyses.css'
import { BASE_URL } from '../config'
import DocumentViewer from './DocumentViewer'

export default function CompletedAnalyses() {
  const [files, setFiles] = useState([])
  const [error, setError] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const { userToken } = useContext(AuthContext)

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/file/list`,
          {
            page: '1',
            size: '10',
            file_queue: 'back-client',
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )

        if (response.data.status === 1) {
          console.log('3t82t3821t378t283', response.data)
          setFiles(response.data.data)
        } else {
          setError(response.data.message)
        }
      } catch (error) {
        console.error('Error fetching files:', error)
        setError('Failed to fetch files')
      }
    }
    fetchFiles()
  }, [userToken])

  const handleDownload = async (file, e) => {
    e.stopPropagation()
    try {
      const response = await axios.get(
        `${BASE_URL}/user/download/${file._id}`,
        {
          responseType: 'blob', // Important for downloading files
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', file.filename) // or the filename you want
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  // const handleViewClick = (file, e) => {
  //   console.log('345871348713548753', file)
  //   e.stopPropagation()
  //   setSelectedFile(file)
  // }

  if (selectedFile) {
    return (
      <DocumentViewer
        file={selectedFile}
        handleBack={() => setSelectedFile(null)}
      />
    )
  }

  return (
    <div className="completed-analyses">
      <table className="file-table">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Date/Time Submitted</th>
            {/* <th>Date/Time Completed</th> */}
            <th>Buyer/Seller</th>
            <th>Risk Score</th>
            {/* <th>Assessment Performed</th>
            <th>Overall Risk Score</th> */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file) => (
            <tr key={file._id}>
              <td>{file.filename}</td>
              <td>{new Date(file.added_date).toLocaleString()}</td>
              {/* <td>
                {file.completedDateTime
                  ? new Date(file.completedDateTime).toLocaleString()
                  : 'N/A'}
              </td> */}
              <td>{file.file_answer_mode || 'N/A'}</td>
              <td>
                {file.risk_score != null
                  ? `${(file.risk_score * 100).toFixed(2)}%`
                  : 'N/A'}
              </td>

              {/* <td>{file.assessmentPerformed || 'N/A'}</td> */}
              {/* <td>{file.score || 'N/A'}</td> */}
              <td>
                {/* <button onClick={(e) => handleViewClick(file, e)}>View</button> */}
                <button onClick={(e) => handleDownload(file, e)}>
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {error && <p className="error">{error}</p>}
    </div>
  )
}
