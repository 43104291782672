import React, { useContext, useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import AuthContext from "../AuthContext";

const CompareApexChart = (props) => {
  const itemsPerPage = 10; // Set items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [riskScores, setRiskScores] = useState([]);
  const { userToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [allData, setallData] = useState([]);
  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  useEffect(() => {
    var demoData = [];
    for (
      let index = 0;
      index < Object.keys(props.subcategoryScores).length;
      index++
    ) {
      demoData.push({
        documentName: Object.keys(props.subcategoryScores)[index],
        riskScore1: Object.values(props.subcategoryScores)[index].toFixed(2),
        riskScore2: Object.values(props.subcategoryScores)[index].toFixed(2),
      });
    }
    setallData(demoData);
    setRiskScores(demoData);
  }, [userToken]);
  // Slice data based on pagination
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const pageData = allData.slice(startIndex, startIndex + itemsPerPage);

    return {
      categories: pageData.map((item) => item.documentName),
      riskScore1Data: pageData.map((item) => item.riskScore1 * 100),
      riskScore2Data: pageData.map((item) =>
        item.riskScore2 > 0 ? getRandomNumber(5, 20) : 0
      ),
    };
  };

  const { categories, riskScore1Data, riskScore2Data } = getCurrentPageData();

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: "20px",
      },
    },
    xaxis: {
      categories,
      labels: {
        rotate: -45,

        style: {
          fontSize: "8px",
        },
      },
    },
    yaxis: {
      tickAmount: 10, // Y-axis increments in steps of 10
      labels: {
        formatter: (val) => `${val}%`, // Format the y-axis as percentages
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    title: {
      text: `Company Standard Comparison for Page ${currentPage}`,
    },
    colors: ["#008FFB", "#ff9b00"], // Custom colors for bars
  };

  const chartSeries = [
    {
      name: "",
      data: riskScore1Data,
    },
    {
      name: "",
      data: riskScore2Data,
    },
  ];

  // Pagination handlers
  const nextPage = () => {
    if (currentPage < Math.ceil(allData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="chart-wrapper">
      <h2 className="chart-title">Company Standard</h2>

      <div id="chart3" className="chart-container">
        {!props.subcategoryScores && (
          <div className="overlay">
            <p className="overlay-text">No data available</p>
          </div>
        )}
        <div className={props.subcategoryScores ? "" : "blur-chart"}>
          {allData.length > 0 && (
            <ApexCharts
              options={chartOptions}
              series={chartSeries}
              type="bar"
              height={350}
            />
          )}
          <div style={{ marginTop: "20px" }}>
            <button onClick={prevPage} disabled={currentPage === 1}>
              Previous
            </button>
            <button
              onClick={nextPage}
              disabled={
                currentPage === Math.ceil(allData.length / itemsPerPage)
              }
            >
              Next
            </button>
            <p>Page {currentPage}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareApexChart;
