import React, { useEffect, useState, useContext, useRef } from "react";
import Chart from "../chart.js"; // Ensure the correct path to your Chart component
import "../chart.js";
import "../../styles/main.css";
import AuthContext from "../../AuthContext.js";
import { BASE_URL } from "../../config.js";
import axios from "axios";
import Header from "../Header.js";

const HomePage = () => {
  const { userToken } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [averageTime, setAverageTime] = useState(0);
  const [highestRisk, setHighestRisk] = useState(null);
  const [lowestRisk, setLowestRisk] = useState(null);
  const [processingDoc, setProcessingDoc] = useState(0);
  const { userRole } = useContext(AuthContext);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/${userRole}/file/list`,
          {
            page: "1",
            size: "10",
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === 1) {
          setTotalDocuments(response.data.total_doc_count);
          setProcessingDoc(response.data.progress_doc_count);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError("Failed to fetch files");
      }
    };

    const fetchAverageEstimatedTime = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/${userRole}/files/average-time-difference`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.status === 200) {
          const { averageTimeDifference, highestRiskScore, lowestRiskScore } =
            response.data;
          setAverageTime(averageTimeDifference.toFixed(2));
          setHighestRisk((highestRiskScore * 100).toFixed(2));
          setLowestRisk((lowestRiskScore * 100).toFixed(2));
        } else {
          setError("Failed to fetch average estimated time");
        }
      } catch (error) {
        setError("Failed to fetch average estimated time");
      }
    };

    fetchFiles();
    fetchAverageEstimatedTime();
  }, [userToken, userRole]);

  console.log("83748237t48", error);

  return (
    <div className="wrapper">
      <div className="content-area px-4 pt-3" style={{ marginLeft: "0px" }}>
        <header className="header-flex">
          <div></div>
          <Header />
        </header>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12  ">
              <h3 className="title w-700 mt-0">Dashboard</h3>
            </div>
          </div>

          <div className="row px-2">
            <StatCard title="All Documents" value={totalDocuments} />
            <StatCard title="Processing Docs" value={processingDoc} />
            <StatCard
              title="Average Estimated Time (in Hours)"
              value={`${averageTime}`}
            />
            <StatCard
              title="Highest Risk Value"
              value={highestRisk !== null ? `${highestRisk}%` : "N/A"}
            />
            <StatCard
              title="Lowest Risk Value"
              value={lowestRisk !== null ? `${lowestRisk}%` : "N/A"}
            />
          </div>

          <div className="row mt-1 pb-5 mb-5">
            <div className="col-12">
              <div className="cards">
                {/* Chart component */}
                <div className="row" style={{ width: "78vw" }}>
                  <div className="col-12" style={{ width: "78vw" }}>
                    <Chart />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StatCard = ({ title, value }) => {
  return (
    <div className="col px-1">
      <div className="cards dashcards">
        <p className="font-10 mb-1">{title}</p>
        <h4 className="mb-0">{value}</h4>
      </div>
    </div>
  );
};

export default HomePage;
