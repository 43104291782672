// QAFetchContext.js
import React, { createContext, useState, useEffect } from "react";

const QAFetchContext = createContext();

export const QAFetchProvider = ({ children }) => {
  const [isFetchingQA, setIsFetchingQA] = useState(false);
  const [processingFiles, setProcessingFiles] = useState([]);

// Add a file object to the processing list
const addFileToProcessing = (file) => {
  setProcessingFiles((prevFiles) => {
    if (!prevFiles.find((f) => f._id === file._id)) {
      return [...prevFiles, file];
    }
    return prevFiles;
  });
};

// Remove a file object from the processing list by file ID
const removeFileFromProcessing = (file) => {
  setProcessingFiles((prevFiles) => prevFiles.filter((f) => f._id !== file._id));
};

  return (
    <QAFetchContext.Provider value={{ isFetchingQA, setIsFetchingQA, addFileToProcessing, removeFileFromProcessing, processingFiles }}>
      {children}
    </QAFetchContext.Provider>
  );
};

export default QAFetchContext;