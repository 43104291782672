// import React, { createContext, useState, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'

// const AuthContext = createContext()

// export const AuthProvider = ({ children }) => {
//   const navigate = useNavigate()
//   const [isAuthenticated, setIsAuthenticated] = useState(
//     JSON.parse(localStorage.getItem('isAuthenticated')) || false
//   )
//   const [userEmail, setUserEmail] = useState(
//     localStorage.getItem('userEmail') || ''
//   )
//   const [userToken, setUserToken] = useState(
//     localStorage.getItem('userToken') || ''
//   )
//   const [userRole, setUserRole] = useState(
//     localStorage.getItem('userRole') || ''
//   )

//   useEffect(() => {
//     localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated))
//   }, [isAuthenticated])

//   useEffect(() => {
//     localStorage.setItem('userEmail', userEmail)
//   }, [userEmail])

//   useEffect(() => {
//     localStorage.setItem('userToken', userToken)
//   }, [userToken])

//   useEffect(() => {
//     localStorage.setItem('userRole', userRole)
//   }, [userRole])

//   const login = (email, token, role) => {
//     setIsAuthenticated(true)
//     setUserEmail(email)
//     setUserToken(token)
//     setUserRole(role)
//   }

//   const logout = () => {
//     setIsAuthenticated(false)
//     setUserEmail('')
//     setUserToken('')
//     setUserRole('')

//     localStorage.removeItem('isAuthenticated')
//     localStorage.removeItem('userEmail')
//     localStorage.removeItem('userToken')
//     localStorage.removeItem('userRole')
//     localStorage.removeItem('buyerSeller')
//     localStorage.removeItem('selectedCategory')

//     navigate('/')
//   }

//   return (
//     <AuthContext.Provider
//       value={{
//         isAuthenticated,
//         userEmail,
//         userToken,
//         userRole,
//         login,
//         logout,
//       }}
//     >
//       {children}
//     </AuthContext.Provider>
//   )
// }

// export default AuthContext

import React, { createContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(
    JSON.parse(localStorage.getItem('isAuthenticated')) || false
  )
  const [userEmail, setUserEmail] = useState(
    localStorage.getItem('userEmail') || ''
  )
  const [userToken, setUserToken] = useState(
    localStorage.getItem('userToken') || ''
  )
  const [userRole, setUserRole] = useState(
    localStorage.getItem('userRole') || ''
  )

  const [userId, setUserId] = useState(
    localStorage.getItem('userId') || ''
  )

  const [fname, setFname] = useState( 
    localStorage.getItem('fname') || ''
  )

  const [lname, setLname] = useState(
    localStorage.getItem('lname') || ''
  )




  // Function to check token expiration
  const isTokenExpired = (token) => {
    if (!token) return true
    const decodedToken = jwtDecode(token)
    const currentTime = Date.now() / 1000
    return decodedToken.exp < currentTime
  }

  useEffect(() => {
    if (userToken && isTokenExpired(userToken)) {
      logout()
    }
  }, [userToken])

  useEffect(() => {
    localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated))
  }, [isAuthenticated])

  useEffect(() => {
    localStorage.setItem('userEmail', userEmail)
  }, [userEmail])

  useEffect(() => {
    localStorage.setItem('userToken', userToken)
  }, [userToken])

  useEffect(() => {
    localStorage.setItem('userRole', userRole)
  }, [userRole])

  useEffect(() => {
    localStorage.setItem('userId', userId)
  }
  , [userId])

  useEffect(() => {
    localStorage.setItem('fname', fname)
  }
  , [fname])

  useEffect(() => {
    localStorage.setItem('lname', lname)
  }
  , [lname])







  const login = (email, token, role, userId, fname, lname) => {
    console.log(userId)
    setIsAuthenticated(true)
    setUserEmail(email)
    setUserToken(token)
    setUserRole(role)
    setUserId(userId)
    setFname(fname)
    setLname(lname)
  }

  const logout = () => {
    setIsAuthenticated(false)
    setUserEmail('')
    setUserToken('')
    setUserRole('')
    setUserId('')
    setFname('')
    setLname('')

    localStorage.removeItem('isAuthenticated')
    localStorage.removeItem('userEmail')
    localStorage.removeItem('userToken')
    localStorage.removeItem('userRole')
    localStorage.removeItem('buyerSeller')
    localStorage.removeItem('selectedCategory')
    localStorage.removeItem('userId')
    localStorage.removeItem('fname')
    localStorage.removeItem('lname')

    navigate('/')
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userEmail,
        userToken,
        userRole,
        login,
        logout,
        userId,
        fname,
        lname

      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
