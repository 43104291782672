import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import AuthContext from '../../AuthContext'
import '../../styles/Home.css'
import '../../styles/QaAdminTools.css'
import { BASE_URL } from '../../config'

export default function AdminTools() {
  const [admins, setAdmins] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [questions, setQuestions] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [error, setError] = useState('')
  const [originalQuestion, setOriginalQuestion] = useState({})
  const [newAdmin, setNewAdmin] = useState({
    email: '',
    username: '',
    password: '',
    admin_type: 'qa',
    full_name: '',
    company_name: '',
  })
  const [newQuestion, setNewQuestion] = useState('')
  const [newOptions, setNewOptions] = useState([''])
  const [isAddingQuestion, setIsAddingQuestion] = useState(false)
  const [isEditing, setIsEditing] = useState(false) // State to track if editing is in progress
  const [editAdmin, setEditAdmin] = useState(null) // State to hold the admin being edited

  const { userToken } = useContext(AuthContext)

  useEffect(() => {
    fetchAdmins()
    fetchCategories()
  }, [userToken, isEditing])

  const fetchAdmins = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/qa-admin/admins`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      })

      if (response.data.status === 1) {
        setAdmins(
          response.data.data.filter((admin) => admin.admin_type !== 'qa_admin')
        )
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      console.error('Error fetching admins:', error)
      setError('Failed to fetch admins')
    }
  }

  const fetchCategories = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/category/list`,
        {
          page: '0',
          size: '10',
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data && response.data.status === 1) {
        setCategories(response.data.categories)
      } else {
        setError(response.data?.data?.message || 'Failed to fetch categories')
      }
    } catch (error) {
      console.error('Error fetching categories:', error)
      setError('Failed to fetch categories')
    }
  }

  const fetchQuestions = async (categoryId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/global/questions-by-categories/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.status === 200) {
        setQuestions(response.data.questions)
        setCurrentQuestionIndex(0)
        setOriginalQuestion(response.data.questions[0]) // Set the original question for revert
      }
    } catch (error) {
      console.error('Error fetching questions:', error)
    }
  }

  const toggleAdminStatus = async (adminId, currentStatus, email) => {
    try {
      const newStatus = currentStatus === 1 ? 0 : 1
      const response = await axios.post(
        `${BASE_URL}/qa-admin/admin/update-status`,
        { admin_id: adminId, admin_status: newStatus, email: email },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        fetchAdmins()
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      console.error('Error updating admin status:', error)
      setError('Failed to update admin status')
    }
  }

  const deleteAdmin = async (adminId) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/qa-admin/admin/delete/${adminId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        fetchAdmins()
        alert(response.data.message)
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      console.error('Error deleting admin:', error)
      setError('Failed to delete admin')
    }
  }

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value
    setSelectedCategory(categoryId)
    fetchQuestions(categoryId)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewAdmin({ ...newAdmin, [name]: value })
  }

  const handleAddAdmin = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/qa-admin/user/addAdmin`,
        {
          ...newAdmin,
          company_email: newAdmin.email,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        setNewAdmin({
          email: '',
          username: '',
          password: '',
          admin_type: 'qa',
          full_name: '',
          company_name: '',
        })
        fetchAdmins()
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      console.error('Error adding admin:', error)
      setError('Failed to add admin')
    }
  }

  const handleQuestionInputChange = (e, index) => {
    const { name, value } = e.target
    const updatedQuestions = [...questions]
    updatedQuestions[index] = { ...updatedQuestions[index], [name]: value }
    setQuestions(updatedQuestions)
  }

  const handleOptionChange = (e, optionIndex, field) => {
    // const value = e.target.value
    // const updatedQuestions = [...questions]
    // const currentQuestion = updatedQuestions[currentQuestionIndex]
    // const options = JSON.parse(currentQuestion.answers)
    // options[optionIndex] = value
    // currentQuestion.answers = JSON.stringify(options)
    // setQuestions(updatedQuestions)

    const value =
      field === 'seller_score' || field === 'buyer_score'
        ? parseFloat(e.target.value)
        : e.target.value
    const updatedQuestions = [...questions]
    const currentQuestion = updatedQuestions[currentQuestionIndex]
    const options = JSON.parse(currentQuestion.answers)

    // Update the specific field in the option
    options[optionIndex][field] = value

    currentQuestion.answers = JSON.stringify(options)
    setQuestions(updatedQuestions)
  }

  const addOption = () => {
    const updatedQuestions = [...questions]
    const currentQuestion = updatedQuestions[currentQuestionIndex]
    const options = JSON.parse(currentQuestion.answers)
    options.push({
      answer: '',
      seller_score: 0.0,
      buyer_score: 0.0,
    })
    currentQuestion.answers = JSON.stringify(options)
    setQuestions(updatedQuestions)
  }

  const deleteOption = (optionIndex) => {
    const updatedQuestions = [...questions]
    const currentQuestion = updatedQuestions[currentQuestionIndex]
    const options = JSON.parse(currentQuestion.answers)
    options.splice(optionIndex, 1)
    currentQuestion.answers = JSON.stringify(options)
    setQuestions(updatedQuestions)
  }

  const saveQuestion = async () => {
    try {
      const currentQuestion = questions[currentQuestionIndex]
      const response = await axios.put(
        `${BASE_URL}/qa-admin/question/${currentQuestion._id}`,
        currentQuestion,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status !== 1) {
        setError(response.data.message)
      } else {
        setOriginalQuestion(currentQuestion) // Update the original question after saving
      }
    } catch (error) {
      console.error('Error saving question:', error)
      setError('Failed to save question')
    }
  }

  const revertChanges = () => {
    const updatedQuestions = [...questions]
    updatedQuestions[currentQuestionIndex] = originalQuestion
    setQuestions(updatedQuestions)
  }

  const deleteQuestion = async () => {
    const currentQuestion = questions[currentQuestionIndex]
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this question?'
    )

    if (confirmDelete) {
      try {
        const response = await axios.delete(
          `${BASE_URL}/qa-admin/question/${currentQuestion._id}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        if (response.data.status === 1) {
          setQuestions(questions.filter((q) => q._id !== currentQuestion._id))
          setCurrentQuestionIndex(0)
        } else {
          setError(response.data.message)
        }
      } catch (error) {
        console.error('Error deleting question:', error)
        setError('Failed to delete question')
      }
    } else {
      // If the user cancels the deletion, do nothing
      return
    }
  }

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1)
      setOriginalQuestion(questions[currentQuestionIndex - 1]) // Update original question for revert
    }
  }

  // Function to handle the edit button click
  const handleEditAdmin = (admin) => {
    setEditAdmin(admin)
    setIsEditing(true)
  }

  // Function to handle editing input changes
  const handleEditInputChange = (e) => {
    const { name, value } = e.target
    setEditAdmin({ ...editAdmin, [name]: value })
  }

  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
      setOriginalQuestion(questions[currentQuestionIndex + 1]) // Update original question for revert
    }
  }

  const startAddingQuestion = () => {
    setNewQuestion('')
    setNewOptions([''])
    setIsAddingQuestion(true)
  }

  const cancelAddingQuestion = () => {
    setIsAddingQuestion(false)
    setNewQuestion('')
    setNewOptions([''])
  }

  const handleNewQuestionChange = (e) => {
    setNewQuestion(e.target.value)
  }

  const handleNewOptionChange = (e, index, field) => {
    // const updatedOptions = [...newOptions]
    // updatedOptions[index] = e.target.value
    // setNewOptions(updatedOptions)
    const updatedOptions = [...newOptions]
    if (!updatedOptions[index]) {
      updatedOptions[index] = {
        answer: '',
        seller_score: 0.0,
        buyer_score: 0.0,
      }
    }
    updatedOptions[index][field] =
      field === 'seller_score' || field === 'buyer_score'
        ? parseFloat(e.target.value)
        : e.target.value
    setNewOptions(updatedOptions)
  }

  // Function to submit the edited admin details
  const handleEditAdminSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        `${BASE_URL}/qa-admin/edit`,
        editAdmin,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        fetchAdmins() // Refresh the admin list
        setIsEditing(false) // Close the edit form
        setEditAdmin(null) // Clear the edit admin state
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      console.error('Error updating admin:', error)
      setError('Failed to update admin')
    }
  }

  const addNewOption = () => {
    setNewOptions([...newOptions, ''])
  }

  const deleteNewOption = (index) => {
    const updatedOptions = [...newOptions]
    updatedOptions.splice(index, 1)
    setNewOptions(updatedOptions)
  }

  const saveNewQuestion = async () => {
    try {
      const newQuestionData = {
        question: newQuestion,
        answers: JSON.stringify(newOptions),
        category_id: selectedCategory,
      }
      const response = await axios.post(
        `${BASE_URL}/qa-admin/add-question`,
        newQuestionData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        // Successfully saved the new question
        fetchQuestions(selectedCategory)
        setIsAddingQuestion(false)
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      console.error('Error saving new question:', error)
      setError('Failed to save new question')
    }
  }

  return (
    <div className="qa-admin-tools">
      <h2 className="profile-header">Admin Management Portal</h2>
      {/* Admin Users Table */}
      <div className="admin-section">
        <h3>Admin Users</h3>
        <table className="admin-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <tr key={admin.admin_uid}>
                <td>{admin.username}</td>
                <td>{admin.full_name}</td>
                <td>{admin.email}</td>
                <td>
                  <span
                    className={
                      admin.admin_status === 1
                        ? 'status-active'
                        : 'status-inactive'
                    }
                  >
                    {admin.admin_status === 1 ? 'Active' : 'Inactive'}
                  </span>
                </td>
                <td>
                  <button
                    onClick={() => handleEditAdmin(admin)}
                    className="edit-button"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() =>
                      toggleAdminStatus(
                        admin.admin_uid,
                        admin.admin_status,
                        admin.email
                      )
                    }
                    className={
                      admin.admin_status === 1
                        ? 'deactivate-button'
                        : 'activate-button'
                    }
                  >
                    {admin.admin_status === 1 ? 'Deactivate' : 'Activate'}
                  </button>
                  <button
                    onClick={() => deleteAdmin(admin.admin_uid)}
                    className="delete-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isEditing && editAdmin && (
        <div className="edit-admin-section">
          <h3>Edit Admin</h3>
          <form onSubmit={handleEditAdminSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={editAdmin.email}
              onChange={handleEditInputChange}
              required
            />
            <input
              type="text"
              name="username"
              placeholder="Username"
              value={editAdmin.username}
              onChange={handleEditInputChange}
              required
            />
            <input
              type="text"
              name="full_name"
              placeholder="Full Name"
              value={editAdmin.full_name}
              onChange={handleEditInputChange}
              required
            />
            <input
              type="text"
              name="company_name"
              placeholder="Company Name"
              value={editAdmin.company_name}
              onChange={handleEditInputChange}
              required
            />
            <select
              name="admin_type"
              value={editAdmin.admin_type}
              onChange={handleEditInputChange}
              required
            >
              <option value="qa">QA</option>
              <option value="qa-analyst">QA Analyst</option>
            </select>
            <button type="submit">Save Changes</button>
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="cancel-button"
            >
              Cancel
            </button>
          </form>
        </div>
      )}

      <div className="add-admin-section">
        <h3>Add New User</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleAddAdmin()
          }}
        >
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={newAdmin.email}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={newAdmin.username}
            onChange={handleInputChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={newAdmin.password}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="full_name"
            placeholder="Full Name"
            value={newAdmin.full_name}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="company_name"
            placeholder="Company Name"
            value={newAdmin.company_name}
            onChange={handleInputChange}
            required
          />
          <select
            name="admin_type"
            value={newAdmin.admin_type}
            onChange={handleInputChange}
            required
          >
            <option value="qa">QA</option>
            <option value="qa-analyst">QA Analyst</option>
          </select>
          <button type="submit">Submit</button>
        </form>
      </div>

      <div className="category-section">
        <h3>Question Management</h3>
        <select value={selectedCategory} onChange={handleCategoryChange}>
          <option value="">Select a Category</option>
          {categories.map((category) => (
            <option key={category.qc_id} value={category.qc_id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>

      {selectedCategory && questions.length > 0 && (
        <div className="questions-section">
          <h3>Edit Questions</h3>
          <div className="question-editor">
            <input
              name="question"
              value={questions[currentQuestionIndex].question}
              onChange={(e) =>
                handleQuestionInputChange(e, currentQuestionIndex)
              }
            />
            {/* {JSON.parse(questions[currentQuestionIndex].answers).map(
              (option, index) => (
                <div key={index} className="option-container">
                  <input
                    type="text"
                    value={option.answer}
                    onChange={(e) => handleOptionChange(e, index)}
                    className="option-input"
                  />
                  <div>
                    <button
                      className="delete-option-button"
                      onClick={() => deleteOption(index)}
                    >
                      &times;
                    </button>
                  </div>
                </div>
              )
            )} */}
            {JSON.parse(questions[currentQuestionIndex].answers).map(
              (option, index) => (
                <div key={index} className="option-container">
                  <input
                    type="text"
                    value={option.answer}
                    onChange={(e) => handleOptionChange(e, index, 'answer')}
                    className="option-input"
                    placeholder="Enter answer"
                  />
                  <input
                    type="number"
                    value={option.seller_score}
                    onChange={(e) =>
                      handleOptionChange(e, index, 'seller_score')
                    }
                    className="option-input"
                    placeholder="Enter seller score"
                    min="0"
                    max="1"
                    step="0.1"
                    style={{ width: '50px' }}
                  />
                  <input
                    type="number"
                    value={option.buyer_score}
                    onChange={(e) =>
                      handleOptionChange(e, index, 'buyer_score')
                    }
                    className="option-input"
                    placeholder="Enter buyer score"
                    min="0"
                    max="1"
                    step="0.1"
                    style={{ width: '50px', paddingRight: '60px' }}
                  />
                  <div>
                    <button
                      className="delete-option-button"
                      onClick={() => deleteOption(index)}
                    >
                      &times;
                    </button>
                  </div>
                </div>
              )
            )}

            <div className="question-actions">
              <button onClick={addOption}>Add Option</button>
              <button onClick={saveQuestion}>Save Question</button>
              <button onClick={deleteQuestion} className="delete-button">
                Delete Question
              </button>
              <button onClick={revertChanges} className="revert-button">
                Revert Changes
              </button>
              <button onClick={startAddingQuestion} className="add-button">
                Add Question
              </button>
            </div>
          </div>

          {isAddingQuestion && (
            <div className="new-question-section">
              <h3>New Question</h3>
              <input
                type="text"
                placeholder="Enter your question"
                value={newQuestion}
                onChange={handleNewQuestionChange}
                className="new-question-input"
              />
              {/* {newOptions.map((option, index) => (
                <div key={index} className="option-container">
                  <input
                    type="text"
                    value={option}
                    onChange={(e) => handleNewOptionChange(e, index)}
                    className="option-input"
                  />
                  <div>
                    <button
                      className="delete-option-button"
                      onClick={() => deleteNewOption(index)}
                    >
                      &times;
                    </button>
                  </div>
                </div>
              ))} */}
              {newOptions.map((option, index) => (
                <div key={index} className="option-container">
                  <input
                    type="text"
                    value={option.answer}
                    onChange={(e) => handleNewOptionChange(e, index, 'answer')}
                    className="option-input"
                    placeholder="Enter answer"
                  />
                  <input
                    type="number"
                    value={option.seller_score}
                    onChange={(e) =>
                      handleNewOptionChange(e, index, 'seller_score')
                    }
                    className="option-input"
                    placeholder="Enter seller score"
                    min="0"
                    max="1"
                    step="0.1"
                  />
                  <input
                    type="number"
                    value={option.buyer_score}
                    onChange={(e) =>
                      handleNewOptionChange(e, index, 'buyer_score')
                    }
                    className="option-input"
                    placeholder="Enter buyer score"
                    min="0"
                    max="1"
                    step="0.1"
                  />
                  <div>
                    <button
                      className="delete-option-button"
                      onClick={() => deleteNewOption(index)}
                    >
                      &times;
                    </button>
                  </div>
                </div>
              ))}

              <div className="question-actions">
                <button onClick={addNewOption}>Add Option</button>
                <button onClick={saveNewQuestion} className="save-button">
                  Save New Question
                </button>
                <button
                  onClick={cancelAddingQuestion}
                  className="cancel-button"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          <div className="question-controls">
            <button
              onClick={previousQuestion}
              disabled={currentQuestionIndex === 0}
            >
              Previous
            </button>
            <button
              onClick={nextQuestion}
              disabled={currentQuestionIndex === questions.length - 1}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {error && <p className="error">{error}</p>}
    </div>
  )
}
