import React, { useState, useEffect, useContext } from "react";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import AuthContext from "../AuthContext";
import { BASE_URL } from "../config";
import "../styles/main.css";
import { BeatLoader } from "react-spinners";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};
const MonthlyRiskScores = (props) => {
  const [riskScores, setRiskScores] = useState([]);
  const { userToken } = useContext(AuthContext);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [score_type, setScore_type] = useState("full");
  const [month, setmonth] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    var dd = new Date();
    setmonth(dd.getMonth() + 1);
  }, [userToken]);
  useEffect(() => {
    fetchRiskScores();
  }, [month, selectedCategory, score_type]);

  const fetchRiskScores = async () => {
    setRiskScores([]);
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/user/files/getUserMonthlyRiskScores`,
        {
          month: month,
          category: { _id: selectedCategory },
          score_type: score_type == "full" ? "full" : "preview",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === 1) {
        console.log(response.data.data);
        setRiskScores(
          response.data.data.result.map((score) => ({
            documentName: score.documentName,
            riskScore: (score.riskScore * 100).toFixed(2),
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching risk scores:", error);
    } finally {
      setLoading(false);
    }
  };
  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#F1C40F",
    "#8E44AD",
    "#2ECC71",
    "#E74C3C",
  ];

  const options = {
    annotations: {
      points: [
        {
          x: "Bananas",
          seriesIndex: 0,
          label: {
            borderColor: "#775DD0",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#775DD0",
            },
            text: "Bananas are good",
          },
        },
      ],
    },
    series: [
      {
        name: "Risk Score",
        data: riskScores.map((score) => parseFloat(score.riskScore)),
      },
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: "30px",
      },
    },
    // dataLabels: {
    //   enabled: false,
    // },
    stroke: {
      width: 0,
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: riskScores.map((score) => score.documentName),
      labels: {
        rotate: -45,

        style: {
          fontSize: "12px",
        },
      },
      // tickPlacement: "on",
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 10, // Y-axis increments in steps of 10
      labels: {
        formatter: (val) => `${val}%`, // Format the y-axis as percentages
      },
    },
    title: {
      text: "Risk Scores per Document",
      align: "left",
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "light",
    //     type: "horizontal",
    //     shadeIntensity: 0.25,
    //     gradientToColors: undefined,
    //     inverseColors: true,
    //     opacityFrom: 0.85,
    //     opacityTo: 0.85,
    //     stops: [50, 0, 100],
    //   },
    // },
    // colors: colors.slice(0, riskScores.length),
  };

  // Check if there's any data to display
  const hasData = riskScores.length > 0;

  return (
    <div className="chart-wrapper">
      <h2 className="chart-title">Risk Scores per Document</h2>
      <div className="row">
        <div className="col-8 d-flex">
          <div className="form-group report_selects">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-down"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
            <label>Month:</label>
            <select
              className="monthselect"
              value={month}
              onChange={(e) => setmonth(e.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div className="form-group mx-3 report_selects">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-down"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
            <label>Category:</label>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              required
            >
              <option value="">All Category</option>
              {Array.isArray(props.categories) &&
                props.categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-4 text-right pt-3">
          <label class="switch2">
            <input
              type="checkbox"
              onChange={() => {
                if (score_type == "full") {
                  setScore_type("preview");
                } else {
                  setScore_type("full");
                }
              }}
            />
            <span class="slider2">
              <span className="pre-view">P</span>
              <span className="previewtool">Preview</span>

              <span className="full-view">F</span>
              <span className="fullviewtool">Full</span>
            </span>
          </label>
        </div>
      </div>
      {loading && (
        <BeatLoader
          color={"#000"}
          loading={loading}
          cssOverride={override}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
      {!loading && (
        <div id="chart3" className="chart-container">
          {!hasData && (
            <div className="overlay">
              <p className="overlay-text">No data available</p>
            </div>
          )}
          <div className={hasData ? "" : "blur-chart"}>
            <ApexCharts
              options={options}
              series={options.series}
              type="bar"
              height={350}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthlyRiskScores;
