import React, { useState, useEffect, useContext } from "react";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import AuthContext from "../AuthContext";
import { BASE_URL } from "../config";
import "../styles/main.css";
import { BeatLoader } from "react-spinners";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};
const AverageMonthlyRiskScores = () => {
  const [riskScores, setRiskScores] = useState([]);
  const { userToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchRiskScores = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/user/files/getAverageMonthlyRiskScores`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === 1) {
          //   console.log(response.data);
          setRiskScores(
            response.data.data.result.map((score) => ({
              documentName: score.month,
              riskScore: score.averageScore,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching risk scores:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRiskScores();
  }, [userToken]);

  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#F1C40F",
    "#8E44AD",
    "#2ECC71",
    "#E74C3C",
  ];

  const series = [
    {
      name: "Risk Score",
      data: riskScores.map((score) => parseFloat(score.riskScore)),
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Percent",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    legend: {
      show: true,
      formatter: (val) => {
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        return monthNames[parseInt(val) - 1]; // adjust for 0-indexed array
      },
    },
    tooltip: {
      enabled: true,
      formatter: (value) => `${value}%`, // Add percentage sign
    },
    xaxis: {
      categories: riskScores.map((score) => score.documentName),
      labels: {
        style: {
          fontSize: "12px",
        },
        formatter: (val) => {
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return monthNames[parseInt(val) - 1]; // adjust for 0-indexed array
        },
      },
    },

    yaxis: {
      labels: {
        formatter: (value) => `${value}%`, // Add percentage sign to y-axis labels
      },
    },
  };

  // Check if there's any data to display
  const hasData = riskScores.length > 0;

  return (
    <div className="chart-wrapper">
      <h2 className="chart-title">Average Risk Scores per Month</h2>
      {loading && (
        <BeatLoader
          color={"#000"}
          loading={loading}
          cssOverride={override}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
      {!loading && (
        <div id="chart3" className="chart-container">
          {!hasData && (
            <div className="overlay">
              <p className="overlay-text">No data available</p>
            </div>
          )}
          <div className={hasData ? "" : "blur-chart"}>
            <ApexCharts
              options={options}
              series={series}
              type="line"
              height={350}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AverageMonthlyRiskScores;
