import axios from "axios";
import { BASE_URL } from "../config";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../AuthContext";
import { ThemeContext } from "./../ThemeContext";
import QAFetchContext from "../QaFetchContext";
import useDebounce from "./useDebounce";
import { useNavigate } from "react-router-dom";
import SemiCircleChart from "./SemiCircleChart";
import { BeatLoader } from "react-spinners";
import Drive from "../img/google-drive.png";
import Share from "../img/office.png";
import { useGoogleLogin } from "@react-oauth/google";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};
const Header = () => {
  const [isSearchFor, setisSearchFor] = useState(false);
  const [showComingSoon, setshowComingSoon] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [area_livability_chart, setarea_livability_chart] = useState(false);
  const [top_5_occupation, settop_5_occupation] = useState(false);
  const [area_livability_chartList, setarea_livability_chartList] = useState(
    []
  );
  const [isFileUploaded, setisFileUploaded] = useState(false);
  const [top_5_occupationList, settop_5_occupationList] = useState([]);
  const handleShowModal = () => setShowModal(true);
  const [error, setError] = useState("");
  const [CompletedFiles, setCompletedFiles] = useState([]);
  const { userToken, userRole } = useContext(AuthContext);
  const [showSuggestion, setshowSuggestion] = useState(false);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);
  const [showList, setshowList] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [buyerSeller, setBuyerSeller] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [discussionPoint, setDiscussionPoint] = useState("");
  const fileInputRef = useRef(null);
  const suggestionsRef = useRef(null); // Debouncing with 500ms delay
  const [showPromptModal, setshowPromptModal] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const {
    isFetchingQA,
    setIsFetchingQA,
    proccessingFiles,
    addFileToProcessing,
    removeFileFromProcessing,
  } = useContext(QAFetchContext);

  const [fileId, setFileId] = useState(null);
  const [fileName, setFileName] = useState(null);
  var [accessToken, setAccessToken] = useState(null); // State to store the access token
  var [uploadedFileName, setUploadedFileName] = useState(null);
  var [uploadedOriginalFileName, setUploadedOriginalFileName] = useState(null);

  // Google OAuth login hook
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setAccessToken(tokenResponse.access_token); // Store the access token in the state
      localStorage.setItem("gapi_accessToken", tokenResponse.access_token);
      const pickerApi = await loadPicker();
      // console.log("accesstoken 1: ", accessToken);
      createPicker(tokenResponse.access_token); // Pass the access token to the picker
    },
    onError: (errorResponse) =>
      console.log("Error during login:", errorResponse),
    scope: "https://www.googleapis.com/auth/drive.readonly",
  });

  // Load Google Picker API
  const loadPicker = () => {
    return new Promise((resolve) => {
      window.gapi.load("picker", resolve);
    });
  };

  // Create the Google Picker widget
  const createPicker = (accessToken) => {
    // console.log("accesstoken 2: ", accessToken);
    const view = new window.google.picker.DocsView(
      window.google.picker.ViewId.DOCS
    )
      .setIncludeFolders(true) // Allow selecting from folders
      .setMimeTypes(
        "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
      )
      .setSelectFolderEnabled(false); // Disable folder selection

    const picker = new window.google.picker.PickerBuilder()
      // .addView(window.google.picker.ViewId.DOCS)
      .addView(view)
      .setOAuthToken(accessToken)
      .setDeveloperKey("AIzaSyBCCH39RtJ1BTPx1prgbXnfG3NBT_k-uNI") // Replace with your actual API key
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
    // console.log("accesstoken 3: ", accessToken);
  };

  // Callback for handling file selection in the Picker
  const pickerCallback = async (data) => {
    if (!accessToken) {
      accessToken = localStorage.getItem("gapi_accessToken");
    }
    // console.log("accesstoken 4: ", accessToken);
    if (data.action === window.google.picker.Action.PICKED) {
      const file = data.docs[0];
      setFileId(file.id);
      setFileName(file.name);

      // console.log("accesstoken 5: ", accessToken);
      // Check if accessToken is available before proceeding
      if (!accessToken) {
        console.error("Access token is missing");
        return;
      }

      try {
        // Download the file from Google Drive using the file ID
        const downloadUrl = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;

        // Download file content using the access token
        const response = await axios({
          url: downloadUrl,
          method: "GET",
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: "blob",
        });

        // Send the file to the Node.js server
        const formData = new FormData();
        formData.append("file", response.data, file.name);

        const uploadResponse = await axios.post(
          `${BASE_URL}/global/upload-drive-file`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (uploadResponse.data.status === 1) {
          const { filename, original_filename } = uploadResponse.data;
          setUploadedOriginalFileName(original_filename);
          setUploadedFileName([
            {
              filename: filename,
              original_filename: original_filename,
            },
          ]);
          setSelectedFile(original_filename);
        } else {
          setError(
            uploadResponse.data.message ||
              "An error occurred during the file upload."
          );
        }
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    }
  };

  // Fetch notifications on mount and every minute
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/notifications`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.data.status === 1) {
          setNotifications(response.data.notifications);
          setNotificationCount(response.data.notifications.length);
        } else {
          console.error("Error fetching notifications:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
    const interval = setInterval(fetchNotifications, 60000); // Poll every minute

    return () => clearInterval(interval);
  }, [userToken]);

  // Toggle notification dropdown
  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  // Mark a notification as read
  const markAsRead = async (notificationId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/notifications/${notificationId}/mark-as-read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data.status === 1) {
        // Remove the notification from the list
        setNotifications((prev) =>
          prev.filter((notif) => notif._id !== notificationId)
        );
        setNotificationCount((prev) => prev - 1);
      } else {
        console.error(
          "Error marking notification as read:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  // Delete a notification
  const deleteNotification = async (notificationId) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/notifications/${notificationId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data.status === 1) {
        // Remove the notification from the list
        setNotifications((prev) =>
          prev.filter((notif) => notif._id !== notificationId)
        );
        setNotificationCount((prev) => prev - 1);
      } else {
        console.error("Error deleting notification:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  // Effect to handle the debounced value
  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    setCompletedFiles([]);
    if (debouncedQuery) {
      settop_5_occupation(false);
      setarea_livability_chart(false);
      fetchCompletedFiles();
    }
  }, [debouncedQuery]);

  const fetchCompletedFiles = async () => {
    if (debouncedQuery != "Find ") {
      setLoading(true);
      try {
        const response = await axios.post(
          `${BASE_URL}/user/file/list`,
          {
            page: "1",
            size: "10",
            //   file_queue: "back-client",
            keyword: debouncedQuery,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.status === 1) {
          if (response.data.popup_name == "area_livability_chart") {
            setarea_livability_chart(true);

            settop_5_occupation(false);
            setarea_livability_chartList(response.data.data);
            settop_5_occupationList([]);
          } else if (response.data.popup_name == "top_5_occupation") {
            setarea_livability_chartList([]);
            settop_5_occupationList(response.data.data);
            setarea_livability_chart(false);
            settop_5_occupation(true);
          } else {
            setarea_livability_chartList([]);
            settop_5_occupationList([]);
            settop_5_occupation(false);
            setarea_livability_chart(false);
            const demosearch = query;
            localStorage.setItem(
              "currentSearch",
              demosearch.replace("search for", "")
            );
            if (showList) {
              localStorage.setItem("isSearchUser", response.data.data[0]._id);
              setCompletedFiles(response.data.data);
            } else {
              localStorage.setItem("isSearchUser", response.data.data[0]._id);
              navigate("/documents");
            }
          }
        }
      } catch (error) {
        console.error("Error fetching completed files:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setshowSuggestion(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event) => {
    // Check for Command + G or Ctrl + G
    if ((event.metaKey || event.ctrlKey) && event.key === "k") {
      event.preventDefault(); // Prevent default behavior
      setshowSuggestion((prev) => !prev); // Toggle modal visibility
    }
  };

  useEffect(() => {
    // Add event listener for keydown
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      // Clean up the event listener
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (area_livability_chart || top_5_occupation) {
        setshowPromptModal(true);
      } else {
        const isSearchUser = localStorage.getItem("isSearchUser");

        if (isSearchUser) {
          const demosearch = query;
          localStorage.setItem(
            "currentSearch",
            demosearch.replace("search for", "")
          );
          navigate("/documents");
        }
      }
    }
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFile(files);
    // setSelectedFile(event.target.files[0]);
    // if (event.target.files[0]) {
    //   setShowModal(true);
    // }
  };
  const handleCloseModal = () => {
    setSelectedFile([]);
    setBuyerSeller("");
    setSelectedCategory("");
    setDiscussionPoint("");
    setShowModal(false);
    setshowPromptModal(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const saveFiles = async (
    filename,
    original_filename,
    buyerSeller,
    selectedCategory,
    discussionPoint
  ) => {
    setisFileUploaded(false);
    const secondResponse = await axios.post(
      `${BASE_URL}/user/upload-and-search`,
      {
        filename,
        original_filename,
        user_type: buyerSeller,
        qc_uid: selectedCategory,
        admin_id: "1234",
        discussion_point: discussionPoint,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (secondResponse.data.status === 1) {
      alert(
        "File uploaded! Your file will take a few minutes to process, but may take longer, please do not refresh the page."
      );
      handleCloseModal();
      // Call handleHashDocument after successful file upload
      const fileId = secondResponse.data.file_id; // Assuming `file_id` is returned in the response
      handleHashDocument({ _id: fileId, filename: original_filename });
    } else {
      alert(
        secondResponse.data.message ||
          "An error occurred during the second upload step."
      );
    }
  };

  const handleUpload = async () => {
    if (selectedFile.length > 0 && buyerSeller && selectedCategory) {
      try {
        var isUploaded = false;
        var filename = [];
        var original_filename = "";
        if (uploadedFileName == null) {
          selectedFile.forEach(async (file, index) => {
            const formData = new FormData();
            formData.append("file", file);
            const uploadResponse = await axios.post(
              `${BASE_URL}/global/upload-file`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (uploadResponse.data.status) {
              filename.push({
                filename: uploadResponse.data.filename,
                original_filename: uploadResponse.data.original_filename,
              });
              original_filename = uploadResponse.data.original_filename;
              isUploaded = true;
              if (index == selectedFile.length - 1) {
                saveFiles(
                  filename,
                  original_filename,
                  buyerSeller,
                  selectedCategory,
                  discussionPoint
                );
              }
            }
          });
        } else {
          isUploaded = true;
          filename = uploadedFileName;
          original_filename = uploadedOriginalFileName;
        }
        if (isUploaded == true) {
          const secondResponse = await axios.post(
            `${BASE_URL}/user/upload-and-search`,
            {
              filename,
              original_filename,
              user_type: buyerSeller,
              qc_uid: selectedCategory,
              admin_id: "1234",
              discussion_point: discussionPoint,
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (secondResponse.data.status === 1) {
            alert("File uploaded and processed successfully!");
            handleCloseModal();
            // Call handleHashDocument after successful file upload
            const fileId = secondResponse.data.file_id; // Assuming `file_id` is returned in the response
            const hashResponse = await handleHashDocument({ _id: fileId }); // Pass the file ID to the hash function
            console.log("Hash response:", hashResponse.data);

            window.location.reload();
          } else {
            alert(
              secondResponse.data.message ||
                "An error occurred during the second upload step."
            );
          }
        } else {
          setError(
            isUploaded == false || "An error occurred during the file upload."
          );
        }
      } catch (error) {
        setError("An error occurred during file upload");
      }
    } else {
      setLoading(false);
    }
  };

  const handleHashDocument = async (file) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/hash-document-and-check`,
        {
          file_id: file._id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      if (response.data.status === 1) {
        console.log(
          "Document hash saved and checked if already exists successfully:",
          response.data
        );
        const fileHash = response.data.file_hash;
        for (let i = 0; i < response.data.file_hash.length; i++) {
          try {
            if (isFetchingQA) {
              alert(
                "Data is already being fetched at the moment. Please wait."
              );
              return;
            }
            setIsFetchingQA(true);
            addFileToProcessing(file);

            await axios.post(
              `${BASE_URL}/user/existing-file-qa`,
              { file_hash: response.data.file_hash[i].hash },
              {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                  "Content-Type": "application/json",
                },
              }
            );
            console.log(
              "Existing file QA fetched successfully:",
              response.data
            );
          } catch (error) {
            setIsFetchingQA(false);
            removeFileFromProcessing(file);
            alert(
              error.response.data.message || "Error fetching existing file QA"
            );
            console.error("Error fetching existing file QA:", error);
          }
        }

        setIsFetchingQA(false);
        removeFileFromProcessing(file);
      } else {
        console.error("Error hashing document:", response.data.message);
      }
    } catch (error) {
      console.error("Error hashing document:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/category/list`,
        {
          page: "0",
          size: "10",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.status === 1) {
        setCategories(response.data.categories);
      } else {
        setError(response.data?.data?.message || "Failed to fetch categories");
      }
    } catch (error) {
      setError("Failed to fetch categories");
    }
  };
  useEffect(() => {
    setisSearchFor(query.toLowerCase().includes("search for"));
  }, [query]);
  const { theme, toggleTheme } = useContext(ThemeContext);
  return (
    <>
      <label className="position-relative search-header">
        {showSuggestion && (
          <>
            <div
              className="newsearchlist "
              ref={suggestionsRef}
              style={{ zIndex: "10" }}
            >
              {/* {loading && (
                <BeatLoader
                  color={"#000"}
                  loading={loading}
                  cssOverride={override}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )} */}
              <p className="px-3">Searching in files</p>
              <div className="wrapnewbtns px-3 d-flex">
                <button className="newsearbtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-file"
                  >
                    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                    <polyline points="13 2 13 9 20 9"></polyline>
                  </svg>
                  Documents
                </button>
                <button className="newsearbtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-alert-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="12"></line>
                    <line x1="12" y1="16" x2="12.01" y2="16"></line>
                  </svg>
                  Risk Score
                </button>
                <button className="newsearbtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                    <polyline points="2 17 12 22 22 17"></polyline>
                    <polyline points="2 12 12 17 22 12"></polyline>
                  </svg>
                  Task
                </button>
                <button className="newsearbtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-user"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  User
                </button>
              </div>
              {!isSearchFor && (
                <div>
                  <p className="px-3 pt-2">Smart Prompt Examples</p>
                  <div className="px-3">
                    <ul className="smartpromptbtn">
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/invite");
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="far"
                          data-icon="sparkles"
                          class="svg-inline--fa fa-sparkles styles__icon_emz6K"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 128l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 128l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 64 426.8 7.5C425.1 3 420.8 0 416 0s-9.1 3-10.8 7.5L384 64 327.5 85.2zM9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5L26.3 277l8.1 3.7 .6 .3 88.3 40.8L164.1 410l.3 .6 3.7 8.1 7.9 17.1c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l7.9-17.1 3.7-8.1 .3-.6 40.8-88.3L346 281l.6-.3 8.1-3.7 17.1-7.9c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5l-17.1-7.9-8.1-3.7-.6-.3-88.3-40.8L217 99.1l-.3-.6L213 90.3l-7.9-17.1c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3l-7.9 17.1-3.7 8.1-.3 .6-40.8 88.3L35.1 228.1l-.6 .3-8.1 3.7L9.3 240zm83 14.5l51.2-23.6c10.4-4.8 18.7-13.1 23.5-23.5l23.6-51.2 23.6 51.2c4.8 10.4 13.1 18.7 23.5 23.5l51.2 23.6-51.2 23.6c-10.4 4.8-18.7 13.1-23.5 23.5l-23.6 51.2-23.6-51.2c-4.8-10.4-13.1-18.7-23.5-23.5L92.3 254.6zM384 384l-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 448l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 448l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 384l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L384 384z"
                          ></path>
                        </svg>
                        Invite new user
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="far"
                          data-icon="sparkles"
                          class="svg-inline--fa fa-sparkles styles__icon_emz6K"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 128l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 128l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 64 426.8 7.5C425.1 3 420.8 0 416 0s-9.1 3-10.8 7.5L384 64 327.5 85.2zM9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5L26.3 277l8.1 3.7 .6 .3 88.3 40.8L164.1 410l.3 .6 3.7 8.1 7.9 17.1c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l7.9-17.1 3.7-8.1 .3-.6 40.8-88.3L346 281l.6-.3 8.1-3.7 17.1-7.9c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5l-17.1-7.9-8.1-3.7-.6-.3-88.3-40.8L217 99.1l-.3-.6L213 90.3l-7.9-17.1c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3l-7.9 17.1-3.7 8.1-.3 .6-40.8 88.3L35.1 228.1l-.6 .3-8.1 3.7L9.3 240zm83 14.5l51.2-23.6c10.4-4.8 18.7-13.1 23.5-23.5l23.6-51.2 23.6 51.2c4.8 10.4 13.1 18.7 23.5 23.5l51.2 23.6-51.2 23.6c-10.4 4.8-18.7 13.1-23.5 23.5l-23.6 51.2-23.6-51.2c-4.8-10.4-13.1-18.7-23.5-23.5L92.3 254.6zM384 384l-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 448l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 448l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 384l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L384 384z"
                          ></path>
                        </svg>
                        Upload new document
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/settings");
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="far"
                          data-icon="sparkles"
                          class="svg-inline--fa fa-sparkles styles__icon_emz6K"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 128l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 128l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 64 426.8 7.5C425.1 3 420.8 0 416 0s-9.1 3-10.8 7.5L384 64 327.5 85.2zM9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5L26.3 277l8.1 3.7 .6 .3 88.3 40.8L164.1 410l.3 .6 3.7 8.1 7.9 17.1c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l7.9-17.1 3.7-8.1 .3-.6 40.8-88.3L346 281l.6-.3 8.1-3.7 17.1-7.9c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5l-17.1-7.9-8.1-3.7-.6-.3-88.3-40.8L217 99.1l-.3-.6L213 90.3l-7.9-17.1c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3l-7.9 17.1-3.7 8.1-.3 .6-40.8 88.3L35.1 228.1l-.6 .3-8.1 3.7L9.3 240zm83 14.5l51.2-23.6c10.4-4.8 18.7-13.1 23.5-23.5l23.6-51.2 23.6 51.2c4.8 10.4 13.1 18.7 23.5 23.5l51.2 23.6-51.2 23.6c-10.4 4.8-18.7 13.1-23.5 23.5l-23.6 51.2-23.6-51.2c-4.8-10.4-13.1-18.7-23.5-23.5L92.3 254.6zM384 384l-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 448l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 448l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 384l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L384 384z"
                          ></path>
                        </svg>
                        Change Settings
                      </li>
                    </ul>
                  </div>
                  <p className="px-3 pt-2">Examples</p>
                  <div className="px-3">
                    <ul className="smartpromptbtn">
                      {showSuggestion && (
                        <>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setQuery("Show me the lowest preview risk score");
                              setshowList(false);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-terminal"
                            >
                              <polyline points="4 17 10 11 4 5"></polyline>
                              <line x1="12" y1="19" x2="20" y2="19"></line>
                            </svg>
                            Show me the lowest preview risk score
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setQuery("Highest preview risk score");
                              setshowList(false);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-terminal"
                            >
                              <polyline points="4 17 10 11 4 5"></polyline>
                              <line x1="12" y1="19" x2="20" y2="19"></line>
                            </svg>
                            Highest preview risk score
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setQuery(
                                "Find document with the lowest preview risk score"
                              );
                              setshowList(false);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-terminal"
                            >
                              <polyline points="4 17 10 11 4 5"></polyline>
                              <line x1="12" y1="19" x2="20" y2="19"></line>
                            </svg>
                            Find document with the lowest preview risk score
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setQuery(
                                "Document with highest preview risk score"
                              );
                              setshowList(false);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-terminal"
                            >
                              <polyline points="4 17 10 11 4 5"></polyline>
                              <line x1="12" y1="19" x2="20" y2="19"></line>
                            </svg>
                            Document with highest preview risk score
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setQuery("Find ");
                              setshowList(false);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-terminal"
                            >
                              <polyline points="4 17 10 11 4 5"></polyline>
                              <line x1="12" y1="19" x2="20" y2="19"></line>
                            </svg>
                            Find
                          </li>
                        </>
                      )}
                      {loading && (
                        <BeatLoader
                          color={"#000"}
                          loading={loading}
                          cssOverride={override}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )}
                      {CompletedFiles.length > 0 && <hr />}
                      {CompletedFiles.map((item, key) => (
                        <li
                          key={key}
                          onClick={() => {
                            const demosearch = query;
                            localStorage.setItem(
                              "currentSearch",
                              demosearch.replace("search for", "")
                            );
                            localStorage.setItem("isSearchUser", item._id);
                            navigate("/documents");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-file"
                          >
                            <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                            <polyline points="13 2 13 9 20 9"></polyline>
                          </svg>
                          {/* {item.user_id?.fname} {item.user_id?.lname} */}
                          {item.filename}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {isSearchFor && (
                <div>
                  <p className="px-3 pt-2">Searching for files</p>
                  <div className="px-3">
                    <ul className="smartpromptbtn">
                      {loading && (
                        <BeatLoader
                          color={"#000"}
                          loading={loading}
                          cssOverride={override}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )}
                      {CompletedFiles.length > 0 && <hr />}
                      {CompletedFiles.map((item, key) => (
                        <li
                          key={key}
                          onClick={() => {
                            const demosearch = query;
                            localStorage.setItem(
                              "currentSearch",
                              demosearch.replace("search for", "")
                            );
                            localStorage.setItem("isSearchUser", item._id);
                            navigate("/documents");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-file"
                          >
                            <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                            <polyline points="13 2 13 9 20 9"></polyline>
                          </svg>
                          {/* {item.user_id?.fname} {item.user_id?.lname} */}
                          {item.filename}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-search"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
        <span className="sspn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="feather feather-command"
          >
            <path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path>
          </svg>
        </span>
        <span className="sspn1">K</span>
        <input
          type="text"
          onClick={() => setshowSuggestion(true)}
          value={query}
          onKeyUp={handleKeyPress}
          onChange={(e) => {
            setQuery(e.target.value);

            setshowList(true);
          }}
          placeholder="Search Documents"
        />
      </label>

      <div className="d-flex align-items-center">

      <button className="mode_theme_btn" onClick={toggleTheme}>
          <span className="lig">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-moon"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
          </span>
          <span className="dark">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-sun"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
          </span>
      </button>

        <div className="notification-bell mx-2">
          <div className="cirnotisvg" onClick={toggleNotifications}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-bell"
            >
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
          </div>

          {notificationCount > 0 && (
            <span className="notification-count">{notificationCount}</span>
          )}
          {showNotifications && (
            <div className="notification-dropdown">
              <div className="not_card_head">
                <h1 className="notic_heading">Notifications</h1>
                <button className="close_btn_noti">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
              {notifications.map((notification) => (
                <div key={notification._id} className="notification-item">
                  <div className="not_msg_wraps">
                    <p className="not_msgss">{notification.message}</p>
                  </div>

                  <div className="notification-actions">
                    <button
                      onClick={() => markAsRead(notification._id)}
                      className="mark-as-read"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-check"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                      Mark as Read
                    </button>
                    <button
                      onClick={() => deleteNotification(notification._id)}
                      className="delete-notification"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-trash"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
              {notifications.length === 0 && (
                <div className="no-notifications">No new notifications</div>
              )}
            </div>
          )}
        </div>

        <button
          className="btn_upld_head"
          type="button"
          onClick={handleShowModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-upload-cloud"
          >
            <polyline points="16 16 12 12 8 16"></polyline>
            <line x1="12" y1="12" x2="12" y2="21"></line>
            <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
            <polyline points="16 16 12 12 8 16"></polyline>
          </svg>
          &nbsp; Upload Documents
        </button>
      </div>

      {/* Modal */}
      {showModal && (
        <>
          <div
            className="modal  fade show"
            id="documentModal"
            aria-labelledby="documentModalLabel"
            role="dialog"
            style={{ zIndex: 1 }}
          >
            <div className="modal-dialog  modal-xl modal-dialog-centered">
              <div className="modal-content border-none p-3">
                <div className="modal-body p-0">
                  <div className="container px-3 py-2">
                    <div className="row">
                      <div className="col-8">
                        <h2 className="modal-headings pt-1 m-0">
                          Upload Document
                        </h2>
                      </div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={handleCloseModal}
                          style={{ marginTop: "0px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <section className="file_upload_section my-3">
                          <div className="upload-drag">
                            <label htmlFor="selectfiless">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="44"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6b7280"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-upload"
                              >
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="17 8 12 3 7 8"></polyline>
                                <line x1="12" y1="3" x2="12" y2="15"></line>
                              </svg>
                            </label>
                            <p className="upload-text font-13 w-700 mb-0 mt-3">
                              {selectedFile.length == 0
                                ? "Drag and drop files here or"
                                : ""}
                              {selectedFile.length > 0 &&
                                selectedFile
                                  .map((file) => file.name)
                                  .join(", ")}
                            </p>
                            <span className="upload-limit font-11">
                              Only *.xls, *.xlsx, *.csv, *.ods, *.pdf & 30 MB
                              limit
                            </span>
                            <span className="upload-limit font-12 mt-2 ">
                              Or
                            </span>
                          </div>
                          <div>
                            <div className="row px-4 mt-2">
                              <div className="col-6 px-1">
                                <label
                                  className="lblselct"
                                  htmlFor="selectfiless"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-upload-cloud"
                                  >
                                    <polyline points="16 16 12 12 8 16"></polyline>
                                    <line
                                      x1="12"
                                      y1="12"
                                      x2="12"
                                      y2="21"
                                    ></line>
                                    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                                    <polyline points="16 16 12 12 8 16"></polyline>
                                  </svg>
                                  Upload
                                  <input
                                    id="selectfiless"
                                    type="file"
                                    multiple
                                    className="file-input"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                  />
                                </label>
                              </div>

                              <div className="col-6 px-1">
                                <button
                                  className="lblselct"
                                  onClick={() => {
                                    // handleCloseModal();
                                    login();
                                  }}
                                >
                                  <img src={Drive}></img>
                                  Drive
                                </button>
                              </div>

                              <div className="col-6 px-1">
                                <button
                                  className="lblselct"
                                  onClick={() => setshowComingSoon(true)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-folder"
                                  >
                                    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                                  </svg>
                                  DMS Integrations
                                </button>
                              </div>
                              <div className="col-6 px-1">
                                <button
                                  className="lblselct"
                                  onClick={() => setshowComingSoon(true)}
                                >
                                  <img src={Share}></img>
                                  Sharepoint
                                </button>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <div className="form-group report_selects2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-chevron-down"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                          <label>Buyer/Seller:</label>

                          <select
                            className="mt-0 mb-0"
                            value={buyerSeller}
                            onChange={(e) => setBuyerSeller(e.target.value)}
                            required
                          >
                            <option value="">Select</option>
                            <option value="buyer">Buyer</option>
                            <option value="seller">Seller</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group report_selects2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-chevron-down"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                          <label>Category:</label>
                          <select
                            value={selectedCategory}
                            onChange={(e) =>
                              setSelectedCategory(e.target.value)
                            }
                            required
                            className="mt-0 mb-0"
                          >
                            <option value="">Select</option>
                            {Array.isArray(categories) &&
                              categories.map((category) => (
                                <option
                                  key={category.qc_uid}
                                  value={category.qc_uid}
                                >
                                  {category.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label>Discussion Point:</label>
                          <input
                            value={discussionPoint}
                            onChange={(e) => setDiscussionPoint(e.target.value)}
                            placeholder="Enter any points you want to discuss"
                            required
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        {!loading && (
                          <button
                            className="btn_uplo_doc mx-auto my-0"
                            aria-label="Upload Documents"
                            onClick={() => {
                              setLoading(true);
                              handleUpload();
                            }}
                          >
                            Upload Documents
                          </button>
                        )}
                        {loading && (
                          <button
                            className="btn_uplo_doc mx-auto my-0"
                            aria-label="Upload Documents"
                          >
                            <BeatLoader
                              color={"#fff"}
                              loading={loading}
                              cssOverride={override}
                              size={10}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="bg-white position-relative d-none">
                    {/* <label className="md_lbl mt-4" htmlFor="file_sel">
                      <div className="text-center upload_drag">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="44"
                          height="44"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#6b7280"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-upload"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="17 8 12 3 7 8"></polyline>
                          <line x1="12" y1="3" x2="12" y2="15"></line>
                        </svg>
                        <p className="font-13 w-700 mb-0 mt-3">
                          Choose file to upload or drag and drop
                        </p>
                        <span className="font-11">
                          Only *.xls, *.xlsx, *.csv, *.ods, *.pdf & 30 MB limit
                        </span>
                      </div>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                      />
                    </label> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={handleCloseModal}
          ></div>
        </>
      )}

      {showPromptModal && (
        <>
          <div
            className="modal  fade show"
            id="documentModal2"
            aria-labelledby="documentModalLabel"
            role="dialog"
          >
            <div className="modal-dialog  modal-xl modal-dialog-centered">
              <div className="modal-content border-none p-3 modaldocuments">
                <div className="modal-body p-0">
                  {area_livability_chart && (
                    <section className="py-2">
                      <div className="container pb-2">
                        <div className="row">
                          <div className="col-8 pt-2">
                            <h2 className="modheading d-flex align-items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-alert-circle"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                              </svg>
                              &nbsp;&nbsp;Company Risk Score
                            </h2>
                          </div>
                          <div className="col-4 pb-3 text-right">
                            <button
                              className="btn_close_x p-0 m-0"
                              onClick={() => setshowPromptModal(false)}
                              style={{ marginTop: "0px" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="17"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6b7280"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <SemiCircleChart />
                          </div>
                        </div>
                        <div className="row " style={{ marginTop: "-30px" }}>
                          {area_livability_chartList.map((item, key) => (
                            <div
                              key={key}
                              className="col-6 mt-3"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="d-flex position-relative">
                                <span className="documentcounts">
                                  {item.score}
                                </span>
                                <div className="wrapdicon">
                                  <div className="docicons">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-file"
                                    >
                                      <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                                      <polyline points="13 2 13 9 20 9"></polyline>
                                    </svg>
                                  </div>
                                </div>
                                <div className="flex-2">
                                  <p className="paraMod ">
                                    {item.category_name}
                                  </p>
                                  <div className="progressbar">
                                    <span
                                      style={{ width: item.percent }}
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  )}
                  {top_5_occupation && (
                    <section>
                      <div className="container">
                        <div className="row">
                          <div className="col-8 pt-1">
                            <h2 className="modheading d-flex align-items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-briefcase"
                              >
                                <rect
                                  x="2"
                                  y="7"
                                  width="20"
                                  height="14"
                                  rx="2"
                                  ry="2"
                                ></rect>
                                <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                              </svg>
                              &nbsp;&nbsp; Top {top_5_occupationList.length}{" "}
                              Highest Preview Risk Score
                            </h2>
                          </div>
                          <div className="col-4 text-right pb-2">
                            <button
                              className="btn_close_x p-0 m-0"
                              onClick={() => setshowPromptModal(false)}
                              style={{ marginTop: "0px" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="17"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6b7280"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </button>
                          </div>
                          <div className="col-12 px-0 pt-2">
                            <ul className="profoccupation">
                              {top_5_occupationList.map((item, key) => (
                                <li
                                  key={key}
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>{item.filename}</span>{" "}
                                  <span>({item.percent}%)</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showComingSoon && (
        <>
          <div className="modal  fade show">
            <div className="modal-dialog  modal-xl modal-dialog-centered">
              <div className="modal-content border-none p-3">
                <div className="modal-body p-0" style={{ width: "300px" }}>
                  <div className="container px-3 py-2">
                    <div className="row">
                      <div className="col-8"></div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={() => {
                            setshowComingSoon(false);
                          }}
                          style={{ marginTop: "0px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12" style={{ textAlign: "center" }}>
                        <h2 style={{ margin: 0 }}>Coming soon</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
