let BASE_URL = ''

if (process.env.NODE_ENV !== 'development') {
  BASE_URL = 'https://modinsight.dev:8089/v1'
} else {
  // BASE_URL = 'https://modinsight.dev:8089/v1'
  BASE_URL = 'https://modinsight.dev:8089/v1'
}

export { BASE_URL }
