import React, { useState, useContext, useRef, useEffect } from 'react'
import axios from 'axios'
import AuthContext from '../AuthContext'
import '../styles/DocumentViewer.css'
import Modal from 'react-modal'
import { BASE_URL } from '../config'
import download from '../img/download.png'

export default function DocumentViewer({ file, handleBack }) {
  const { userToken, userRole } = useContext(AuthContext)
  const [selectedFile, setSelectedFile] = useState(null)
  const [documentUrl, setDocumentUrl] = useState('')
  const [error, setError] = useState('')
  // const [highlights, setHighlights] = useState([])
  const [files, setFiles] = useState([])
  const iframeRef = useRef(null)
  const [questions, setQuestions] = useState([])
  const [buyerSeller, setBuyerSeller] = useState('')
  // const [categories, setCategories] = useState('')
  const [categoryUid, setCategoryUid] = useState('')
  const [adminId, setAdminId] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [userId, setUserId] = useState('')
  const [adminUsername, setAdminUsername] = useState('')
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [answerText, setAnswerText] = useState('')
  const [discussPoint, setDiscussPoint] = useState('')
  const [selectedAnswer, setSelectedAnswer] = useState('')
  // const [riskScore, setRiskScore] = useState(0)
  const [filteredQuestions, setFilteredQuestions] = useState([])
  const [submittedAnswers, setSubmittedAnswers] = useState([])
  // const [remarks, setRemarks] = useState('')
  const [allRemarks, setAllRemarks] = useState({})
  const [uploadReadlineFiles, setUploadReadlineFiles] = useState([])
  const [questionIds, setQuestionIds] = useState([])
  const fileInputRef = useRef(null)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false) // State to track checkbox
  // const [checkboxStates, setCheckboxStates] = useState({})

  const isEditable = () => {
    const editable =
      (userRole === 'qa-analyst' && file.file_queue === 'qa-analyst') ||
      (userRole === 'qa' &&
        (file.file_queue === 'qa' || file.file_queue === 'admin-stage')) ||
      (userRole === 'qa-admin' &&
        (file.file_queue === 'qa-admin' ||
          file.file_queue === 'queue-completed'))
    return editable
  }

  const checkForExistingFile = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/global/file/check`,
        {
          file_id: file._id,
          file_queue: 'upload-readline',
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        setUploadReadlineFiles({
          readline_file_id: response.data.readline_file_id,
          readline_file_name: response.data.readline_file_name,
        })
        setFiles([response.data])
        return true
      } else {
        console.log(
          'checkForExistingFile: No file exists for file_id:',
          file._id
        )
        setFiles([])
        setError(response.data.message)
        return false
      }
    } catch (error) {
      setError('Failed to check for existing file')
      return null
    }
  }

  useEffect(() => {
    if (!file) {
      setError('File is undefined')
      return
    }

    const fetchDocument = async () => {
      try {
        const url =
          userRole === 'user'
            ? `${BASE_URL}/user/file/view/${file._id}`
            : `${BASE_URL}/global/file/view/${file._id}`

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        })

        if (response.status === 200) {
          let fileUrl = response.data.url
          console.log('FileURL: ', fileUrl)

          // Check if the file is a DOCX and use Google Docs Viewer
          if (file.filename.endsWith('.docx')) {
            fileUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
              fileUrl
            )}&embedded=true`
          }

          setDocumentUrl(fileUrl)

          if (userRole !== 'user') {
            setAdminId(response.data.admin_id)
            setAdminUsername(response.data.admin_username)
          }
        }
      } catch (error) {
        console.error('fetchDocument: Error fetching document:', error)
      }
    }

    const fetchFileDetails = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/global/file-details/${file._id}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        if (response.status === 200) {
          const { fileAnswerMode, qc_uid, discuss_point, is_contract_silent } =
            response.data.data
          setBuyerSeller(fileAnswerMode)
          // setCategories(categoryName)
          setDiscussPoint(discuss_point)
          setCategoryUid(qc_uid)
        }
      } catch (error) {
        console.error('fetchFileDetails: Error fetching file details:', error)
      }
    }
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/global/questions-by-categories/${file.qc_id.qc_id}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )

        if (response.status === 200) {
          const questionIds = response.data.questions.map(
            (question) => question._id
          )
          setQuestions(response.data.questions)
          setQuestionIds(questionIds)
        }
      } catch (error) {
        console.error('fetchQuestions: Error fetching questions:', error)
      }
    }

    checkForExistingFile()
    fetchDocument()
    fetchQuestions()
    fetchFileDetails()
    setUserId(file.user_id._id)

    const iframeDocument =
      iframeRef.current?.contentDocument ||
      iframeRef.current?.contentWindow.document
    if (iframeDocument) {
      iframeDocument.addEventListener('mouseup', handleTextSelection, {
        passive: true,
      })
      iframeDocument.addEventListener('resize', handleResize, {
        passive: true,
      })
      iframeDocument.addEventListener('touchmove', handleTouchMove, {
        passive: true,
      })
      iframeDocument.addEventListener('touchstart', handleTouchStart, {
        passive: true,
      })
      iframeDocument.addEventListener('wheel', handleWheel, { passive: true })
    }

    return () => {
      if (iframeDocument) {
        iframeDocument.removeEventListener('mouseup', handleTextSelection)
        iframeDocument.removeEventListener('resize', handleResize)
        iframeDocument.removeEventListener('touchmove', handleTouchMove)
        iframeDocument.removeEventListener('touchstart', handleTouchStart)
        iframeDocument.removeEventListener('wheel', handleWheel)
      }
    }
  }, [file._id, userToken])

  useEffect(() => {
    if (questions.length > 0) {
      loadAnswer(currentQuestionIndex)
    }
  }, [questions, submittedAnswers, currentQuestionIndex])

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const answersResponse = await axios.post(
          `${BASE_URL}/global/file/get-answers`,
          { file_id: file._id },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        if (answersResponse.status === 200) {
          console.log(
            'fetchAnswers: Answers fetched:',
            answersResponse.data.answers
          )
          setSubmittedAnswers(answersResponse.data.answers)
          filterQuestionsWithComments(answersResponse.data.answers)
          const initialRemarks = {}
          answersResponse.data.answers.forEach((answer) => {
            initialRemarks[answer.question_id] = answer.remarks || ''
          })
          setAllRemarks(initialRemarks)
          loadAnswer(0)
        }
      } catch (error) {
        console.error('fetchAnswers: Error fetching answers:', error)
      }
    }
    fetchAnswers()
  }, [questions, userToken])

  const filterQuestionsWithComments = (answers) => {
    const commentedQuestions = answers.filter(
      (answer) => answer.remarks && answer.remarks.trim() !== ''
    )
    const questionIdsWithComments = commentedQuestions.map(
      (answer) => answer.question_id
    )
    const filtered = questions.filter((question) =>
      questionIdsWithComments.includes(question._id)
    )
    setFilteredQuestions(filtered)
  }

  const handleQuestionSelect = (event) => {
    const selectedQuestionId = event.target.value
    const selectedIndex = questions.findIndex(
      (question) => question._id === selectedQuestionId
    )
    if (selectedIndex >= 0) {
      setCurrentQuestionIndex(selectedIndex)
    }
  }

  const handleTextSelection = () => {
    const iframeDocument =
      iframeRef.current?.contentDocument ||
      iframeRef.current?.contentWindow.document
    const selectedText = iframeDocument.getSelection().toString()
    if (selectedText) {
      // setHighlights((prevHighlights) => [...prevHighlights, selectedText])
    }
  }

  const handleDownload = async (file, e) => {
    e.stopPropagation()
    try {
      const response = await axios.get(
        `${BASE_URL}/global/download/${file._id}`,
        {
          responseType: 'blob', // Important for downloading files
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', file.filename) // or the filename you want
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  const handleResize = () => {
    console.log('handleResize: Iframe resized')
    setTimeout(() => {}, 0)
  }

  const handleTouchMove = (event) => {
    console.log('handleTouchMove: Touch move detected')
  }

  const handleTouchStart = (event) => {
    console.log('handleTouchStart: Touch start detected')
  }

  const handleWheel = (event) => {
    console.log('handleWheel: Wheel event detected')
  }

  const handleNextQuestion = () => {
    console.log('handleNextQuestion: Moving to next question')
    updateAnswer()
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
      loadAnswer(currentQuestionIndex + 1)
    }
  }

  const handlePreviousQuestion = () => {
    updateAnswer()
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1)
      loadAnswer(currentQuestionIndex - 1)
    }
  }

  const handleAnswerChange = (e) => {
    setAnswerText(e.target.value)
  }

  const handleAnswerSelect = (e) => {
    const selectedValue = JSON.parse(e.target.value) // Parse JSON string to object
    setSelectedAnswer(JSON.stringify(selectedValue))
  }

  const updateAnswer = () => {
    const currentQuestion = questions[currentQuestionIndex]
    const updatedAnswers = [...submittedAnswers]

    const existingAnswerIndex = updatedAnswers.findIndex(
      (answer) => answer.question_id === currentQuestion._id
    )

    const answerToStore = {
      question_id: currentQuestion._id,
      text: answerText,
      selectedAnswer: selectedAnswer, // Store the full selectedAnswer object
      category_id: currentQuestion.category_id,
      isCheckboxChecked: isCheckboxChecked,
      subcategory_id: currentQuestion.subcategory_id,
      remarks: allRemarks[currentQuestion._id] || '',
    }

    if (existingAnswerIndex >= 0) {
      updatedAnswers[existingAnswerIndex] = answerToStore
    } else {
      updatedAnswers.push(answerToStore)
    }
    setSubmittedAnswers(updatedAnswers)
  }

  const handleRemarksChange = (e) => {
    const value = e.target.value
    // setRemarks(value)
    setAllRemarks({
      ...allRemarks,
      [questions[currentQuestionIndex]._id]: value,
    })
  }

  const loadAnswer = (index) => {
    const question = questions[index]
    const existingAnswer = submittedAnswers.find(
      (answer) => answer.question_id === question._id
    )
    console.log('983ye8217te72', existingAnswer)
    if (existingAnswer) {
      setAnswerText(existingAnswer.text)
      const parsedAnswer =
        typeof existingAnswer.answer === 'string'
          ? existingAnswer
          : existingAnswer.selectedAnswer
      console.log(
        'loadAnswer: Existing answer loaded:',
        parsedAnswer.answer || parsedAnswer
      )
      // var answer = parsedAnswer.answer || parsedAnswer
      // setSelectedAnswer(JSON.parse(answer))
      let answer = parsedAnswer.answer || parsedAnswer
      if (typeof answer === 'string' && answer.includes('\\')) {
        answer = JSON.parse(answer)
      }
      setSelectedAnswer(answer)
      // setRemarks(existingAnswer.remarks || '')
      // setRemarks('')
      setIsCheckboxChecked(
        existingAnswer.is_contract_silent ||
          existingAnswer.isCheckboxChecked ||
          false
      )
    } else {
      setAnswerText('')
      const defaultAnswer = JSON.parse(question.answers)[0]
      setSelectedAnswer(defaultAnswer)
      setIsCheckboxChecked(false) // Default to unchecked if no answer exists
    }
  }

  const calculateProgress = () => {
    const answeredQuestions = submittedAnswers.filter(
      (answer) => answer.text && answer.selectedAnswer
    ).length
    const totalQuestions = questions.length
    const progress =
      totalQuestions > 0 ? (answeredQuestions / totalQuestions) * 100 : 0
    return progress
  }

  const isSubmitDisabled = () => {
    const disabled =
      submittedAnswers.filter((answer) => answer.text && answer.selectedAnswer)
        .length !== questions.length
    return disabled
  }

  const calculateCategoryAndSubCategoryScores = () => {
    const categoryScores = {}
    const subCategoryScores = {}
    submittedAnswers.forEach((answer) => {
      const question = questions.find((q) => q._id === answer.question_id)
      console.log(answer)
      if (question) {
        let selectedAnswer = answer.selectedAnswer

        // Check if selectedAnswer is a string, then parse it
        if (typeof selectedAnswer === 'string') {
          try {
            selectedAnswer = JSON.parse(selectedAnswer)
          } catch (e) {
            console.error('Error parsing selectedAnswer', e)
            return // skip this iteration if parsing fails
          }
        }

        const buyerScore = selectedAnswer.buyer_score || 0
        const sellerScore = selectedAnswer.seller_score || 0
        const scoreToAdd = buyerSeller === 'buyer' ? buyerScore : sellerScore

        if (!categoryScores[question.category_id]) {
          categoryScores[question.category_id] = {
            totalScore: 0,
            questionCount: 0,
          }
        }
        categoryScores[question.category_id].totalScore += scoreToAdd
        categoryScores[question.category_id].questionCount += 1

        if (!subCategoryScores[question.subcategory_id]) {
          subCategoryScores[question.subcategory_id] = {
            totalScore: 0,
            questionCount: 0,
          }
        }
        subCategoryScores[question.subcategory_id].totalScore += scoreToAdd
        subCategoryScores[question.subcategory_id].questionCount += 1
      }
    })

    const categoryScoresResult = Object.keys(categoryScores).reduce(
      (acc, categoryId) => {
        const { totalScore, questionCount } = categoryScores[categoryId]
        acc[categoryId] = totalScore / questionCount
        return acc
      },
      {}
    )

    const subCategoryScoresResult = Object.keys(subCategoryScores).reduce(
      (acc, subCategoryId) => {
        const { totalScore, questionCount } = subCategoryScores[subCategoryId]
        acc[subCategoryId] = totalScore / questionCount
        return acc
      },
      {}
    )

    return { categoryScoresResult, subCategoryScoresResult }
  }

  const handleSubmitAnswers = async () => {
    handleUploadReadline()
    updateAnswer()

    const { categoryScoresResult, subCategoryScoresResult } =
      calculateCategoryAndSubCategoryScores()

    let fileQueue = file.file_queue
    let isAnalystApproved = file.is_analyst_approved || 0
    let isQaApproved = file.is_qa_approved || 0
    let isQaAdminApproved = file.is_qa_admin_approved || 0

    if (userRole === 'qa-analyst') {
      fileQueue = 'qa'
      isAnalystApproved = 1
    } else if (userRole === 'qa') {
      fileQueue = 'admin-stage'
      isAnalystApproved = 1
      isQaApproved = 1
    } else if (userRole === 'qa-admin') {
      fileQueue = 'queue-completed'
      isAnalystApproved = 1
      isQaApproved = 1
      isQaAdminApproved = 1
    }
    let buyerScoreSum = 0
    let sellerScoreSum = 0
    console.log('Error: ', error)
    submittedAnswers.forEach((answer) => {
      let parsedAnswer = answer.selectedAnswer
      if (typeof parsedAnswer === 'string') {
        try {
          parsedAnswer = JSON.parse(parsedAnswer)
        } catch (e) {
          console.error('Error parsing answer:', parsedAnswer)
          return
        }
      }
      console.log('Parsed Answer:', parsedAnswer)
      console.log('Buyer Score:', parsedAnswer.buyer_score)
      console.log('Seller Score:', parsedAnswer.seller_score)

      buyerScoreSum += parsedAnswer.buyer_score || 0
      sellerScoreSum += parsedAnswer.seller_score || 0
    })

    const totalQuestions = questions.length

    let calculatedRiskScore = 0
    if (buyerSeller === 'buyer') {
      calculatedRiskScore = buyerScoreSum / totalQuestions
    } else if (buyerSeller === 'seller') {
      calculatedRiskScore = sellerScoreSum / totalQuestions
    }

    // setRiskScore(calculatedRiskScore)

    const answerData = {
      file_id: file._id,
      user_id: file.user_id.user_id,
      userId: file.user_id._id,
      admin_id: adminId,
      answers: submittedAnswers,
      question_id: questionIds,
      file_queue: fileQueue,
      is_analyst_approved: isAnalystApproved,
      is_qa_approved: isQaApproved,
      is_qa_admin_approved: isQaAdminApproved,
      default_answer_approved_by: adminUsername,
      default_answers: JSON.stringify(submittedAnswers),
      upload_readline_file_id: uploadReadlineFiles.readline_file_id,
      risk_score: calculatedRiskScore,
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/global/file-answers`,
        answerData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      await axios.post(
        `${BASE_URL}/global/update-file-scores`,
        {
          file_id: file._id,
          category_scores: categoryScoresResult,
          subcategory_scores: subCategoryScoresResult,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.status === 200) {
        // console.log('handleSubmitAnswers: Answers submitted successfully')
        alert('Answers submitted successfully!')
        setSubmittedAnswers([])
      }
    } catch (error) {
      console.error('handleSubmitAnswers: Error submitting answers:', error)
    }
  }

  const handleUploadReadline = async () => {
    const existingFile = await checkForExistingFile()

    if (existingFile) {
      setFiles([{ ...existingFile, filename: existingFile.filename }])
      return
    }
    if (selectedFile) {
      try {
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('admin_id', 1)

        const uploadResponse = await axios.post(
          `${BASE_URL}/global/upload-file`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        if (uploadResponse.data.status === 1) {
          const { filename, original_filename } = uploadResponse.data

          const secondResponse = await axios.post(
            `${BASE_URL}/user/upload-and-search`,
            {
              filename,
              original_filename,
              user_type: buyerSeller,
              qc_uid: categoryUid,
              admin_id: '1234',
              file_queue: 'upload-readline',
              user_id: userId,
              file_id_ref: file._id,
              upload_readline_file_id: uploadReadlineFiles.readline_file_id,
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
                'Content-Type': 'application/json',
              },
            }
          )

          if (secondResponse.data.status === 1) {
            const newFile = {
              filename: selectedFile.name,
              date: new Date().toLocaleDateString(),
              added_date: new Date().toLocaleString(),
              score: '',
              download: false,
              buyerSeller: buyerSeller,
            }
            const updatedFiles = [...files, newFile]
            setFiles(updatedFiles)
            setSelectedFile(null)
            alert(secondResponse.data.message)
          } else {
            console.error(
              'handleUploadReadline: Error during second upload step:',
              secondResponse.data.message
            )
            setError(
              secondResponse.data.message ||
                'An error occurred during the second upload step.'
            )
          }
        } else {
          console.error(
            'handleUploadReadline: Error during file upload:',
            uploadResponse.data.message
          )
          setError(
            uploadResponse.data.message ||
              'An error occurred during the file upload.'
          )
        }
      } catch (error) {
        console.error('handleUploadReadline: Error during file upload:', error)
        setError('An error occurred during file upload')
      }
    }
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <div className="document-viewer">
      <button className="go-back" onClick={handleBack}>
        Back to Workspace
      </button>
      <div className="document-main">
        <div className="document-content">
          {documentUrl ? (
            <iframe
              ref={iframeRef}
              src={documentUrl}
              title={file.filename}
              width="100%"
              height="100%"
            ></iframe>
          ) : (
            <p>Loading document...</p>
          )}
        </div>
        <div className="sidebar-document">
          <div className="file-info">
            <div>
              <strong>Document Name:</strong> {file.filename}
            </div>
            <div>
              <strong>User Type:</strong> {file.file_answer_mode}
            </div>
            <div>
              <strong>Category:</strong> {file.qc_id.name}
            </div>
            <div>
              <strong>Date Added:</strong>{' '}
              {new Date(file.added_date).toLocaleDateString()}
            </div>
            <div>
              <strong>Full Name:</strong> {file.full_name}
            </div>
            <button onClick={openModal} className="view-request-button">
              Click to View User's Request
            </button>
            <button
              className="doc-download"
              onClick={(e) => handleDownload(file, e)}
            >
              <span>Download the document</span>
              <img src={download} alt="Download Icon" />
            </button>
          </div>
          {filteredQuestions.length > 0 && (
            <div className="question-filter">
              <label htmlFor="question-select">Commented Questions: </label>
              <select id="question-select" onChange={handleQuestionSelect}>
                {filteredQuestions.map((question, index) => (
                  <option key={question._id} value={question._id}>
                    Question {index + 1}
                  </option>
                ))}
              </select>
            </div>
          )}
          {questions.length > 0 && (
            <div className="question-container">
              <div className="question-header">
                Question: {questions[currentQuestionIndex].question}
              </div>
              <div className="answer-header">Answers:</div>
              <select
                className="answer-dropdown"
                value={selectedAnswer}
                onChange={handleAnswerSelect}
                disabled={!isEditable()} // Disable based on role and file queue
                key={currentQuestionIndex}
              >
                {JSON.parse(questions[currentQuestionIndex].answers).map(
                  (answer, index) => (
                    <option key={index} value={JSON.stringify(answer)}>
                      {answer.answer}
                    </option>
                  )
                )}
              </select>
              <p style={{ fontWeight: 'bold' }}>
                Paste the section number and then the relevant text of the
                document below.
              </p>
              <div className="question-input">
                <input
                  className="question-input"
                  placeholder="Paste the section number and then the relevant text of the document below."
                  rows="4"
                  value={answerText}
                  onChange={handleAnswerChange}
                  disabled={!isEditable()} // Disable based on role and file queue
                ></input>
              </div>
              {/* Checkbox for QA, QA-Analyst, QA Admin */}
              {isEditable() && (
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isCheckboxChecked}
                    onChange={() => setIsCheckboxChecked(!isCheckboxChecked)} // Toggle checkbox state
                  />
                  <label>Contract to be Silent</label>{' '}
                </div>
              )}
              {isEditable() && userRole !== 'qa-admin' && (
                <div className="remarks-container">
                  <p style={{ fontWeight: 'bold' }}>Comments:</p>
                  <input
                    className="remarks-input"
                    placeholder="Comments"
                    rows="4"
                    value={
                      allRemarks[questions[currentQuestionIndex]._id] || ''
                    }
                    onChange={handleRemarksChange}
                  ></input>
                </div>
              )}
              <button onClick={handlePreviousQuestion}>Previous</button>
              <button onClick={handleNextQuestion}>Next</button>
            </div>
          )}
          {isEditable() && (
            <div className="review-progress">
              {files.length > 0 && (
                <div className="file-actions">
                  <button
                    onClick={async () => {
                      console.log(
                        'Delete button clicked for file:',
                        files[0].readline_file_id
                      )
                      try {
                        await axios.delete(
                          `${BASE_URL}/global/file/delete/${files[0].readline_file_id}`,
                          {
                            headers: {
                              Authorization: `Bearer ${userToken}`,
                            },
                          }
                        )
                        setFiles([]) // Clear the file from state
                        alert('File deleted successfully')
                      } catch (error) {
                        console.error('Error deleting file:', error)
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
              )}
              <div className="file-upload-container">
                <label className="file-upload-label">
                  <input
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    disabled={!isEditable() || files.length > 0}
                  />
                  <span>Upload Document</span>
                </label>
                {selectedFile ? (
                  <p>{selectedFile.name}</p>
                ) : (
                  uploadReadlineFiles &&
                  files.length > 0 && (
                    <p>{uploadReadlineFiles.readline_file_name}</p>
                  )
                )}
              </div>
              <button
                onClick={handleSubmitAnswers}
                disabled={!isEditable() || isSubmitDisabled()}
                style={{
                  backgroundColor:
                    !isEditable() || isSubmitDisabled() ? '#cccccc' : '#4CAF50',
                  color:
                    !isEditable() || isSubmitDisabled() ? '#666666' : 'white',
                  cursor:
                    !isEditable() || isSubmitDisabled()
                      ? 'not-allowed'
                      : 'pointer',
                }}
              >
                Submit
              </button>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width: `${calculateProgress()}%` }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="User's Request"
        className="Modal"
        overlayClassName="Overlay"
      >
        <button className="close-modal" onClick={closeModal}>
          X
        </button>
        <h2>User's Request</h2>
        <p>{discussPoint}</p>
      </Modal>
    </div>
  )
}
