import React, { useState, useEffect, useContext } from "react";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import AuthContext from "../AuthContext";
import { BASE_URL } from "../config";
import "../styles/main.css";
import useDebounce from "./useDebounce";
import { BeatLoader } from "react-spinners";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};
const RiskChart = (props) => {
  const [riskScores, setRiskScores] = useState([]);
  const { userToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [allData, setallData] = useState([]);

  useEffect(() => {
    var demoData = [];
    for (
      let index = 0;
      index < Object.keys(props.subcategoryScores).length;
      index++
    ) {
      demoData.push({
        documentName: Object.keys(props.subcategoryScores)[index],
        riskScore: Object.values(props.subcategoryScores)[index].toFixed(2),
      });
    }
    setallData(demoData);
    setRiskScores(demoData);
  }, [userToken]);
  const itemsPerPage = 10; // Set items per page
  const [currentPage, setCurrentPage] = useState(1);

  // Slice data based on pagination
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const pageData = allData.slice(startIndex, startIndex + itemsPerPage);
    return {
      categories: pageData.map((item) => item.documentName),
      seriesData: pageData.map((item) => item.riskScore * 100),
    };
  };

  const { categories, seriesData } = getCurrentPageData();
  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#F1C40F",
    "#8E44AD",
    "#2ECC71",
    "#E74C3C",
  ];

  const options = {
    annotations: {
      points: [
        {
          x: "Bananas",
          seriesIndex: 0,
          label: {
            borderColor: "#775DD0",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#775DD0",
            },
            text: "Bananas are good",
          },
        },
      ],
    },
    series: [
      {
        name: "Risk Score",
        // data: riskScores.map((score) => parseFloat(score.riskScore * 100)),
        data: seriesData,
      },
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: "20px",
      },
    },
    // dataLabels: {
    //   enabled: false,
    // },
    stroke: {
      width: 0,
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      // categories: riskScores.map((score) => score.documentName),
      categories,
      labels: {
        rotate: -45,

        style: {
          fontSize: "8px",
        },
      },
      // tickPlacement: "on",
    },
    yaxis: {
      tickAmount: 10, // Y-axis increments in steps of 10
      labels: {
        formatter: (val) => `${val}%`, // Format the y-axis as percentages
      },
    },
    title: {
      text: "Risk score",
      align: "left",
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "light",
    //     type: "horizontal",
    //     shadeIntensity: 0.25,
    //     gradientToColors: undefined,
    //     inverseColors: true,
    //     opacityFrom: 0.85,
    //     opacityTo: 0.85,
    //     stops: [50, 0, 100],
    //   },
    // },
    // colors: colors.slice(0, riskScores.length),
  };

  // Check if there's any data to display
  const hasData = riskScores.length > 0;
  // Pagination handlers
  const nextPage = () => {
    if (currentPage < Math.ceil(allData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="chart-wrapper">
      <h2 className="chart-title">Risk score</h2>

      <div id="chart3" className="chart-container">
        {!props.subcategoryScores && (
          <div className="overlay">
            <p className="overlay-text">No data available</p>
          </div>
        )}
        <div className={props.subcategoryScores ? "" : "blur-chart"}>
          {allData.length > 0 && (
            <ApexCharts
              options={options}
              series={options.series}
              type="bar"
              height={350}
            />
          )}
          <div style={{ marginTop: "20px" }}>
            <button onClick={prevPage} disabled={currentPage === 1}>
              Previous
            </button>
            <button
              onClick={nextPage}
              disabled={
                currentPage === Math.ceil(allData.length / itemsPerPage)
              }
            >
              Next
            </button>
            <p>Page {currentPage}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskChart;
