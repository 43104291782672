import React, { useContext, useState, useEffect } from 'react'
import './styles/App.css'
import logo from './img/logo-svg.png'
import qaLogo from './img/logo.png'
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom'
import Home from './components/Home'
import NewRequest from './components/NewRequest'
import QaAdminProfile from './components/QaAdmin/QaAdminProfile'
import CompletedAnalyses from './components/CompletedAnalyses'
import WorkSpace from './components/WorkSpace'
import HomePage from './components/User/UserHome'
import LegalConciergeRep from './components/LegalConciergeRep'
import DocumentViewer from './components/DocumentViewer'
import AuthContext, { AuthProvider } from './AuthContext'
import QAFetchContext, { QAFetchProvider } from './QaFetchContext'
import Task from './components/Task'
import Discover from './components/Discover'
import Reports from './components/Reports'
import AdminCompletedAnalysis from './components/AdminCompletedAnalysis'
import logoutLogo from './img/logout.png'
import Accounts from './components/Accounts'
import Invite from './components/Invite'
import ErrorBoundary from './components/ErrorBoundary'
import QaAdminTools from './components/QaAdmin/QaAdminTools'
import Auth from './components/Auth'
import TermsAndConditions from './components/Terms'
import PrivacyPolicy from './components/Privacy'
import Documents from './components/User/Documents'
import Settings from './components/User/Settings'
import companyLogoDefault from './img/organization.png'
import chevronDownIcon from './img/down-chevron.png'
import chevronUpIcon from './img/up-chevron.png'
import SendDocument from './components/SendDocument'
import EnterPassword from './components/Enterpassword'
import ReadLine from './components/User/ReadLine'
import Request from './components/Request'
import ForgotPassword from './components/ForgotPassword'
import Risk from './components/Risk'
import axios from 'axios'
import { BASE_URL } from './config'
import DiscoverDetails from './components/DiscoverDetails'
import Procurement from './components/Procurement'
import Extract from './Extract'

function AppContent() {
  const navigate = useNavigate()
  const location = useLocation() // Hook to get current route
  const { isAuthenticated, logout, userRole, userToken, userEmail } =
    useContext(AuthContext)
  const [selectedTab, setSelectedTab] = useState('Home')
  // const [userDetails, setUserDetails] = useState({})
  const [newCompanyName, setNewCompanyName] = useState('')
  const [newCompanyLogo, setNewCompanyLogo] = useState(companyLogoDefault)
  const [error, setError] = useState('')
  const [showLogout, setShowLogout] = useState(false)
  // const [showSettingsMenu, setShowSettingsMenu] = useState(false)
  const [isUserLink, setIsUserLink] = useState(false)

  useEffect(() => {
    const savedTab = localStorage.getItem('selectedTab')
    if (savedTab) {
      setSelectedTab(savedTab)
    }
    setIsUserLink(window.location.pathname.includes('user-link'))
  }, [])

  // Update selected tab based on the current location pathname
  useEffect(() => {
    const pathToTabMap = {
      '/': 'Home',
      '/home': 'Home',
      '/user-home': 'Home',
      '/tasks': 'Task',
      '/extract': 'Extract',
      '/accounts': 'Accounts',
      '/documents': 'Documents',
      '/settings': 'Settings',
      '/redline': 'RedLine',
      '/risk': 'Risk',
      '/reports': 'Reports',
      '/discover': 'discover',
      '/request': 'request',
      '/procurement': 'procurement',
      // Add other paths as needed
    }
    const currentTab = pathToTabMap[location.pathname] || 'Home'
    setSelectedTab(currentTab)
  }, [location.pathname]) // This runs whenever the route changes

  const handleTabChange = (tab) => {
    setSelectedTab(tab)
    localStorage.setItem('selectedTab', tab)
  }

  useEffect(() => {
    if (userRole === 'user') {
      const fetchUserDetails = async () => {
        try {
          const response = await axios.post(
            `${BASE_URL}/${userRole}/getUser`,
            { userEmail },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
                'Content-Type': 'application/json',
              },
            }
          )
          if (response.data.status === 1) {
            setNewCompanyLogo(response.data.url)
            setNewCompanyName(response.data.data.company_name)
          } else {
            setError(response.data.message)
          }
        } catch (error) {
          setError('Failed to fetch user details')
        }
      }
      fetchUserDetails()
    }
  }, [userToken, userEmail, userRole])

  if (!isAuthenticated && !isUserLink) {
    return (
      <div className="auth-wrapper">
        <div className="auth-container">
          <Routes>
            <Route path="/" element={<Auth />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="" element={<Auth />} />
          </Routes>
        </div>
      </div>
    )
  }
  if (!isAuthenticated && isUserLink) {
    return (
      <div className="app">
        <div className="container">
          <Routes>
            <Route path="/user-link/:token" element={<SendDocument />} />
          </Routes>
        </div>
      </div>
    )
  }
  // const toggleSettingsMenu = () => {
  //   setSelectedTab('Settings')
  //   setShowSettingsMenu((prev) => !prev)
  // }
  console.log('Error:', error)
  const toggleLogoutMenu = () => {
    setShowLogout(!showLogout)
  }

  const renderSidebar = () => {
    switch (userRole) {
      case 'qa':
        return (
          <>
            <Link to="/home">
              <button id="Home" onClick={() => handleTabChange('Home')}>
                Home
              </button>
            </Link>
            <Link to="/workspace">
              <button id="WorkSpace">WorkSpace</button>
            </Link>
            {/* <Link to="/completed">
              <button id="Completed">Completed</button>
            </Link>
            <Link to="/profile">
              <button id="Profile">Profile</button>
            </Link> */}
          </>
        )
      case 'qa-analyst':
        return (
          <>
            <Link to="/home">
              <button id="Home" onClick={() => handleTabChange('Home')}>
                Home
              </button>
            </Link>
            <Link to="/workspace">
              <button id="WorkSpace">WorkSpace</button>
            </Link>
            {/* <Link to="/completed">
              <button id="Completed">Completed</button>
            </Link>
            <Link to="/profile">
              <button id="Profile">Profile</button>
            </Link> */}
          </>
        )
      case 'qa-admin':
        return (
          <>
            <Link to="/home">
              <button id="Home" onClick={() => handleTabChange('Home')}>
                Home
              </button>
            </Link>
            <Link to="/workspace">
              <button
                id="WorkSpace"
                onClick={() => handleTabChange('WorkSpace')}
              >
                WorkSpace
              </button>
            </Link>
            <Link to="/completed">
              <button id="Completed">Completed</button>
            </Link>
            <Link to="/qaadmin-tools">
              <button
                id="AdminTools"
                onClick={() => handleTabChange('AdminTools')}
              >
                Admin Tools
              </button>
            </Link>
            <Link to="/qaadmin-profile">
              <button id="Profile" onClick={() => handleTabChange('Profile')}>
                Profile
              </button>
            </Link>
          </>
        )
      default: // general user
        return (
          <>
            <Link to="/user-home">
              <button
                id="Home"
                className={selectedTab === 'Home' ? 'active' : ''}
                onClick={() => handleTabChange('Home')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-home"
                >
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg>
                &nbsp; Home
              </button>
            </Link>{' '}
            <Link to="/tasks">
              <button
                id="Task"
                className={selectedTab === 'Task' ? 'active' : ''}
                onClick={() => handleTabChange('Task')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-server"
                >
                  <rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect>
                  <rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect>
                  <line x1="6" y1="6" x2="6.01" y2="6"></line>
                  <line x1="6" y1="18" x2="6.01" y2="18"></line>
                </svg>
                &nbsp; Task
              </button>
            </Link>
            <Link to="/redline">
              <button
                id="Redline"
                className={selectedTab === 'RedLine' ? 'active' : ''}
                onClick={() => handleTabChange('RedLine')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-zap"
                >
                  <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                </svg>
                &nbsp; Negotiation
              </button>
            </Link>
            {/* <Link to="/reports">
              <button
                id="Reports"
                className={selectedTab === "Reports" ? "active" : ""}
                onClick={() => handleTabChange("Reports")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-pie-chart"
                >
                  <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                  <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                </svg>
                &nbsp; Reports
              </button>
            </Link> */}
            {/* <Link to="/accounts">
              <button
                id="Accounts"
                className={selectedTab === 'Accounts' ? 'active' : ''}
                onClick={() => handleTabChange('Accounts')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-users"
                >
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
                &nbsp; Accounts
              </button>
            </Link> */}
            <Link to="/documents">
              <button
                id="UserDocuments"
                onClick={() => handleTabChange('Documents')}
                className={selectedTab === 'Documents' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-file-text"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
                &nbsp; Vault
              </button>
            </Link>
            <Link to="/settings">
              <button
                id="Settings"
                className={selectedTab === 'Settings' ? 'active' : ''}
                onClick={() => handleTabChange('Settings')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-settings"
                >
                  <circle cx="12" cy="12" r="3"></circle>
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </svg>
                &nbsp; Settings
              </button>
            </Link>
            {/* <Link to="/risk">
              <button
                id="Risk"
                className={selectedTab === 'Risk' ? 'active' : ''}
                onClick={() => handleTabChange('Risk')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-alert-triangle"
                >
                  <path d="M12 2L2 20h20L12 2z"></path>
                  <line x1="12" y1="9" x2="12" y2="13"></line>
                  <line x1="12" y1="17" x2="12" y2="17"></line>
                </svg>
                &nbsp; Risk
              </button>
            </Link>
             */}
            <div className="w-100 pad-20px">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item border-radius-10">
                  <h2 class="accordion-header mt-0">
                    <button
                      onClick={() => {
                        handleTabChange('procurement')
                        navigate('/procurement')
                      }}
                      className={
                        selectedTab === 'procurement'
                          ? 'accordion-button proc_btns_drp  m-0 active'
                          : 'accordion-button proc_btns_drp  m-0'
                      }
                      type="button"
                    >
                      <div
                        className="drop_opeen_click"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-down"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-shopping-cart"
                      >
                        <circle cx="9" cy="21" r="1"></circle>
                        <circle cx="20" cy="21" r="1"></circle>
                        <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                      </svg>
                      &nbsp;&nbsp;&nbsp;&nbsp;Procurement
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body p-0">
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleTabChange('discover')
                          navigate('/discover')
                        }}
                        className={
                          selectedTab === 'discover'
                            ? 'disc_tab_btn active1'
                            : 'disc_tab_btn'
                        }
                      >
                        Discover
                      </span>
                    </div>
                    <div class="accordion-body p-0">
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleTabChange('request')
                          navigate('/request')
                        }}
                        className={
                          selectedTab === 'request'
                            ? 'disc_tab_btn active1'
                            : 'disc_tab_btn'
                        }
                      >
                        Request
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Link to="/reports">
              <button
                id="Reports"
                className={selectedTab === 'Reports' ? 'active' : ''}
                onClick={() => handleTabChange('Reports')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-pie-chart"
                >
                  <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                  <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                </svg>
                &nbsp; Extract
              </button>
            </Link> */}
            <Link to="/extract">
              <button
                id="Reports"
                className={selectedTab === 'Extract' ? 'active' : ''}
                onClick={() => handleTabChange('Reports')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-shuffle"
                >
                  <polyline points="16 3 21 3 21 8"></polyline>
                  <line x1="4" y1="20" x2="21" y2="3"></line>
                  <polyline points="21 16 21 21 16 21"></polyline>
                  <line x1="15" y1="15" x2="21" y2="21"></line>
                  <line x1="4" y1="4" x2="9" y2="9"></line>
                </svg>
                &nbsp; Extract
              </button>
            </Link>
            {/* <div className="settings-menu">
              <button
                id="Settings"
                className={selectedTab === 'Settings' ? 'active' : ''}
                onClick={toggleSettingsMenu}
              >
                <img
                  className="icon-settings"
                  src={settingsLogo}
                  alt="settings logo"
                  style={{ width: '14px', height: '14px' }}
                />
                Settings
              </button>
              {showSettingsMenu && (
                <div className="submenu">
                  <Link id="submenu-password" to="/user-settings/password">
                    <button
                      id="User-Password"
                      className={
                        selectedTab === 'User-Password' ? 'active' : ''
                      }
                      onClick={() => handleTabChange('User-Password')}
                    >
                      Change Password
                    </button>
                  </Link>
                </div>
              )}
            </div> */}
            {/* <Link to="/home">
              <button id="Home" onClick={() => handleTabChange('Home')}>
                Dashboard{' '}
              </button>{' '}
            </Link>
            <Link to="/completed-analyses">
              <button
                id="CompletedAnalyses"
                onClick={() => handleTabChange('CompletedAnalyses')}
              >
                Completed Analyses
              </button>
            </Link>
            {/* <Link to="/legal-concierge-rep">
              <button
                id="LegalConciergeRep"
                onClick={() => handleTabChange('LegalConciergeRep')}
              >
                Legal Concierge Reporting
              </button>
            </Link> */}
          </>
        )
    }
  }

  return (
    <div className="App">
      {!isUserLink && (
        <div>
          {userRole === 'user' ? (
            <div className="sidenav user">
              <div className="logo-class user">
                <div className='logo_wrps mrgbtm'></div>
              </div>
              {renderSidebar()}
              {/* <button className="logout-button user" onClick={logout}>
            <img
              className="icon-logout user"
              src={logoutLogo}
              alt="logout logo"
              style={{
                width: '14px',
                height: '14px',
                fontWeight: 'bolder',
              }}
            ></img>{' '}
            Log Out
          </button> */}
              <div className="logout-button user">
                <div className="company-info" onClick={toggleLogoutMenu}>
                  <img
                    className="company-logo mx-0"
                    src={newCompanyLogo || companyLogoDefault}
                    alt="Company Logo"
                    style={{ width: '30px', height: '30px' }}
                  />
                  <div className="company-name mx-2">
                    {newCompanyName || 'Company Name'}
                  </div>
                  <img
                    className="down-icons mx-0"
                    src={showLogout ? chevronUpIcon : chevronDownIcon}
                    alt="chevron"
                  />
                </div>
                {showLogout && (
                  <div className="submenu">
                    <button className="logout-button user" onClick={logout}>
                      <img
                        className="icon-logout user"
                        src={logoutLogo}
                        alt="logout logo"
                        style={{
                          width: '14px',
                          height: '14px',
                          fontWeight: 'bolder',
                        }}
                      ></img>{' '}
                      Log Out
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="sidenav">
              <div className="logo-class">
                <img
                  className="logo-image"
                  src={qaLogo}
                  alt="modInsight Logo"
                />
              </div>
              {renderSidebar()}
              <button className="logout-button" onClick={logout}>
                <img
                  className="icon-logout"
                  src={logoutLogo}
                  alt="logout logo"
                  style={{
                    width: '14px',
                    height: '14px',
                    fontWeight: 'bolder',
                  }}
                ></img>{' '}
                Log Out
              </button>
            </div>
          )}
        </div>
      )}

      <div className="content" style={{ marginLeft: isUserLink ? '0' : '220' }}>
        <Routes>
          {userRole === 'user' ? (
            <Route path="/" element={<HomePage />} />
          ) : (
            <Route path="/" element={<Home />} />
          )}
          <Route path="/home" element={<Home />} />
          <Route path="/user-home" element={<HomePage />} />
          <Route path="/new-request" element={<NewRequest />} />
          <Route path="/tasks" element={<Task />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/extract" element={<Extract />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/procurement" element={<Procurement />} />

          <Route path="/discover-details" element={<DiscoverDetails />} />

          <Route path="/accounts" element={<Accounts />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/qaadmin-profile" element={<QaAdminProfile />} />
          <Route path="/qaadmin-tools" element={<QaAdminTools />} />
          <Route path="/qaadmin-profile" element={<QaAdminProfile />} />
          <Route path="/redline" element={<ReadLine />} />
          <Route path="/request" element={<Request />} />
          <Route path="/qaadmin-profile" element={<QaAdminProfile />} />
          <Route path="/qaadmin-tools" element={<QaAdminTools />} />
          <Route path="/completed" element={<AdminCompletedAnalysis />} />
          <Route path="/completed-analyses" element={<CompletedAnalyses />} />
          <Route path="/workspace" element={<WorkSpace />} />
          <Route path="/legal-concierge-rep" element={<LegalConciergeRep />} />
          <Route path="/document-viewer/:fileId" element={<DocumentViewer />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/user-link/:token" element={<SendDocument />} />
          <Route path="/enterpassword" element={<EnterPassword />} />
          <Route path="/risk" element={<Risk />} />
        </Routes>
      </div>
    </div>
  )
}

function App() {
  return (
    <QAFetchProvider>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </QAFetchProvider>
  )
}

export default App
