import React, { useEffect, useState } from "react";
import "../styles/SendDocument.css";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
const SendDocument = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const [is_password_required, setis_password_required] = useState(false);
  const [file_path, setfile_path] = useState("");
  const [fileURL, setfileURL] = useState();
  const [password, setpassword] = useState("");
  const [file_details, setfile_details] = useState("");
  const [googleDocsViewerUrl, setgoogleDocsViewerUrl] = useState("");
  const { token } = useParams();
  useEffect(() => {
    if (token) {
      getDetails();
    }
  }, []);
  const getDetails = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/files/link/details`,
        {
          token: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === 1) {
        console.log(response.data.data);
        setis_password_required(response.data.data.is_password_required);
        setfile_details(response.data.data.file_info);
        setfile_path(response.data.data.file_path);
        setgoogleDocsViewerUrl(
          `https://docs.google.com/gview?url=${
            response.data.data.file_path +
            "/" +
            response.data.data.file_info.filename_encoded
          }&embedded=true`
        );
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };
  useEffect(() => {
    console.log(fileURL);
  }, [fileURL]);

  const enterPassword = async () => {
    if (password) {
      try {
        const response = await axios.post(
          `${BASE_URL}/files/link/verify-password`,
          {
            token: token,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === 1) {
          console.log(response.data.data);
          setis_password_required("no");
          setfile_details(response.data.data.file_details);
          setfile_path(response.data.data.file_path);
          setgoogleDocsViewerUrl(
            `https://docs.google.com/gview?url=${
              response.data.data.file_path +
              "/" +
              response.data.data.file_details.filename_encoded
            }&embedded=true`
          );
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    } else {
      toast.error("Please enter password");
    }
  };
  return (
    <div className="wrapper">
      <div className="content-area px-4 pt-3">
        {is_password_required == "yes" && (
          <div className="enterpaeewordwrap">
            <div>
              <h6 className="mb-2">
                <b>Please Enter Password</b>
              </h6>
              <input
                type="password"
                onChange={(e) => setpassword(e.target.value)}
                className="inp-paas"
              ></input>
              <button
                type="button"
                onClick={enterPassword}
                className="go-back mx-auto mt-3 w-100"
              >
                Submit
              </button>
            </div>
          </div>
        )}
        {is_password_required == "no" && (
          <div className="docsections d-flex">
            <div className="viewdocsec" style={{ marginLeft: "50px" }}>
              {file_details?.filename_encoded && (
                <div style={{ height: "100vh", overflow: "auto" }}>
                  {file_details?.filename_encoded.includes("pdf") && (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={
                          file_path + "/" + file_details?.filename_encoded
                        }
                      />
                    </Worker>
                  )}
                  {file_details?.filename_encoded.includes("docx") &&
                    file_details?.filename_encoded && (
                      <iframe
                        src={googleDocsViewerUrl}
                        style={{ width: "100%", height: "100vh" }}
                        frameBorder="0"
                      />
                    )}
                </div>
              )}
            </div>
            <div className="docdetsilsec px-2">
              <div>
                <h3 className="docdethead">Document Details</h3>
                <table className="docdettable">
                  <tr>
                    <td>Document Name</td>
                    <td>{file_details?.filename_encoded}</td>
                  </tr>
                  <tr>
                    <td>Category</td>
                    <td>{file_details?.qc_id?.name}</td>
                  </tr>
                  <tr>
                    <td>Submit Date</td>
                    <td>
                      {dayjs(file_details?.added_date).format("DD MMM YYYY")}
                    </td>
                  </tr>

                  <tr>
                    <td>Risk Score</td>
                    <td>
                      {file_details?.risk_score &&
                        (file_details?.risk_score * 100).toFixed(2) + "%"}
                      {!file_details?.risk_score && "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Owned by</td>
                    <td>
                      {file_details?.user_id?.fname}{" "}
                      {file_details?.user_id?.lname}{" "}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SendDocument;
