import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import AuthContext from '../AuthContext'
import { BASE_URL } from '../config'
import '../styles/WorkSpace.css'
import DocumentViewer from './DocumentViewer'

export default function AdminCompletedAnalysis() {
  const [files, setFiles] = useState([])
  const [completedFiles, setCompletedFiles] = useState([])
  const [error, setError] = useState('')
  const { userToken, userRole } = useContext(AuthContext)
  const [selectedFile, setSelectedFile] = useState(null)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const { file_queue } = useParams('')

  const fetchCompletedFiles = async (page) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/file/list`,
        {
          page: page.toString(),
          size: '10',
          file_queue: 'queue-completed',
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        setFiles(response.data.data)
        setTotalPages(response.data.totalPages)
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to fetch files')
    }
  }
  const fetchPrevCompletedFiles = async (page) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/file/list`,
        {
          page: page.toString(),
          size: '10',
          file_queue: 'back-client',
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        setCompletedFiles(response.data.data)
        setTotalPages(response.data.totalPages)
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to fetch files')
    }
  }
  useEffect(() => {
    // if (file_queue !== fileQueue) {
    //   setFileQueue(file_queue) // Update local state with new URL param
    fetchPrevCompletedFiles(page)
    fetchCompletedFiles(page)
  }, [file_queue, userToken, page])

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1)
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const handleEditClick = (file, e) => {
    e.stopPropagation()
    setSelectedFile(file)
  }

  const handleBack = () => {
    setSelectedFile(null)
  }

  // const resetFileQueue = () => {
  //   setFileQueue(null)
  //   navigate('/workspace')
  // }

  const handleReturnToClient = async (file) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/qa-admin/file/reassign`,
        {
          file_id: file._id,
          file_queue: 'back-client', // Set the file queue to "back-client"
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        alert('File returned to client successfully')
        fetchCompletedFiles(page) // Refresh the file list
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to return file to client')
    }
  }

  const renderFileActions = (file, section) => {
    if (section === 'processedFiles') {
      return (
        <div className="file-actions">
          <button onClick={(e) => handleEditClick(file, e)}>Edit</button>
          <button
            onClick={(e) => {
              e.stopPropagation()
              handleReturnToClient(file)
            }}
          >
            Return to Client
          </button>
        </div>
      )
    } else if (section === 'previouslyprocessedFiles') {
      return (
        <div className="file-actions">
          <button onClick={(e) => handleEditClick(file, e)}>View</button>
        </div>
      )
    }
  }

  const processedFiles = files.filter(
    (file) => file.file_queue === 'queue-completed'
  )

  const prevProcessedFiles = completedFiles.filter(
    (file) => file.file_queue === 'back-client'
  )

  return (
    <div className="workspace">
      <h2>Completed Files</h2>
      {selectedFile ? (
        <DocumentViewer file={selectedFile} handleBack={handleBack} />
      ) : (
        <>
          {userRole === 'qa-admin' && processedFiles.length > 0 && (
            <div className="section">
              <div className="section-head">
                Files need to be returned back to client
              </div>
              <table className="file-table">
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Submit Date</th>
                    <th>File Queue</th>
                    <th>Company Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {processedFiles.map((file) => (
                    <tr key={file._id}>
                      <td>{file.filename}</td>
                      <td>{new Date(file.added_date).toLocaleDateString()}</td>
                      <td>{file.file_queue}</td>
                      <td>{file.user_id.company_name}</td>
                      <td>{renderFileActions(file, 'processedFiles')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {userRole === 'qa-admin' && prevProcessedFiles.length > 0 && (
            <div className="section">
              <div className="section-head">Previously Completed Files</div>
              <table className="file-table">
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Submit Date</th>
                    <th>File Queue</th>
                    <th>Company Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {prevProcessedFiles.map((file) => (
                    <tr key={file._id}>
                      <td>{file.filename}</td>
                      <td>{new Date(file.added_date).toLocaleDateString()}</td>
                      <td>{file.file_queue}</td>
                      <td>
                        {file.user_id ? file.user_id.company_name : 'N/A'}
                      </td>
                      <td>
                        {renderFileActions(file, 'previouslyprocessedFiles')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={page === 0}>
              Previous
            </button>
            <span>
              Page {page + 1} of {totalPages}
            </span>
            <button onClick={handleNextPage} disabled={page === totalPages - 1}>
              Next
            </button>
          </div>

          {error && <p className="error">{error}</p>}
        </>
      )}
    </div>
  )
}
