import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import logosl from "../img/slack.png";
import { useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext";
import axios from "axios";
import { BASE_URL } from "../config";
import { toast } from "react-toastify";

const DiscoverDetails = () => {
  const navigate = useNavigate();
  const [showModal, setshowModal] = useState(false);
  const [PopupLoader, setPopupLoader] = useState(false);
  const { userToken } = useContext(AuthContext);
  const [discoverDetails, setdiscoverDetails] = useState("");
  const [questionList, setquestionList] = useState([]);
  const company_id = localStorage.getItem("company_name");
  useEffect(() => {
    const fetchDiscoverList = async () => {
      setPopupLoader(true);
      try {
        const response = await axios.post(
          `${BASE_URL}/user/discover/company-details`,
          {
            company_id: company_id,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.status === 1) {
          //   console.log(response.data);
          setdiscoverDetails(response.data.data.details);
        } else {
          //   alert(response.data?.data?.message || "Failed to fetch categories");
        }
      } catch (error) {
        // alert("Failed to fetch categories");
      } finally {
        setPopupLoader(false);
      }
    };
    fetchDiscoverList();
  }, []);
  const formatCurrency = (num) => {
    if (Math.abs(num) >= 1_000_000_000) {
      return `${(num / 1_000_000_000).toFixed(1)}B`; // Billion
    } else if (Math.abs(num) >= 1_000_000) {
      return `${(num / 1_000_000).toFixed(1)}M`; // Million
    } else if (Math.abs(num) >= 1_000) {
      return `${(num / 1_000).toFixed(1)}K`; // Thousand
    } else {
      return num.toString(); // Less than 1K
    }
  };
  const formatCurrency2 = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/user/discover/get-questions`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.data.status === 1) {
          const list = [];
          for (let index = 0; index < response.data.data.list.length; index++) {
            response.data.data.list[index].answer = "";
            list.push(response.data.data.list[index]);
          }
          console.log(list);
          setquestionList(list);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchQuestionList();
    fetchQuestion();
  }, []);
  const onSubmit = async () => {
    console.log(questionList);
    try {
      const response = await axios.post(
        `${BASE_URL}/user/discover/save-question-answers`,
        {
          company_id: company_id,
          answers: questionList,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data.status === 1) {
        console.log(response.data);
        toast.success(response.data.message);
        setshowModal(false);
        fetchQuestionList();
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchQuestionList = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/discover/get-saved-question-answers`,
        { company_id: company_id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data.status === 1) {
        console.log(response.data);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="wrapper">
      <div className="content-area px-4 pt-3" style={{ marginLeft: "0px" }}>
        <header className="header-flex">
          <div></div>
          <Header />
        </header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 d-flex">
              <button className="go-back2 " onClick={() => navigate(-1)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>&nbsp;
                Go Back
              </button>
            </div>
            <div className="col-12 d-flex">
              <div className="card_discovers fix_cards_wid p-0">
                {!discoverDetails.name && (
                  <div className="card_loader">
                    <svg viewBox="25 25 50 50" className="svgsss">
                      <circle r="20" cy="50" cx="50"></circle>
                    </svg>
                  </div>
                )}

                <div className="comnies_header">
                  <img src={discoverDetails?.icon}></img>
                  <p>
                    {discoverDetails?.name} {!discoverDetails?.name && "N/A"}
                  </p>
                </div>
                <div className="insights_comp px-4">
                  <p className="mt-4 mb-3">Insights</p>

                  <div className="d-flex justify-content-between pb-3 border-bottom">
                    <div className="insgh_cards">
                      <p>0%</p>
                      <p className="net2">Network Usage</p>
                    </div>
                    <div className="insgh_cards">
                      <p>0</p>
                      <p className="net2">Avg. Savings</p>
                    </div>
                    <div className="insgh_cards">
                      <p>0 days</p>
                      <p className="net2">Avg. Request Duration</p>
                    </div>
                  </div>
                  <div className="py-3">
                    <p className="ins_p2">
                      Customers Annually Pay On Average Between
                    </p>
                    <h2 className="ins_p3">
                      {discoverDetails?.minimum_customer_pay &&
                        formatCurrency2(
                          discoverDetails?.minimum_customer_pay
                        )}{" "}
                      - &nbsp;
                      {discoverDetails?.maximum_customer_pay &&
                        formatCurrency2(discoverDetails?.maximum_customer_pay)}
                    </h2>
                  </div>
                  <div className="progs_cards mt-3 mb-4">
                    <div className="mt-3">
                      <div className="bar_progline">
                        <div className="right-dot"></div>
                        <div className="center-dot">
                          <span></span>
                        </div>
                        <div className="left-dot"></div>
                      </div>
                    </div>
                    <div className="prgs_brs_ul mt-4">
                      <ul>
                        <li>
                          {discoverDetails?.minimum_customer_pay &&
                            "$" +
                              formatCurrency(
                                discoverDetails?.minimum_customer_pay
                              )}{" "}
                          {!discoverDetails?.minimum_customer_pay && "N/A"}
                        </li>
                        <li>
                          {discoverDetails?.average_customer_pay &&
                            "$" +
                              formatCurrency(
                                discoverDetails?.average_customer_pay
                              )}{" "}
                          {!discoverDetails?.average_customer_pay && "N/A"}
                        </li>
                        <li>
                          {" "}
                          {discoverDetails?.maximum_customer_pay &&
                            "$" +
                              formatCurrency(
                                discoverDetails?.maximum_customer_pay
                              )}{" "}
                          {!discoverDetails?.maximum_customer_pay && "N/A"}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card_discovers fix_cards_wid p-0 mx-3">
                {!discoverDetails.name && (
                  <div className="card_loader">
                    <svg viewBox="25 25 50 50" className="svgsss">
                      <circle r="20" cy="50" cx="50"></circle>
                    </svg>
                  </div>
                )}
                <div className="head_ctrs">
                  <h4>Active Contracts</h4>
                  {/* <h5>View Spend & Utilization</h5> */}
                </div>
                <div className="comp_dis_slc mt-4 border-bottom pb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-map"
                  >
                    <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                    <line x1="8" y1="2" x2="8" y2="18"></line>
                    <line x1="16" y1="6" x2="16" y2="22"></line>
                  </svg>
                  <p className="mb-3">
                    You don't have any existing contracts with{" "}
                    {discoverDetails?.name}
                  </p>
                  <button onClick={() => setshowModal(true)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-plus-circle"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="16"></line>
                      <line x1="8" y1="12" x2="16" y2="12"></line>
                    </svg>
                    Request {discoverDetails?.name} Access
                  </button>
                </div>
                <div className="suplrsdetails">
                  <h6>Suppliers Details</h6>
                  <p>
                    {discoverDetails?.short_description}{" "}
                    {!discoverDetails?.short_description && "N/A"}
                  </p>
                  <div className="row">
                    <div className="col-6 detl_iins_dis">
                      <p>Employees</p>
                      <b>
                        {discoverDetails?.total_employees}{" "}
                        {!discoverDetails?.total_employees && "N/A"}
                      </b>
                    </div>
                    {discoverDetails?.estimate_annual_revenue != 0 && (
                      <div className="col-6 detl_iins_dis">
                        <p>Estimated Annual Revenue</p>
                        <b>
                          {discoverDetails?.estimate_annual_revenue &&
                            formatCurrency(
                              Number(discoverDetails?.estimate_annual_revenue)
                            )}
                        </b>
                      </div>
                    )}
                    <div className="col-6 mt-2 detl_iins_dis">
                      <p>Fiscal Year End</p>
                      <b>
                        {discoverDetails?.fiscal_year_end}{" "}
                        {!discoverDetails?.fiscal_year_end && "N/A"}
                      </b>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12 d-flex">
                      <button
                        className="gotowebsitebtn"
                        onClick={() => {
                          window.open(
                            discoverDetails?.company_website_url,
                            "_blank"
                          );
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-link"
                        >
                          <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                          <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                        </svg>
                        &nbsp; {discoverDetails?.company_website_url}{" "}
                        {!discoverDetails?.company_website_url && "N/A"}
                      </button>
                      <p className="ins_addrs mx-2 my-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-map-pin"
                        >
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                          <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                        &nbsp; {discoverDetails?.company_location}{" "}
                        {!discoverDetails?.company_location && "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}
        {showModal && (
          <>
            <div className="modal  fade show">
              <div className="modal-dialog  modal-xl modal-dialog-centered">
                <div className="modal-content max-heig-modls border-none p-3">
                  <div className="container">
                    <div className="row">
                      <div className="col-6 pt-1">
                        <h6 className="mb-0 retemodal-head">Create Request</h6>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={() => {
                            setshowModal(false);
                          }}
                          style={{ marginTop: "0px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="row pt-3">
                      <div className="col-12">
                        <form className="request_create_form">
                          {questionList.map((item, key) => (
                            <div className="mt-2" key={key}>
                              <label>{item.question}</label>
                              <input
                                type={item.type}
                                onChange={(e) => {
                                  item.answer = e.target.value;
                                }}
                              ></input>
                            </div>
                          ))}

                          <button
                            type="button"
                            onClick={onSubmit}
                            className="btn_sumit_forms"
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DiscoverDetails;
