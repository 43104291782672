import React, { useState, useEffect, useContext } from "react";
import ApexCharts from "react-apexcharts";
import AuthContext from "../AuthContext";
import { BASE_URL } from "../config";
import axios from "axios";
import { BeatLoader } from "react-spinners";
const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};
const ReusableChart = ({ categoryName, color }) => {
  const [documentCount, setDocumentCount] = useState(0);
  const { userToken } = useContext(AuthContext);
  const { userRole } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchDocumentCount = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/${userRole}/files/getDocumentCountForCategoryThisMonth`,
          {
            categoryName: categoryName,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.status === 1) {
          setDocumentCount(response.data.data.documentCount);
        }
      } catch (error) {
        console.error("Error fetching document count:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentCount();
  }, [categoryName]);

  const options = {
    series: [
      {
        name: `${categoryName} Documents`,
        data: [documentCount],
      },
    ],
    // colors: [color],
    chart: {
      height: 150,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: "30px",
      },
    },
    // dataLabels: {
    //   enabled: true,
    // },
    stroke: {
      width: 0,
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: [`${categoryName}`],
      labels: {
        rotate: -45,
      },
      tickPlacement: "on",
    },
    // fill: {
    //   colors: [color],
    // },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "light",
    //     type: "horizontal",
    //     shadeIntensity: 0.25,
    //     gradientToColors: undefined,
    //     inverseColors: true,
    //     opacityFrom: 0.85,
    //     opacityTo: 0.85,
    //     stops: [50, 0, 100],
    //   },
    // },
  };

  return (
    <>
      {loading && (
        <BeatLoader
          color={"#000"}
          loading={loading}
          cssOverride={override}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
      {!loading && (
        <ApexCharts
          options={options}
          series={options.series}
          type="bar"
          height={150}
        />
      )}
    </>
  );
};

export default ReusableChart;
