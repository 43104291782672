import React from 'react'
import logo from '../img/logo.svg'
import '../styles/Terms.css'

export default function TermsAndConditions() {
  return (
    <div
      className="terms-container"
      style={{ marginLeft: '-400px', width: '1240px' }}
    >
      <img src={logo} alt="modCounsel Logo" className="logo" />
      <h1>TERMS AND CONDITIONS</h1>
      <p>
        Please read this Terms of Service agreement (the “Agreement”) carefully
        before using the Services. This Agreement governs the provision of
        Services by mCIO Next, Inc. (“modInsight”) to you (the "Customer" or
        “you”) and Customer’s use of the Services.
      </p>

      <p>
        YOU INDICATE YOUR ACCEPTANCE OF THIS AGREEMENT AND ALL OF THE TERMS AND
        CONDITIONS HEREUNDER BY ACCESSING OR USING ANY MODINSIGHT SOLUTIONS OR
        DATA. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, YOU MAY NOT
        ACCESS OR USE ANY MODINSIGHT SERVICES, DATA, OR MODINSIGHT INTELLECTUAL
        PROPERTY. YOU AGREE THAT THIS AGREEMENT IS ENFORCEABLE LIKE ANY WRITTEN
        AGREEMENT SIGNED BY YOU.
      </p>

      <p>
        IF YOU ARE USING ANY MODINSIGHT SERVICES AS AN EMPLOYEE, CONTRACTOR, OR
        AGENT OF A CORPORATION, PARTNERSHIP OR SIMILAR ENTITY, THEN YOU MUST BE
        AUTHORIZED TO SIGN FOR AND BIND SUCH ENTITY IN ORDER TO ACCEPT THE TERMS
        OF THIS AGREEMENT, AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE
        AUTHORITY TO DO SO. THE RIGHTS GRANTED UNDER THIS AGREEMENT ARE
        EXPRESSLY CONDITIONED UPON ACCEPTANCE BY SUCH AUTHORIZED PERSONNEL.
      </p>

      <h2>Definitions</h2>
      <ol>
        <li>
          "Documentation" shall mean any user manuals, technical specifications,
          and other materials provided by modInsight relating to the Services
          and solely to the extent published and made available at{' '}
          <a href="https://app.modinsight.ai">https://app.modinsight.ai</a> or a
          related web property dedicated to the support materials of the
          foregoing services offering by modInsight.
        </li>
        <li>
          “Effective Date” shall mean the date which is the earlier of (a)
          Customer’s initial access to any modInsight Service through any online
          provisioning, registration or order process or (b) the effective date
          of the first Order Form referencing this Agreement.
        </li>
        <li>
          “Order Form” shall mean the document issued by modInsight to Customer
          stating the materials, services and quantities provided for a
          specified price.
        </li>
        <li>
          "Services" shall mean the service solution provided by modInsight to
          Customer.
        </li>
      </ol>

      <h2>2. Services</h2>
      <p>
        <strong>2.1 Provision of Services:</strong> modInsight agrees to provide
        the Services to Customer during the Term of this Agreement. The Services
        shall be provided in accordance with the terms and conditions set forth
        in this Agreement and the Documentation.
      </p>

      <p>
        <strong>2.2 Access and Use:</strong> Subject to the terms and conditions
        of this Agreement, modInsight grants Customer a limited, non-exclusive,
        non-transferable right to access and use the Services for its internal
        business operations during the Term. Customer shall be responsible for
        any acts or omissions by its affiliates or contractors provided with
        access to the Services.
      </p>

      <p>
        <strong>2.3 Sensitive Data:</strong> Customer agrees that it will not
        provide modInsight access to sensitive data, regulated data including
        personal or trade sensitive information, or any information that has a
        heightened degree of protection by applicable law (“Sensitive Data”). If
        Customer discovers that due to human error or otherwise, customer data
        does include Sensitive Data, Customer will immediately notify modInsight
        and modInsight will promptly delete the Sensitive Data in its control or
        possession.
      </p>

      <p>
        <strong>2.4 Restrictions:</strong> Customer will not modify, translate,
        reverse engineer, or copy the Services or related documentation in any
        manner; (b) reverse assemble, decompile, reverse engineer, translate or
        otherwise attempt to derive or obtain the source code, the underlying
        ideas, algorithms, structure or organization of the Services or
        otherwise seek to obtain the non-public APIs to the Service; (c) copy or
        duplicate the Services; (d) use the Services for the benefit of any
        third party or to provide substantially similar services to the
        Services; (e) publish any benchmark testing results on the Services
        without modInsight’s written consent; (f) remove or obscure any
        proprietary markings or notices contained in the Service or (g) access
        or use the Services other than for the stated purpose and under no
        circumstances for a purpose designed to circumvent, facilitate, or
        contribute to the breach of any contract or violation of law.
      </p>

      <h2>3. Terms and Conditions</h2>
      <p>
        <strong>3.1 Term:</strong> Either party may terminate this Agreement
        upon written notice. The Agreement may also terminate upon the
        expiration of any applicable Subscription Term, unless expressly stated
        otherwise therein or in this Agreement.
      </p>

      <p>
        <strong>3.2 Termination for Cause:</strong> Either Party may terminate
        for cause in the event of a material breach by the other Party, provided
        that the non-breaching Party provides written notice of the breach, and
        the breaching Party fails to cure such breach within 30 days of
        receiving written notice.
      </p>

      <p>
        <strong>3.3 Effect of Termination:</strong> Upon termination, Customer's
        right to access the Services shall immediately cease.
      </p>

      <h2>4. Confidentiality</h2>
      <p>
        Each party will protect the confidentiality of the other party’s
        Confidential Information using the same degree of care that it uses for
        its own confidential information of the other party (the “Disclosing
        Party”) for any purpose outside the scope of this Agreement; and (b)
        except as otherwise authorized by the Disclosing Party in writing, limit
        access to Confidential Information of the Disclosing Party to those of
        its and its Affiliates’ employees and contractors who need that access
        for purposes consistent with this Agreement and who are bound by
        obligations of confidentiality to the Receiving Party containing
        protections not materially less protective of the Confidential
        Information than those herein. If Receiving Party is required by law or
        court order to disclose Confidential Information, then Receiving Party
        shall, to the extent legally permitted, provide Disclosing Party with
        advance written notice and cooperate in any effort to obtain
        confidential treatment of the Confidential Information. The Receiving
        Party acknowledges that disclosure of Confidential Information would
        cause substantial harm for which damages alone would not be a sufficient
        remedy, and therefore that upon any such disclosure by the Receiving
        Party, the Disclosing Party will be entitled to seek appropriate
        equitable relief in addition to whatever other remedies it might have at
        law.
      </p>

      <h2>5. Intellectual Property</h2>
      <p>
        5.1 Ownership. Customer acknowledges and agrees that all intellectual
        property rights in the Services and Documentation, including any
        modifications or enhancements, are and shall remain the exclusive
        property of modInsight.
      </p>
      <p>
        {' '}
        5.2 Customer Data. Customer shall retain all right, title, and interest
        in and to any data or information uploaded to the Services ("Customer
        Data"). Customer grants modInsight a limited, non-exclusive, worldwide
        license to use, host, backup, transmit, and display the Customer Data as
        reasonably necessary to provide, operate, maintain, enhance, and update
        the Services and to perform its obligations under this Agreement.
        Notwithstanding the foregoing, You agree that modInsight has the right
        to: (a) access and use Customer Data for the foregoing purpose and to
        provide statistical insights and analysis related to usage of the
        Services; and (b) anonymize and aggregate Your Data (such anonymized and
        aggregated data, “Aggregate Data”) to prepare reports, studies,
        analyses, and other work product resulting from such Aggregate Data,
        provided that under no circumstances will modInsight distribute or
        otherwise make available to any third party any data in violation of
        Section 4 above.
      </p>

      <h2>6. Payment Agreement</h2>
      <p>
        <strong>6.1 Subscription Fee:</strong> Customer shall pay modInsight the
        stated Subscription Fee starting at $20 to over $500 depending on the
        actual Customer usage details or as set forth in Order Form. The
        Subscription Fee shall be non-cancelable, non-refundable and payable in
        advance.
      </p>

      <p>
        <strong>6.2 Taxes:</strong> Customer shall be responsible for all
        applicable taxes, duties, or other governmental charges arising out of
        or related to this Agreement, excluding taxes based on modInsight's net
        income.
      </p>

      <h2>7. Warranty and Disclaimer</h2>
      <p>
        <strong>7.1 Service Warranty:</strong> modInsight warrants that: (a) the
        Services will operate in substantial conformity with the applicable
        Documentation; and (b) Services and deliverables as set out in an Order
        Form will be provided in a professional and workmanlike manner.
      </p>

      <p>
        <strong>7.2. Mutual Warranty:</strong> Each party warrants that it has
        validly entered into this Agreement and has the legal power to do so.
      </p>
      <p>
        <strong>7.3 Warranty Disclaimer:</strong> EXCEPT AS EXPRESSLY SET FORTH
        IN THIS AGREEMENT, SERVICES AND CORRESPONDING DATA ARE PROVIDED “AS IS,”
        AND MODINSIGHT MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, STATUTORY
        OR OTHERWISE, INCLUDING BUT NOT LIMITED TO, WARRANTIES OF
        MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, OR
        NON-INFRINGEMENT. MODINSIGHT DOES NOT WARRANT THAT THE USE OF THE
        SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, NOR DOES MODINSIGHT
        WARRANT THAT IT WILL REVIEW THE CUSTOMER DATA FOR ACCURACY.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        N NO EVENT SHALL EITHER PARTY BE LIABLE FOR ANY DIRECT, INCIDENTAL,
        SPECIAL OR CONSEQUENTIAL DAMAGES OR DAMAGES FOR LOSS OF PROFITS,
        REVENUE, DATA OR INTERRUPTION OF BUSINESS INCURRED BY THE OTHER PARTY OR
        ANY THIRD PARTY WHETHER IN ACTION IN CONTRACT OR TORT, ARISING FROM
        CUSTOMER’S ACCESS TO, OR USE OF THE SERVICES OR ANY CONTENT PROVIDED
        THROUGH THE SERVICES. EXCEPT FOR A BREACH OF SECTION 2.4, SECTION 5, OR
        SECTION 6, EACH PARTY’S SOLE AND EXCLUSIVE LIABILITY UNDER THIS
        AGREEMENT SHALL LIMITED TO NO MORE THAN THE SUBSCRIPTION FEES ACTUALLY
        PAID TO MODINSIGHT FOR THE PRECEDING TWELVE (12) MONTHS OR $500,
        WHICHEVER IS LOWER.
      </p>

      <h2>9. Customer Reference</h2>
      <p>
        modInsight may identify Customer as its customer to other modInsight
        customers or prospective customers. Without limiting the foregoing,
        modInsight may use and display Customer’s name, logo, trademarks, and
        service marks on modInsight’s website and in modInsight’s marketing
        materials identifying Customer as a customer of modInsight. Upon
        Customer’s written request, modInsight will promptly remove any such
        marks from modInsight’s website and marketing materials.
      </p>

      <h2>10. Assignment</h2>
      <p>
        Neither Party may assign or transfer its rights or obligations under
        this Agreement, in whole or in part, without the prior written consent
        of the other Party, except that either Party may assign this Agreement
        without consent to a successor-in-interest in connection with a merger,
        acquisition, or sale of all or substantially all of its assets.
      </p>

      <h2>11. Survival</h2>
      <p>
        The following sections will survive any expiration or termination of
        this Agreement: Section 1 (Definitions), Section 2.4 (Restrictions on
        Use), Section 3 (Term and Termination), Section 4 (Confidentiality),
        Section 5 (Intellectual Property), Section 6 Payment Agreement), Section
        7.3 (Warranty Disclaimer), Section 8 (Limitation of Liability), and
        Sections 11 through 13.
      </p>

      <h2>12. Governing Law</h2>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of the State of Delaware, without regard to its conflict of laws
        principles.
      </p>

      <h2>13. Miscellaneous</h2>
      <p>
        <strong>13.1 Entire Agreement:</strong>This Agreement shall be governed
        by and construed in accordance with the laws of the State of Delaware,
        without regard to its conflict of laws principles.
      </p>

      <p>
        <strong>13.2 Amendments:</strong> From time to time, modInsight may
        modify this Agreement. Unless otherwise specified by modInsight, changes
        become effective for Customer upon renewal of the then-current Term or
        upon the effective date of a new Order Form after the updated version of
        this Agreement goes into effect. Customer may be required to click to
        accept or otherwise agree to the modified Agreement before renewing a
        Term or upon the effective date of a new Order Form, and in any event
        continued use of any Service after the updated version of this Agreement
        goes into effect will constitute Customer’s acceptance of such updated
        version.
      </p>
      <p>
        <strong>13.3 Severabilityt:</strong>If any provision of this Agreement
        is held to be invalid, illegal, or unenforceable, the remaining
        provisions shall remain in full force and effect.
      </p>

      <p>
        <strong>13.4 No Waiver:</strong> He failure of either Party to enforce
        any provision of this Agreement shall not be deemed a waiver of that
        provision or any other provision of this Agreement. Any waiver of a
        provision of this Agreement shall only be effective if it is in writing
        and signed by the Party granting the waiver.
      </p>
    </div>
  )
}
