// import React, { useState, useContext } from 'react'
// import axios from 'axios'
// import '../../styles/PasswordSettings.css'
// import AuthContext from '../../AuthContext.js'
// import { BASE_URL } from '../../config.js'

// export default function PasswordSettings() {
//   const { userRole, userToken } = useContext(AuthContext)
//   const [oldPassword, setOldPassword] = useState('')
//   const [newPassword, setNewPassword] = useState('')
//   const [confirmPassword, setConfirmPassword] = useState('')
//   const [errorMessage, setErrorMessage] = useState('')
//   const [successMessage, setSuccessMessage] = useState('')

//   const handlePasswordChange = async (e) => {
//     e.preventDefault()

//     if (newPassword !== confirmPassword) {
//       setErrorMessage('New password and confirm password do not match')
//       return
//     }

//     try {
//       const response = await axios.post(
//         `${BASE_URL}/${userRole}/change-password`,
//         {
//           oldPassword: oldPassword,
//           newPassword: newPassword,
//           confirmPassword: confirmPassword,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${userToken}`,
//             'Content-Type': 'application/json',
//           },
//         }
//       )

//       if (response.data.status === 1) {
//         setSuccessMessage('Password changed successfully!')
//         setOldPassword('')
//         setNewPassword('')
//         setConfirmPassword('')
//         setErrorMessage('')
//       } else {
//         setErrorMessage(response.data.message || 'Failed to change password')
//       }
//     } catch (error) {
//       console.error('Error changing password:', error)
//       setErrorMessage(
//         'An error occurred while changing the password. Please try again.'
//       )
//     }
//   }

//   return (
//     <div className="password-settings">
//       <h2>Change Password</h2>
//       {errorMessage && <p className="error-message">{errorMessage}</p>}
//       {successMessage && <p className="success-message">{successMessage}</p>}
//       <form onSubmit={handlePasswordChange}>
//         <label>
//           Old Password:
//           <input
//             type="password"
//             value={oldPassword}
//             onChange={(e) => setOldPassword(e.target.value)}
//             required
//           />
//         </label>
//         <label>
//           New Password:
//           <input
//             type="password"
//             value={newPassword}
//             onChange={(e) => setNewPassword(e.target.value)}
//             required
//           />
//         </label>
//         <label>
//           Confirm New Password:
//           <input
//             type="password"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//             required
//           />
//         </label>
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   )
// }

import React, { useState, useContext } from 'react'
import axios from 'axios'
import '../../styles/PasswordSettings.css'
import eyeOpen from '../../img/eye.png'
import eyeHide from '../../img/hidden.png'
import AuthContext from '../../AuthContext.js'
import { BASE_URL } from '../../config.js'

export default function PasswordSettings() {
  const { userRole, userToken } = useContext(AuthContext)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  //   const [logOutOtherDevices, setLogOutOtherDevices] = useState(true)

  const handlePasswordChange = async (e) => {
    e.preventDefault()

    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirm password do not match')
      return
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/change-password`,
        {
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        setSuccessMessage('Password changed successfully!')
        setOldPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setErrorMessage('')
      } else {
        setErrorMessage(response.data.message || 'Failed to change password')
      }
    } catch (error) {
      console.error('Error changing password:', error)
      setErrorMessage(
        'An error occurred while changing the password. Please try again.'
      )
    }
  }

  return (
    <div className="password-settings">
      <h2 className='mt-0'>Change Password</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <form onSubmit={handlePasswordChange} className='pb-2' >
        <label>
          Old Password
          <div className="password-field">
            <input
            className='mb-2'
              type={showOldPassword ? 'text' : 'password'}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
            <span
              className="password-toggle"
              onClick={() => setShowOldPassword(!showOldPassword)}
            >
              {showOldPassword ? (
                <img src={eyeOpen} alt="eyeOpen Icon" />
              ) : (
                <img src={eyeHide} alt="eyeHide Icon" />
              )}
            </span>
          </div>
        </label>

        <label>
          New Password
          <div className="password-field">
            <input
            className='mb-2'
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <span
              className="password-toggle"
              onClick={() => setShowNewPassword(!showNewPassword)}
            >
              {showNewPassword ? (
                <img src={eyeOpen} alt="eyeOpen Icon" />
              ) : (
                <img src={eyeHide} alt="eyeHide Icon" />
              )}
            </span>
          </div>
        </label>

        <label>
          Repeat New Password
          <div className="password-field">
            <input
            className='mb-2'
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <span
              className="password-toggle"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <img src={eyeOpen} alt="eyeOpen Icon" />
              ) : (
                <img src={eyeHide} alt="eyeHide Icon" />
              )}
            </span>
          </div>
        </label>
        <div className="button-container">
          {/* <button
            type="button"
            className="cancel-button"
            onClick={() => window.history.back()}
          >
            Cancel
          </button> */}
          <button
            type="submit"
            className="submit-button"
            disabled={!oldPassword || !newPassword || !confirmPassword}
          >
            Change Password
          </button>
        </div>
      </form>
    </div>
  )
}
