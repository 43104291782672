import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import AuthContext from "./AuthContext";
import { BASE_URL } from "./config";
import "./styles/SimpleDocumentViewer.css";
import dayjs from "dayjs";

import "@react-pdf-viewer/core/lib/styles/index.css";
import useDebounce from "./components/useDebounce";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import Mammoth from "mammoth";
import { renderAsync } from "docx-preview";
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;

export default function SimpleDocumentView({ fileId, handleBack }) {
  const { userToken, userRole } = useContext(AuthContext);
  const [documentUrl, setDocumentUrl] = useState("");
  const [notes, setNotes] = useState("");
  const [error, setError] = useState("");
  const [companyDetails, setcompanyDetails] = useState("");
  const [note_list, setnote_list] = useState([]);
  const [NotesMSG, setNotesMSG] = useState(false);
  const debouncedQuery = useDebounce(notes, 500);
  const currentSearch = localStorage.getItem("currentSearch");

  useEffect(() => {
    if (debouncedQuery) {
      handleSaveNotes();
    }
  }, [debouncedQuery]);
  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const notesUrl = `${BASE_URL}/${userRole}/file/view/${fileId}`;

        const response = await axios.get(notesUrl, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200 && response.data?.notes) {
          setNotes(response.data.notes);
        }
      } catch (error) {
        console.error("Error fetching notes:", error);
        setError("Failed to fetch the notes");
      }
    };

    fetchDocument();
    fetchNotes();
  }, [fileId, userToken, userRole]);
  const fetchDocument = async () => {
    try {
      const documentUrl =
        userRole === "user"
          ? `${BASE_URL}/user/file/view/${fileId}`
          : `${BASE_URL}/global/file/view/${fileId}`;

      const response = await axios.get(documentUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setnote_list(response.data.note_list);
        setDocumentUrl(response.data.url);
        setcompanyDetails(response.data);
        if (response.data.url.includes("docx")) {
          if (!currentSearch) {
            localStorage.setItem("currentSearch", "deepak");
          }
          handleFileUpload(response.data.url);
        } else {
          if (currentSearch == "deepak") {
            localStorage.removeItem("currentSearch");
          }
        }
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      setError("Failed to fetch the document");
    }
  };
  const handleSaveNotes = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/notes`,
        {
          file_id: fileId,
          notes: notes, // Current state of notes
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === 1) {
        setNotes("");
        setNotesMSG(true);
        setTimeout(() => {
          setNotesMSG(false);
        }, 2000);
        fetchDocument();
      } else {
        alert("Failed to save notes");
      }
    } catch (error) {
      console.error("Error saving notes:", error);
      setError("Failed to save notes");
    }
  };

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const highlightKeyword = (text, keyword) => {
    if (!keyword) return text;
    const parts = text.split(new RegExp(`(${keyword})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === keyword.toLowerCase()
        ? `<span style="background-color: yellow;margin-left:-7px;padding: 0.00em;color:black">${text}</span>`
        : part
    );
  };

  const [docxContent, setDocxContent] = useState("");
  const predefinedKeywords = [currentSearch]; // Add keywords to highlight

  const containerRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const handleFileUpload = async (demourl) => {
    const response = await fetch(demourl);
    if (!response.ok) throw new Error("Failed to fetch file");

    const arrayBuffer = await response.arrayBuffer();

    const result = await Mammoth.convertToHtml({ arrayBuffer });
    setDocxContent(result.value);
    setLoading(true);
    if (containerRef.current) {
      await renderAsync(arrayBuffer, containerRef.current); // Render DOCX content
      setLoading(false);
    }
  };

  const highlightKeywords = () => {
    if (!predefinedKeywords || predefinedKeywords.length === 0) return;

    const elements = containerRef.current?.querySelectorAll("p, span");
    const regex = new RegExp(`(${predefinedKeywords.join("|")})`, "gi");

    elements.forEach((element) => {
      element.innerHTML = element.innerHTML.replace(
        regex,
        (match) => `<span class="highlight">${match}</span>`
      );
    });
  };
  useEffect(() => {
    if (!loading) {
      highlightKeywords();
    }
  }, [loading]);
  return (
    <div className="simple-document-view">

     <div className="d-flex">
     <button className="go-back2 mx-4 mt-4" onClick={handleBack}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-arrow-left"
        >
          <line x1="19" y1="12" x2="5" y2="12"></line>
          <polyline points="12 19 5 12 12 5"></polyline>
        </svg>{" "}
        &nbsp; Back to Documents
      </button>
     </div>

 
      <div className="document-container pt-4">
        <div className="document-viewer pt-0">
          {documentUrl && (
            <div
              style={{
                height: "100vh",
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {documentUrl.includes("pdf") && (
                <Document
                  file={documentUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      customTextRenderer={(textItem) =>
                        highlightKeyword(textItem.str, currentSearch)
                      }
                    />
                  ))}
                </Document>
              )}
              {documentUrl.includes("docx") && (
                <div >
                  <div
                    ref={containerRef}
                    style={{
                    
                      padding: "0",
                      height: "80vh",
                      overflow: "auto",
                      fontFamily: "'Times New Roman', serif",
                      whiteSpace: "pre-wrap",
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="notes-section pt-2">
          <table className="tabl_dis_nts mt-0">
            <tr>
              <td>Document Name</td>
              <td>{companyDetails?.file?.name}</td>
            </tr>
            <tr>
              <td>Category</td>
              <td>{companyDetails?.file?.category_name}</td>
            </tr>
            <tr>
              <td>Submit Date</td>
              <td>
                {dayjs(companyDetails?.file?.submitted_date).format(
                  "DD MMM YYYY"
                )}
              </td>
            </tr>
            <tr>
              <td>Risk Score (%)</td>
              <td>
                {companyDetails?.file?.preview_risk_score &&
                  (companyDetails?.file?.preview_risk_score * 100).toFixed(2) +
                    "%"}
                {!companyDetails?.file?.preview_risk_score && "N/A"}
              </td>
            </tr>
            <tr>
              <td>Owned By</td>
              <td>
                {companyDetails?.user?.fname} {companyDetails?.user?.lname}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <button
                  className="go-back m-0"
                  onClick={() => {
                    window.open(documentUrl, "_blank");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-download"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>{" "}
                  &nbsp; Download Document
                </button>
              </td>
            </tr>
          </table>

          <h3 className="notsheading mt-0">Notes:</h3>
          <textarea
            className="notes-textarea"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Write your notes here..."
          ></textarea>
          {NotesMSG && (
            <p
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "-10px",
                color: "green",
              }}
            >
              saved
            </p>
          )}

          {note_list.length > 0 && (
            <ul style={{ listStyleType: "none", marginLeft: "-30px" }}>
              {note_list.map((item, key) => (
                <li key={key}>
                  <div className="inititab">
                    <span>{item.added_by[0]}</span>
                    <div className="mx-2">
                      <p>{item.added_by}</p>
                      <b>{item.note}</b>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
