import React, { useState, useEffect, useContext, useRef } from 'react'
import AuthContext from '../AuthContext'
import { BASE_URL } from '../config'
import { BeatLoader } from 'react-spinners'
import axios from 'axios'
import '../styles/ReadLine.css'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import slake from './../img/Slack-logo.png'

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#000',
  justifyContent: 'center',
}

const Request = () => {
  const [showModal, setshowModal] = useState(false)
  const [showModal2, setshowModal2] = useState(false)
  const fileInputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const { userToken, userEmail } = useContext(AuthContext)
  const [PopupLoader, setPopupLoader] = useState(true)
  const [isTableShow, setisTableShow] = useState(true)
  const [discoverList, setdiscoverList] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTerm2, setSearchTerm2] = useState('')
  const [UserList, setUserList] = useState([])
  const [UserID, setUserID] = useState('')
  const [RequestID, setRequestID] = useState('')
  const [teamMembers, setTeamMembers] = useState([])
  const [chatMembers, setChatMembers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([]) // Filtered user list
  const [filteredUsers2, setFilteredUsers2] = useState([]) // Filtered user list
  const [document_path, setdocument_path] = useState('')
  const [DocumentList, setDocumentList] = useState([])
  const [selectedFile, setSelectedFile] = useState('')
  const [ApproveList, setApproveList] = useState([])
  const [user_id, setuser_id] = useState('')
  const [reason, setreason] = useState('')
  useEffect(() => {
    if (searchTerm) {
      const filtered = teamMembers.filter((user) =>
        `${user.fname} ${user.lname} ${user.email}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
      console.log(filtered)
      setFilteredUsers(filtered)
    } else {
      setFilteredUsers([]) // Reset the filtered list if search is empty
    }
  }, [searchTerm])
  useEffect(() => {
    if (searchTerm2) {
      const filtered = teamMembers.filter((user) =>
        `${user.fname} ${user.lname} ${user.email}`
          .toLowerCase()
          .includes(searchTerm2.toLowerCase())
      )
      console.log(filtered)
      setFilteredUsers2(filtered)
    } else {
      setFilteredUsers2([]) // Reset the filtered list if search is empty
    }
  }, [searchTerm2])
  useEffect(() => {
    discoverRequestList()
  }, [])

  const discoverRequestList = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/discover/request-list`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        setdiscoverList(response.data.data.list)
      }
    } catch (error) {
      console.error('Error saving notes:', error)
    } finally {
      setPopupLoader(false)
    }
  }
  const getDocumentList = async (request_id) => {
    fetchTeamMembers()
    try {
      const response = await axios.post(
        `${BASE_URL}/user/request/document-list`,
        { request_id: request_id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        console.log(response.data)
        setDocumentList(response.data.data.document_list)
        setdocument_path(response.data.data.document_path)
      }
    } catch (error) {
      console.error('Error saving notes:', error)
    } finally {
      setPopupLoader(false)
    }
  }
  const getUserList = async (request_id) => {
    fetchTeamMembers()
    try {
      const response = await axios.post(
        `${BASE_URL}/user/request/user-list`,
        { request_id: request_id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        console.log(response.data)
        setUserList(response.data.data.document_users)
      }
    } catch (error) {
      console.error('Error saving notes:', error)
    } finally {
      setPopupLoader(false)
    }
  }
  const getApproveList = async (request_id) => {
    fetchTeamMembers()
    try {
      const response = await axios.post(
        `${BASE_URL}/user/request/approval-list`,
        { request_id: request_id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        console.log(response.data)
        setApproveList(response.data.data.list)
      }
    } catch (error) {
      console.error('Error saving notes:', error)
    } finally {
      setPopupLoader(false)
    }
  }
  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/team-members`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      })
      const { status, data } = response.data

      if (status === 1) {
        const filteredData = data.filter((member) => member.email !== userEmail)
        setTeamMembers(filteredData)
      } else {
        console.error('Error fetching team members:', response.data.message)
      }
    } catch (error) {
      console.error('Error fetching team members:', error)
    }
  }
  const addUser = async (user_id) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/request/add-user`,
        {
          user_id: user_id,
          request_id: RequestID,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        toast.success(response.data.message)
        setSearchTerm('')
        getUserList(RequestID)
      } else {
        console.error('Error fetching team members:', response.data.message)
        toast.error(response.data.message)
      }
    } catch (error) {
      console.error('Error fetching team members:', error)
    }
  }
  const removeUser = async (user_id) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/request/remove-user`,
        {
          user_id: user_id,
          request_id: RequestID,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        toast.success(response.data.message)
        setSearchTerm('')
        getUserList(RequestID)
      } else {
        console.error('Error fetching team members:', response.data.message)
        toast.error(response.data.message)
      }
    } catch (error) {
      console.error('Error fetching team members:', error)
    }
  }
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    setTimeout(() => {
      event.target.value = ''
    }, 2000)
  }
  const handleUpload = async () => {
    if (selectedFile) {
      try {
        var filename = ''
        var original_filename = ''
        const formData = new FormData()
        formData.append('file', selectedFile)
        const uploadResponse = await axios.post(
          `${BASE_URL}/global/upload-file`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        if (uploadResponse.data.status) {
          filename = uploadResponse.data.filename
          original_filename = uploadResponse.data.original_filename
          const addDocument = await axios.post(
            `${BASE_URL}/user/request/add-document`,

            {
              request_id: RequestID,
              filename: filename,
              original_filename: original_filename,
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
                'Content-Type': 'application/json',
              },
            }
          )
          if (addDocument.data.status == 1) {
            toast.success(addDocument.data.message)
            getDocumentList(RequestID)
            setshowModal(false)
            setSelectedFile('')
          }
        }
      } catch (error) {
        toast.error('An error occurred during file upload')
      } finally {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }
  const handleAddApproval = () => {
    if (user_id && reason) {
      setLoading(true)
      axios
        .post(
          `${BASE_URL}/user/request/add-approval`,
          {
            request_id: RequestID,
            user_id: user_id,
            reason: reason,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          if (response.data.status === 1) {
            toast.success(response.data.message)
            getApproveList(RequestID)
            setshowModal2(false)
            setuser_id('')
            setreason('')
            setSearchTerm2('')
          } else {
            toast.error(response.data.message)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      toast.error('Please select user and enter reason')
    }
  }
  const handleApproval = (approval_request_id, action) => {
    setLoading(true)
    axios
      .post(
        `${BASE_URL}/user/request/approve-approval-request`,
        {
          approval_request_id: approval_request_id,
          action: action,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response.data.status === 1) {
          toast.success(response.data.message)
          getApproveList(RequestID)
        } else {
          toast.error(response.data.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <div
      className="readline-container"
      style={{
        display: 'flex',
        flexDirection: isTableShow ? 'column' : 'row',
        alignItems: isTableShow && 'center',
      }}
    >
      {!isTableShow && (
        <div className="chat-window">
          <div className="chat-header">
            <button
              className="go-back2  mt-4"
              onClick={() => {
                setisTableShow(!isTableShow)
              }}
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>&nbsp;
              Back
            </button>
            <h3 className='mt-3'>Chat with Admin about Document: </h3>
            <span>Version:</span>
          </div>
          <div className="chat-actions">
            <button className="readline-btn-down mx-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-message-square"
              >
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
              </svg>
              Chat
            </button>
            <button className="readline-btn-select mx-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-edit-2"
              >
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
              </svg>
              Audit
            </button>
          </div>
          <div className="chat-messages">
            <p>No audit logs available.</p>

            <p>
              <strong>{' Message: '}</strong>
            </p>

            <p>msg</p>
          </div>

          <div className="chat-input">
            <input type="text" placeholder="Enter your message" />
            <button>Send</button>
          </div>
        </div>
      )}

      {/* File list display */}
      <div className="files-list" style={{ width: isTableShow && '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h5 className="title w-700 mt-2 mb-0">Document</h5>
          <button
            className="btn_upld_head"
            onClick={() => setshowModal(true)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>&nbsp;
            Upload Document
          </button>
        </div>

        {!isTableShow && (
          <div>
            <table className="task_tabl">
              <thead>
                <tr style={{ cursor: 'pointer' }}>
                  <th>File Name</th>
                  <th>Created AT</th>
                  <th className='text-right'>Actions</th>
                </tr>
              </thead>

              <tbody>
                {DocumentList.map((item, key) => (
                  <tr key={key}>
                    <td> {item.original_filename}</td>
                    <td>{dayjs(item.created_at).format('MMM DD, YYYY')}</td>
                    <td className='text-right'>
                      <div className="d-flex">
                        <button
                          className="readline-btn-down  ms-auto"
                          onClick={() => {
                            window.open(document_path + '/' + item.filename)
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-download"
                          >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="7 10 12 15 17 10"></polyline>
                            <line x1="12" y1="15" x2="12" y2="3"></line>
                          </svg>
                          Download
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Approvals Section */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h5 className="cards_headings">Approvals</h5>
              <button
                className="readline-btn-down mx-1"
                onClick={() => setshowModal2(true)}
              >
                Add Approval
              </button>
            </div>

            <table className="task_tabl">
              <thead>
                <tr style={{ cursor: 'pointer' }}>
                  <th>Name</th>
                  <th>Reason</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {ApproveList.length == 0 && (
                  <tr>
                    <td colSpan={5}>
                      <BeatLoader
                        color={'#000'}
                        loading={PopupLoader}
                        cssOverride={override}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                      {!PopupLoader && <p>No records found.</p>}
                    </td>
                  </tr>
                )}
                {ApproveList.map((item, key) => (
                  <tr key={key}>
                    <td>
                      {item.request_user.fname} {item.request_user.lname}
                    </td>
                    <td>{item.reason}</td>
                    <td>{item.status}</td>
                    <td>
                      {item.show_button && (
                        <div className="d-flex align-items-center">
                          <button
                            className="readline-btn-down"
                            onClick={() => {
                              handleApproval(
                                item.approval_request_id,
                                'approve'
                              )
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-check"
                            >
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            Approve
                          </button>
                          <button
                            className="readline-btn-select"
                            onClick={() => {
                              handleApproval(item.approval_request_id, 'reject')
                            }}
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Search Input */}
            <h5 className="cards_headings">Add Users to the Chat</h5>
            <div className="chat-input">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search by name or email"
              />
            </div>
            {/* Show Users Section only if a search term is entered */}

            <>
              <table className="task_tabl">
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {searchTerm && filteredUsers.length > 0 ? (
                    filteredUsers.map((user, index) => {
                      // Check if the user is already in the chat
                      const isMember = chatMembers.some(
                        (member) => member._id === user._id
                      )
                      return (
                        <tr>
                          <td>
                            {user.fname} {user.lname}
                          </td>
                          <td>{user.email}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <button
                                className="readline-btn-down"
                                onClick={() => {
                                  addUser(user._id)
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-plus"
                                >
                                  <line x1="12" y1="5" x2="12" y2="19"></line>
                                  <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <></>
                  )}

                  {UserList.length == 0 && (
                    <tr>
                      <td colSpan="3">No users found.</td>
                    </tr>
                  )}
                  {UserList.map((item, key) => (
                    <tr key={key}>
                      <td>
                        {item.fname} {item.lname}
                      </td>
                      <td>{item.email}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <button
                            onClick={() => {
                              removeUser(item.user_uid)
                            }}
                            className="readline-btn-select"
                          >
                            Remove
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          </div>
        )}

        {isTableShow && (
          <table className="task_tabl">
            <thead>
              <tr>
                <th>Name</th>
                <th>Purchase Request</th>
                <th>Added date</th>
                <th>Chat</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="redtabletd">
              {discoverList.length == 0 && (
                <tr>
                  <td colSpan={5}>
                    <BeatLoader
                      color={'#000'}
                      loading={PopupLoader}
                      cssOverride={override}
                      size={10}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                    {!PopupLoader && <p>No records found.</p>}
                  </td>
                </tr>
              )}
              {discoverList.map((item, key) => (
                <tr key={key}>
                  <td>{item.name}</td>
                  <td>{item.purchase_request}</td>
                  <td>
                    {dayjs(item.added_date).format('MMM DD, YYYY | hh:mm a')}
                  </td>

                  <td>
                    <button
                      onClick={() => {
                        setisTableShow(!isTableShow)
                        getDocumentList(item.request_id)
                        setRequestID(item.request_id)
                        getUserList(item.request_id)
                        getApproveList(item.request_id)
                      }}
                      className="readline-btn"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                    </button>
                  </td>
                  <td>
                    <img style={{ height: 40 }} src={slake} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {/* Modal */}
      {showModal && (
        <>
          <div className="modal  fade show " style={{ zIndex: 1 }}>
            <div className="modal-dialog  modal-xl modal-dialog-centered">
              <div className="modal-content border-none p-3">
                <div className="modal-body p-0">
                  <div className="container px-3 py-2">
                    <div className="row">
                      <div className="col-8">
                        <h2 className="modal-headings pt-1 m-0">
                          Upload Document
                        </h2>
                      </div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={() => setshowModal(false)}
                          style={{ marginTop: '0px' }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <section className="file_upload_section my-3">
                          <div className="upload-drag">
                            <label htmlFor="selectfiless">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="44"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6b7280"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-upload"
                              >
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="17 8 12 3 7 8"></polyline>
                                <line x1="12" y1="3" x2="12" y2="15"></line>
                              </svg>
                            </label>
                            <p className="upload-text font-13 w-700 mb-0 mt-3">
                              {selectedFile
                                ? selectedFile.name
                                : 'Choose file to upload or drag and drop'}
                            </p>
                            <span className="upload-limit font-11">
                              Only *.xls, *.xlsx, *.csv, *.ods, *.pdf & 30 MB
                              limit
                            </span>
                            <span className="upload-limit font-12 mt-2 ">
                              Or
                            </span>
                          </div>
                          <div>
                            <div className="row px-4 mt-2">
                              <div className="col-12 px-1">
                                <label
                                  className="lblselct"
                                  htmlFor="selectfiless"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-upload-cloud"
                                  >
                                    <polyline points="16 16 12 12 8 16"></polyline>
                                    <line
                                      x1="12"
                                      y1="12"
                                      x2="12"
                                      y2="21"
                                    ></line>
                                    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                                    <polyline points="16 16 12 12 8 16"></polyline>
                                  </svg>
                                  Upload
                                  <input
                                    id="selectfiless"
                                    type="file"
                                    multiple
                                    className="file-input"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 text-center">
                        {!loading && (
                          <button
                            className="btn_uplo_doc mx-auto my-0"
                            aria-label="Upload Documents"
                            onClick={() => {
                              setLoading(true)
                              handleUpload()
                            }}
                          >
                            Upload Documents
                          </button>
                        )}
                        {loading && (
                          <button
                            className="btn_uplo_doc mx-auto my-0"
                            aria-label="Upload Documents"
                          >
                            <BeatLoader
                              color={'#fff'}
                              loading={loading}
                              cssOverride={override}
                              size={10}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Modal */}
      {showModal2 && (
        <>
          <div className="modal  fade show " style={{ zIndex: 1 }}>
            <div className="modal-dialog  modal-xl modal-dialog-centered">
              <div className="modal-content border-none p-3">
                <div className="modal-body p-0">
                  <div className="container px-3 py-2">
                    <div className="row">
                      <div className="col-8">
                        <h2 className="modal-headings pt-1 m-0">
                          Add Approval
                        </h2>
                      </div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={() => setshowModal2(false)}
                          style={{ marginTop: '0px' }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="chat-input mt-3">
                          <input
                            type="text"
                            value={searchTerm2}
                            onChange={(e) => setSearchTerm2(e.target.value)}
                            placeholder="Search by name or email"
                          />
                        </div>
                        {filteredUsers2.length > 0 && (
                          <div
                            className="newsearchlist2 "
                            style={{ zIndex: '10' }}
                          >
                            <div>
                              <div className="px-3">
                                <ul className="smartpromptbtn">
                                  {filteredUsers2.length > 0 ? (
                                    filteredUsers2.map((user, index) => {
                                      // Check if the user is already in the chat
                                      const isMember = chatMembers.some(
                                        (member) => member._id === user._id
                                      )
                                      return (
                                        <li
                                          style={{ cursor: 'pointer' }}
                                          key={index}
                                          onClick={() => {
                                            setSearchTerm2(
                                              user.fname + ' ' + user.lname
                                            )
                                            setuser_id(user._id)
                                            setTimeout(() => {
                                              setFilteredUsers2([])
                                            }, 1000)
                                          }}
                                        >
                                          {user.fname} {user.lname}
                                        </li>
                                      )
                                    })
                                  ) : (
                                    <li style={{ cursor: 'pointer' }}></li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="chat-input mt-3 mb-3">
                          <input
                            type="text"
                            onChange={(e) => {
                              setreason(e.target.value)
                            }}
                            placeholder="Please enter reason"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        {!loading && (
                          <button
                            className="btn_uplo_doc mx-auto my-0"
                            aria-label="Upload Documents"
                            onClick={() => {
                              handleAddApproval()
                            }}
                          >
                            Add
                          </button>
                        )}
                        {loading && (
                          <button
                            className="btn_uplo_doc mx-auto my-0"
                            aria-label="Upload Documents"
                          >
                            <BeatLoader
                              color={'#fff'}
                              loading={loading}
                              cssOverride={override}
                              size={10}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Request
