import React, { useState } from 'react'
import SignIn from './SignIn'
import SignUp from './SignUp'

export default function Auth() {
  const [isSignIn, setIsSignIn] = useState(true)

  return (
    <div>
      {isSignIn ? <SignIn /> : <SignUp />}

     <button className='button-signup'  onClick={() => setIsSignIn(!isSignIn)}>
         {isSignIn ? (
            <span>
              Don't have an account? <b>Sign Up</b>
            </span>
          ) : (
            <span>
              Already have an account? <b>Sign In</b>
            </span>
          )}
     </button>

    
    </div>
  )
}
