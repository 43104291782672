import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import AuthContext from '../AuthContext'
import { BASE_URL } from '../config'
import '../styles/Home.css'

export default function Home() {
  const [files, setFiles] = useState([])
  const [error, setError] = useState('')
  const { userToken } = useContext(AuthContext)
  const { userRole } = useContext(AuthContext)


  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/${userRole}/file/list`,
          {
            page: '1',
            size: '10',
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )

        console.log('API Response:', response)

        if (response.data.status === 1) {
          setFiles(response.data.data)
        } else {
          setError(response.data.message)
        }
      } catch (error) {
        console.error('Error fetching files:', error)
        setError('Failed to fetch files')
      }
    }

    fetchFiles()
  }, [userToken, userRole])

  const getStepClassName = (fileQueue, step) => {
    const steps = {
      initialReview: [
        'qa-analyst',
        'qa',
        'qa-admin',
        'back-client',
        'admin-stage',
      ],
      qualityAnalysis: ['qa', 'qa-admin', 'back-client', 'admin-stage'],
      finalStages: ['qa-admin', 'back-client', 'admin-stage'],
      completed: ['back-client'],
    }

    if (steps[step]?.includes(fileQueue)) {
      return 'step-progress green'
    }

    return 'step-progress'
  }

  return (
    <div className="home">
      <div className="section">
        <div className="section-head">In Queue</div>
        <table className="file-table">
          <thead>
            <tr>
              <th>FILE NAME</th>
              <th>SUBMIT DATE</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file) => (
              <tr key={file._id}>
                <td>{file.filename}</td>
                <td>{new Date(file.added_date).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="section">
        <div className="section-head">Under Review</div>
        <table className="file-table">
          <thead>
            <tr>
              <th>FILE NAME</th>
              <th>SUBMIT DATE</th>
              <th>Step 1: INITIAL REVIEW</th>
              <th>Step 2: QUALITY ANALYSIS</th>
              <th>Step 3: FINAL STAGES</th>
              <th>Step 4: COMPLETED</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file) => (
              <tr key={file._id}>
                <td>{file.filename}</td>
                <td>{new Date(file.added_date).toLocaleString()}</td>
                <td>
                  <div
                    className={getStepClassName(
                      file.file_queue,
                      'initialReview'
                    )}
                  ></div>
                </td>
                <td>
                  <div
                    className={getStepClassName(
                      file.file_queue,
                      'qualityAnalysis'
                    )}
                  ></div>
                </td>
                <td>
                  <div
                    className={getStepClassName(file.file_queue, 'finalStages')}
                  ></div>
                </td>
                <td>
                  <div
                    className={getStepClassName(file.file_queue, 'completed')}
                  ></div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  )
}
