import React from "react";
import ApexCharts from "react-apexcharts";

import "../styles/main.css";

const SemiCircleChart = () => {
  const options = {
    series: [76],
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: -10,
          size: "50%",
        },
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            show: true,
            fontSize: "22px",
            formatter: function (val) {
              return `${val}%`;
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: [""],
    colors: ["#00E396"],
    stroke: {
      lineCap: "round",
    },
  };

  return (
    <div className="chart-wrapper">
      <div>
        <ApexCharts
          options={options}
          type="radialBar"
          series={options.series}
          height={150}
        />
      </div>
    </div>
  );
};

export default SemiCircleChart;
