import React, { useState } from "react";
import "../styles/SendDocument.css";
const EnterPassword = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  return (
    <div className="wrapper">
      <div className="content-area px-4 pt-3">
          <div className="enterpaeewordwrap">
            <div>
                <h6 className="mb-2"><b>Please Enter Password</b></h6>
                <input type="password" className="inp-paas"></input>
                <button className="go-back mx-auto mt-3 w-100">Submit</button>
            </div>
          </div>
      </div>
    </div>
  );
};

export default EnterPassword;
