import React, { useState, useEffect, useContext } from "react";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import AuthContext from "../AuthContext";
import { BASE_URL } from "../config";
import "../styles/main.css";
import { BeatLoader } from "react-spinners";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};
const ChartThree = () => {
  const [riskScores, setRiskScores] = useState([]);
  const { userToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchRiskScores = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/files/getUserRiskScores`,
          {},
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === 1) {
          setRiskScores(
            response.data.data.map((score) => ({
              documentName: score.documentName,
              riskScore: (score.riskScore * 100).toFixed(2),
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching risk scores:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRiskScores();
  }, [userToken]);

  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#F1C40F",
    "#8E44AD",
    "#2ECC71",
    "#E74C3C",
  ];

  const options = {
    annotations: {
      points: [
        {
          x: "Bananas",
          seriesIndex: 0,
          label: {
            borderColor: "#775DD0",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#775DD0",
            },
            text: "Bananas are good",
          },
        },
      ],
    },
    series: [
      {
        name: "Risk Score",
        data: riskScores.map((score) => parseFloat(score.riskScore)),
      },
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        // horizontal: false,
        // distributed: true,
        borderRadius: 8,
        columnWidth: "30px",
      },
    },
    stroke: {
      width: 0,
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    stroke: {
      width: 0,
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: riskScores.map((score) => score.documentName),
      labels: {
        rotate: -45,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 10, // Y-axis increments in steps of 10
      labels: {
        formatter: (val) => `${val}%`, // Format the y-axis as percentages
      },
    },
    title: {
      text: "Risk Scores per Document",
      align: "left",
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "light",
    //     type: "horizontal",
    //     shadeIntensity: 0.25,
    //     gradientToColors: undefined,
    //     inverseColors: true,
    //     opacityFrom: 0.85,
    //     opacityTo: 0.85,
    //     stops: [50, 0, 100],
    //   },
    // },
    // colors: colors.slice(0, riskScores.length),
  };

  // Check if there's any data to display
  const hasData = riskScores.length > 0;

  return (
    <div className="chart-wrapper">
      <h2 className="chart-title">Risk Scores per Document</h2>
      {loading && (
        <BeatLoader
          color={"#000"}
          loading={loading}
          cssOverride={override}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
      {!loading && (
        <div id="chart3" className="chart-container">
          {!hasData && (
            <div className="overlay">
              <p className="overlay-text">No data available</p>
            </div>
          )}
          <div className={hasData ? "" : "blur-chart"}>
            <ApexCharts
              options={options}
              series={options.series}
              type="bar"
              height={350}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartThree;
