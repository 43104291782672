import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import logosl from "../img/slack.png";
import axios from "axios";
import { BASE_URL } from "../config";
import AuthContext from "../AuthContext";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};
const Discover = () => {
  const [query, setQuery] = useState("");
  const [searchData, setsearchData] = useState([]);
  const navigate = useNavigate();
  const [PopupLoader, setPopupLoader] = useState(false);
  const { userToken } = useContext(AuthContext);
  const [discoverList, setdiscoverList] = useState([]);
  useEffect(() => {
    if (!query) {
      setsearchData([]);
    }
  }, [query]);

  useEffect(() => {
    const fetchDiscoverList = async () => {
      setPopupLoader(true);
      try {
        const response = await axios.get(
          `${BASE_URL}/user/discover/company-list`,

          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.status === 1) {
          setdiscoverList(response.data.data.list);
        } else {
          //   alert(response.data?.data?.message || "Failed to fetch categories");
        }
      } catch (error) {
        // alert("Failed to fetch categories");
      } finally {
        setPopupLoader(false);
      }
    };
    fetchDiscoverList();
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      console.log(query);
      fetchDiscoverList(query);
    }
  };
  useEffect(() => {
    fetchDiscoverList(query);
  }, [query]);

  const fetchDiscoverList = async (query) => {
    // setPopupLoader(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/user/discover/search-brand`,
        { company_name: query },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.status === 1) {
        console.log(response.data);
        setsearchData(response.data.data.list);
      } else {
        //   alert(response.data?.data?.message || "Failed to fetch categories");
      }
    } catch (error) {
      // alert("Failed to fetch categories");
    } finally {
      setPopupLoader(false);
    }
  };

  const fetchDiscoverDetails = async (query) => {
    console.log(query);
    setPopupLoader(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/user/discover/company-details-by-name`,
        { company_name: query },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.status === 1) {
        console.log(response.data);
        localStorage.setItem(
          "company_name",
          response.data.data.details.company_id
        );
        navigate("/discover-details");
        // setsearchData(response.data.data.list);
      } else {
        //   alert(response.data?.data?.message || "Failed to fetch categories");
      }
    } catch (error) {
      // alert("Failed to fetch categories");
    } finally {
      setPopupLoader(false);
    }
  };
  return (
    <>
      <div className="wrapper">
        <div className="content-area px-4 pt-3" style={{ marginLeft: "0px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <h3 className="discover_headings mt-3">Discover</h3>
                <p>Find new software supplier and products</p>
              </div>
              <div className="col-6 pb-1 pt-3">
                <label className="search_lbl_companies">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="search"
                    value={query}
                    onKeyUp={handleKeyPress}
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                    placeholder="Search companies"
                  ></input>

                  {query && searchData.length > 0 && (
                    <div className="dropdownlistcompanies">
                      <div className="container">
                        <div className="row">
                          {searchData.map((item, key) => (
                            <div
                              className="col-4 my-1 px-2"
                              key={key}
                              onClick={() => {
                                fetchDiscoverDetails(item.name);
                              }}
                            >
                              <div className="card_srched_conpnies">
                                <img src={item.icon}></img>
                                <div className="mx-2">
                                  <p>{item.name}</p>
                                  <p>{item.domain}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </label>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-12 pb-1">
                <h3 className="discover_headings2">Most Popular Suppliers</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <BeatLoader
                  color={"#000"}
                  loading={PopupLoader}
                  cssOverride={override}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
              {discoverList.map((item, key) => (
                <div
                  key={key}
                  className="col-3 mb-3"
                  onClick={() => {
                    localStorage.setItem("company_name", item.company_id);
                    navigate("/discover-details");
                  }}
                >
                  <div className="card_discovers">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="comnies_logos">
                        <img src={item.icon}></img>
                        <p>{item.name}</p>
                      </div>
                    </div>
                    <div className="compnies_despt">
                      <p>
                        {item.short_description.length > 75
                          ? item.short_description.slice(0, 75) + "..."
                          : item.short_description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Discover;
