import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { BASE_URL } from "../config";
import { BeatLoader } from "react-spinners";
import AuthContext from "../AuthContext";
import QaFetchContext from "../QaFetchContext";
import RiskChart from "./RiskChart";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { FaTimes } from "react-icons/fa";

const Risk = () => {
  const [openChart, setopenChart] = useState(false);
  const [fileDetailsList, setfileDetailsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showSubcategoryModal, setShowSubcategoryModal] = useState(false); // State for Subcategory modal
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false); // State for More Info modal
  const [subcategoryScores, setSubcategoryScores] = useState(null);
  const [subcategoryReasons, setSubcategoryReasons] = useState(null);
  const [moreInfoLoading, setMoreInfoLoading] = useState(false); // Loading state for more info modal
  const [moreInfoContent, setMoreInfoContent] = useState(null); // API response content for more info
  const { userToken } = useContext(AuthContext);
  const [isLoadingQAData, setIsLoadingQAData] = useState(false); // Loading state for QA data
  const { isFetchingQA, processingFiles } = useContext(QaFetchContext);
  const [showCitationModal, setShowCitationModal] = useState(false);
  const [citationContent, setCitationContent] = useState("");
  const [filepath, setfilepath] = useState("");

  useEffect(() => {
    fetchFilesDetails();
  }, [isFetchingQA, processingFiles]);  



  const fetchFilesDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/user/subcategory-scores`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data && response.data.status === 1) {
        setfileDetailsList(response.data.data);
        setfilepath(response.data.filepath);
      } else {
        console.error("Failed to fetch files");
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      setLoading(false);
    }
  };

  const getExistingFileQA = async (fileHash) => {
    if (isFetchingQA) {
      alert("Data is already being fetched at the moment. Please wait.");
      return;
    }
    setIsLoadingQAData(true); // Set loading state for QA data
    try {
      const response = await axios.post(
        `${BASE_URL}/user/existing-file-qa`,
        { file_hash: fileHash },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoadingQAData(false); // Remove loading state

      if (response.data.status === 1 && response.data.data) {
        return response.data.data;
      } else {
        console.error(
          "No QA data found for this file hash:",
          response.data.message
        );
        return null;
      }
    } catch (error) {
      setIsLoadingQAData(false); // Remove loading state
      alert(error?.response?.data?.message || "Failed to fetch data");
      console.error("Error fetching existing file QA:", error);
      return null;
    }
  };

  const extractReasonAndCitation = (reasonText) => {
    if (!reasonText) return { reasonWithoutCitation: "", citation: "" };

    // Replace underscores with quotation marks in the entire reasonText
    const formattedReasonText = reasonText.replace(/_/g, '"');

    // Split the formatted text into sentences
    const sentences = formattedReasonText.match(/[^.!?]+[.!?]?/g) || [
      formattedReasonText,
    ];

    let citationSentence = "";
    const otherSentences = [];

    sentences.forEach((sentence) => {
      if (sentence.includes("Citation:")) {
        citationSentence += sentence.trim();
      } else {
        otherSentences.push(sentence.trim());
      }
    });

    const reasonWithoutCitation = otherSentences.join(" ");

    return { reasonWithoutCitation, citation: citationSentence };
  };

  const handleCitationClick = (citation) => {
    if (citation) {
      setCitationContent(citation);
    } else {
      setCitationContent("No Citation available");
    }
    setShowCitationModal(true);
  };

  const handleCloseCitationModal = () => {
    setShowCitationModal(false);
    setCitationContent("");
  };

  const handleRiskClick = async (file) => {
    console.log("file: ", file);
    if (isFetchingQA) {
      alert("Data is already being fetched at the moment. Please wait.");
      return;
    }
    setSelectedFile(file);
    setShowSubcategoryModal(true); // Show modal with Loading message initially

    if (
      file.subcategory_scores &&
      Object.keys(file.subcategory_scores).length > 0
    ) {
      setSubcategoryScores(file.subcategory_scores);
      setSubcategoryReasons(file.subcategory_reasons);
    } else {
      // Show "Loading..." while fetching data
      setSubcategoryScores(null);
      setSubcategoryReasons(null);
      setIsLoadingQAData(true); // Set loading state for QA data

      // Call getExistingFileQA if no subcategory scores available
      const qaData = await getExistingFileQA(file.file_hash);

      if (qaData && qaData.subcategory_scores) {
        setSubcategoryScores(qaData.subcategory_scores);
        setSubcategoryReasons(qaData.subcategory_reasons);
      }

      setIsLoadingQAData(false); // Stop loading once data is fetched
    }
  };

  // const exportChartToExcel = () => {
  //   if (!subcategoryScores || Object.keys(subcategoryScores).length === 0) {
  //     alert('No data available to export.')
  //     return
  //   }

  //   const data = [
  //     ['Subcategory', 'AI Risk Score (%)'], // Header row
  //     ...Object.entries(subcategoryScores).map(([subcategory, score]) => [
  //       subcategory,
  //       (score * 100).toFixed(2),
  //     ]),
  //   ]

  //   const worksheet = XLSX.utils.aoa_to_sheet(data)
  //   const workbook = XLSX.utils.book_new()
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Risk Chart Data')

  //   XLSX.writeFile(workbook, `${selectedFile?.filename || 'ChartData'}.xlsx`)
  // }
  const exportChartToPDF = async () => {
    const chartElement = document.querySelector(".chart-container"); // Replace with your chart's container class or ID

    if (!chartElement) {
      alert("Chart element not found");
      return;
    }

    // Capture the chart as an image using html2canvas
    const canvas = await html2canvas(chartElement);
    const imgData = canvas.toDataURL("image/png");

    // Create a new PDF document
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [canvas.width, canvas.height],
    });

    // Add the image to the PDF
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

    // Save the PDF
    pdf.save("Chart.pdf");
  };

  const handleMoreInfoClick = async (file) => {
    setMoreInfoLoading(true); // Set loading state for more info modal
    setSelectedFile(file);
    setShowMoreInfoModal(true); // Open More Info modal
    try {
      const response = await axios.post(
        `${BASE_URL}/user/getAnswersAboutFile`,
        { file_id: file._id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.status === 1) {
        const formattedResponse = response.data.data
          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // bolds text between **
          .replace(/\n/g, "<br/>"); // convert new lines to HTML <br> tags
        setMoreInfoContent(formattedResponse); // Set formatted response data
      } else {
        setMoreInfoContent("Failed to fetch additional info.");
      }
    } catch (error) {
      console.error("Error fetching file info:", error);
      setMoreInfoContent("Error fetching additional info.");
    } finally {
      setMoreInfoLoading(false); // Remove loading state
    }
  };

  const handleCloseSubcategoryModal = () => {
    setShowSubcategoryModal(false);
    setSelectedFile(null);
    setSubcategoryScores(null);
  };

  const handleCloseMoreInfoModal = () => {
    setShowMoreInfoModal(false);
    setMoreInfoContent(null);
    setSelectedFile(null);
  };

  const handleDownloadPDF = async (pdfPath, filename) => {
    const response = await fetch(pdfPath);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container mt-5" style={{ padding: "0 2rem" }}>
      <div className="cards pt-3">
        <h5 className="cards_headings">Risk</h5>

        <table className="task_tabl  mt-3">
          <thead>
            <tr>
              <th>Filename</th>
              <th>Risk Score</th>
              <th>More Info</th>
              <th>Chart</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={4} className="text-center ">
                  <div className="bg-red py-2">
                    <BeatLoader color={"#000"} loading={loading} size={10} />
                  </div>
                </td>
              </tr>
            ) : fileDetailsList.length === 0 ? (
              <tr>
                <td colSpan="4" className="text-center">
                  No files found
                </td>
              </tr>
            ) : (
              fileDetailsList.map((file, index) => (
                <tr key={index}>
                  <td>{file.filename}</td>
                  <td>
                    <button
                      className="btn_td_tabs"
                      onClick={() => handleRiskClick(file)}
                    >
                      {file.risk_score || file.ai_risk_score
                        ? (file.risk_score || file.ai_risk_score * 100).toFixed(
                            2
                          ) + "%"
                        : "N/A"}
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn_td_tabs"
                      onClick={() => handleMoreInfoClick(file)}
                    >
                      More Info
                    </button>
                  </td>
                  <td>
                    <button
                      className="chart_icons_btns"
                      onClick={() => {
                        setSubcategoryScores("");
                        if (
                          file.subcategory_scores &&
                          Object.keys(file.subcategory_scores).length > 0
                        ) {
                          setSubcategoryScores(file.subcategory_scores);
                        }
                        setopenChart(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-bar-chart-2"
                      >
                        <line x1="18" y1="20" x2="18" y2="10"></line>
                        <line x1="12" y1="20" x2="12" y2="4"></line>
                        <line x1="6" y1="20" x2="6" y2="14"></line>
                      </svg>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Modal to show subcategory scores */}
      {showSubcategoryModal && selectedFile && (
        <div
          className="modal fade show"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div
              className="modal-content"
              style={{ minWidth: "65vw", maxWidth: "65vw" }}
            >
              <div
                className="modal-header"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h5 className="modal-title">
                    AI risk review for {selectedFile.filename}
                  </h5>
                  <div class="dropdown dots_drop_mdl">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-more-horizontal"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                      </svg>
                    </button>
                    <ul class="dropdown-menu can_mod_uls">
                      <li
                        onClick={() => {
                          handleDownloadPDF(
                            filepath + "/" + selectedFile.filename_encoded,
                            selectedFile.filename
                          );
                        }}
                      >
                        <a class="dropdown-item" href="javascript:;">
                          Fix it
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Send to DocuSign
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Send to Counsel
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseSubcategoryModal}
                >
                  <FaTimes />
                </button>
              </div>
              <div className="modal-body">
                {isLoadingQAData ? (
                  <div className="text-center">
                    <BeatLoader
                      color={"#000"}
                      loading={isLoadingQAData}
                      size={10}
                    />
                  </div>
                ) : subcategoryScores &&
                  Object.keys(subcategoryScores).length > 0 ? (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Contact Deal Point</th>
                        <th>AI Risk Score</th>
                        <th>This Contract Submission</th>
                        <th>Your Company Standard</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(subcategoryScores).map(
                        (subcategory, index) => {
                          const reasonText =
                            subcategoryReasons[subcategory]?.reason || "";
                          const { reasonWithoutCitation, citation } =
                            extractReasonAndCitation(reasonText);

                          return (
                            <tr key={index}>
                              <td>{subcategory}</td>
                              <td>
                                {(subcategoryScores[subcategory] * 100).toFixed(
                                  2
                                )}
                                %
                              </td>
                              <td>
                                {subcategoryScores[subcategory] == 0 ? (
                                  "Meets Company Standard"
                                ) : (
                                  <span>
                                    {reasonWithoutCitation}{" "}
                                    <span
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleCitationClick(citation)
                                      }
                                    >
                                      1
                                    </span>
                                  </span>
                                )}
                              </td>
                              <td>
                                {subcategoryScores[subcategory] == 0
                                  ? "Meets Company Standard"
                                  : subcategoryReasons[subcategory]?.target}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center">No subcategory scores found</div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseSubcategoryModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal to show more info about the file */}
      {showMoreInfoModal && (
        <div
          className="modal fade show"
          role="dialog"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-lg"
            role="document"
            style={{ minWidth: "90%" }}
          >
            <div className="modal-content max-width-1000perce">
              <div className="modal-header">
                <h5 className="modal-title">
                  More Info for {selectedFile?.filename}
                </h5>
              </div>
              <div className="modal-body">
                {moreInfoLoading ? (
                  <div className="text-center">
                    <BeatLoader
                      color={"#000"}
                      loading={moreInfoLoading}
                      size={10}
                    />
                  </div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        moreInfoContent ||
                        "No additional information available.",
                    }}
                  />
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseMoreInfoModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showSubcategoryModal || showMoreInfoModal ? (
        <div className="modal-backdrop fade show"></div>
      ) : null}

      {/* Citation Modal */}
      {/* Citation Modal */}
      {showCitationModal && (
        <div
          className="modal fade show d-flex align-items-center justify-content-center"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Citation</h5>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseCitationModal}
                >
                  <FaTimes />
                </button>
              </div>
              <div className="modal-body">
                <p>{citationContent}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Chart Modal */}
      {openChart && (
        <div
          className="modal fade show"
          role="dialog"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-xl"
            role="document"
            style={{ minWidth: "90%" }}
          >
            <div className="modal-content max-width-1000perce">
              <div className="d-flex align-items-end">
                <button type="button" onClick={exportChartToPDF}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-download"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                </button>
                <button
                  type="button"
                  className="modal_close_buttons"
                  onClick={() => setopenChart(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>

              {/* Bar chart area start */}
              <RiskChart subcategoryScores={subcategoryScores} />
              {/* Bar chart area end */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Risk;
