import React, { useState, useContext, useEffect, useRef } from 'react'
import axios from 'axios'
import AuthContext from '../AuthContext'
import Modal from 'react-modal'
import { BASE_URL } from '../config'
import '../styles/NewRequest.css'

export default function NewRequest() {
  const [selectedFile, setSelectedFile] = useState(null)
  const [files, setFiles] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [buyerSeller, setBuyerSeller] = useState('')
  const [error, setError] = useState('')
  const [discussionPoint, setDiscussionPoint] = useState('')
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')

  const fileInputRef = useRef(null)

  const { userToken } = useContext(AuthContext)

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}user/file/list`,
          {
            page: '1',
            size: '10',
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )

        console.log('API Response:', response)
      } catch (error) {
        console.error('Error fetching files:', error)
        // setError('Failed to fetch files')
      }
    }

    const fetchCategories = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/category/list`,
          {
            page: '0',
            size: '10',
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )

        console.log('Categories API Response:', response)

        if (response.data && response.data.status === 1) {
          setCategories(response.data.categories)
        } else {
          setError(response.data?.data?.message || 'Failed to fetch categories')
        }
      } catch (error) {
        console.error('Error fetching categories:', error)
        setError('Failed to fetch categories')
      }
    }

    fetchFiles()
    fetchCategories()
  }, [userToken])

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    if (event.target.files[0]) {
      setModalIsOpen(true)
    }
  }

  const handleCloseModal = () => {
    setSelectedFile(null)
    setBuyerSeller('')
    setSelectedCategory('')
    setDiscussionPoint('')
    setModalIsOpen(false)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleUpload = async () => {
    if (selectedFile && buyerSeller && selectedCategory) {
      try {
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('admin_id', 1) // Replace 'YOUR_ADMIN_ID' with the actual admin ID

        const uploadResponse = await axios.post(
          `${BASE_URL}/global/upload-file`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        console.log('First API Response:', uploadResponse)

        if (uploadResponse.data.status === 1) {
          const { filename, original_filename } = uploadResponse.data

          const secondResponse = await axios.post(
            `${BASE_URL}/user/upload-and-search`,
            {
              filename,
              original_filename,
              user_type: buyerSeller,
              qc_uid: selectedCategory,
              admin_id: '1234',
              discussion_point: discussionPoint,
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
                'Content-Type': 'application/json',
              },
            }
          )

          console.log('Second API Response:', secondResponse)

          if (secondResponse.data.status === 1) {
            const newFile = {
              filename: selectedFile.name,
              date: new Date().toLocaleDateString(),
              added_date: new Date().toLocaleString(),
              score: '',
              download: false,
              buyerSeller: buyerSeller,
              discussionPoint: discussionPoint,
            }
            const updatedFiles = [...files, newFile]
            setFiles(updatedFiles)
            setSelectedFile(null)
            setBuyerSeller('')
            setSelectedCategory('')
            setModalIsOpen(false)
            alert(secondResponse.data.message)
          } else {
            setError(
              secondResponse.data.message ||
                'An error occurred during the second upload step.'
            )
          }
        } else {
          setError(
            uploadResponse.data.message ||
              'An error occurred during the file upload.'
          )
        }
      } catch (error) {
        console.error('Error during file upload:', error)
        setError('An error occurred during file upload')
      }
    }
  }

  const handleCheckboxChange = (index) => {
    const newFiles = [...files]
    newFiles[index].download = !newFiles[index].download
    setFiles(newFiles)
  }

  return (
    <div className="new-request">
      <div className="file-upload">
        <div className="file-upload-container">
          <label className="file-upload-label">
            <input type="file" onChange={handleFileChange} ref={fileInputRef} />
            <span>Upload File</span>
          </label>
          {selectedFile && <p>FILE NAME: {selectedFile.name}</p>}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="File Details"
        className="Modal"
        overlayClassName="Overlay"
      >
        <button className="close-modal" onClick={handleCloseModal}>
          X
        </button>
        <h2>File Details</h2>
        {selectedFile && <p>FILE NAME: {selectedFile.name}</p>}
        <div className="form-group">
          <label>Buyer/Seller:</label>
          <select
            value={buyerSeller}
            onChange={(e) => setBuyerSeller(e.target.value)}
            required
          >
            <option value="">Select</option>
            <option value="buyer">Buyer</option>
            <option value="seller">Seller</option>
          </select>
        </div>
        <div className="form-group">
          <label>Category:</label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            required
          >
            <option value="">Select</option>
            {Array.isArray(categories) &&
              categories.map((category) => (
                <option key={category.qc_uid} value={category.qc_uid}>
                  {category.name}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group">
          <label>Discussion Point:</label>
          <input
            value={discussionPoint}
            onChange={(e) => setDiscussionPoint(e.target.value)}
            placeholder="Enter any points you want to discuss"
            rows="4"
            required
          ></input>
        </div>
        <button onClick={handleUpload}>Submit</button>
        {error && (
          <p className="error">
            {typeof error === 'string' ? error : 'An error occurred'}
          </p>
        )}
      </Modal>
      <table className="file-table">
        <thead>
          <tr>
            <th>FILE NAME</th>
            <th>SUBMIT DATE</th>
            <th>OVERALL RISK SCORE</th>
            <th>DOWNLOAD ANALYSIS</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={file._id}>
              <td>{file.filename}</td>
              <td>{new Date(file.added_date).toLocaleDateString()}</td>
              <td>{file.score}</td>
              <td>
                <input
                  type="checkbox"
                  checked={file.download}
                  onChange={() => handleCheckboxChange(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <div className="add-req-container">
        <button className="add-request-button">Add to Existing Request</button>
      </div> */}
    </div>
  )
}
