// Task.js

import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import AuthContext from "../AuthContext";
import QaFetchContext from "../QaFetchContext";
import { BASE_URL } from "../config";
import "../styles/main.css";
import ClipLoader from "react-spinners/ClipLoader";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import Header from "./Header";
import Pagination from "./Pagination";
import RiskChart from "./RiskChart"; // Ensure this path is correct
import { FaTimes } from "react-icons/fa";
import CompareApexChart from "./CompareApexChart";
import { Offcanvas } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import Mammoth from "mammoth";
import { renderAsync } from "docx-preview";
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};

const Task = () => {
  const containerRef = useRef(null);
  const currentSearch = localStorage.getItem("currentSearch");
  const [showLeft, setShowLeft] = useState(false);

  const handleCloseLeft = () => setShowLeft(false);
  const handleShowLeft = () => setShowLeft(true);
  const [documentUrl, setDocumentUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const handleFileUpload = async (demourl) => {
    const response = await fetch(demourl);
    if (!response.ok) throw new Error("Failed to fetch file");

    const arrayBuffer = await response.arrayBuffer();

    const result = await Mammoth.convertToHtml({ arrayBuffer });
    // setDocxContent(result.value);
    setLoading(true);
    if (containerRef.current) {
      await renderAsync(arrayBuffer, containerRef.current); // Render DOCX content
      setLoading(false);
    }
  };

  const highlightKeyword = (text, keyword) => {
    if (!keyword) return text;
    const parts = text.split(new RegExp(`(${keyword})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === keyword.toLowerCase()
        ? `<span style="background-color: yellow;margin-left:-7px;padding: 0.00em;color:black">${text}</span>`
        : part
    );
  };
  const [completedFiles, setCompletedFiles] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [expandedFileIds, setExpandedFileIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);

  const { userToken } = useContext(AuthContext);
  const { userRole } = useContext(AuthContext);
  const { isFetchingQA, processingFiles } = useContext(QaFetchContext);

  const [contractTerms, setContractTerms] = useState({});
  const [clickedFile, setClickedFile] = useState(null);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);

  const [showRiskModal, setShowRiskModal] = useState(false);
  const [subcategoryScores, setSubcategoryScores] = useState(null);
  const [subcategoryReasons, setSubcategoryReasons] = useState(null);
  const [isLoadingQAData, setIsLoadingQAData] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showCitationModal, setShowCitationModal] = useState(false);
  const [citationContent, setCitationContent] = useState("");
  const [moreInfoContent, setMoreInfoContent] = useState(null);
  const [moreInfoLoading, setMoreInfoLoading] = useState(false);

  const [Loader2, setLoader2] = useState(false);
  let [loading, setLoading] = useState(true);
  const [showModal2, setShowModal2] = useState(false);
  const handleShowModal2 = () => setShowModal2(true);

  const [is_password_required, setis_password_required] = useState("");
  const [url, seturl] = useState("");
  const [isURL, setisURL] = useState(false);
  const [password, setpassword] = useState("");
  const [link_id, setlink_id] = useState("");
  const [file_id, setfile_id] = useState("");

  const [summary, setsummary] = useState("");
  const [filepath, setfilepath] = useState("");

  const [selectedModalTab, setSelectedModalTab] = useState("subcategories");
  const [isChart, setisChart] = useState(true);

  const renderTabContent = () => {
    switch (selectedModalTab) {
      case "subcategories":
        return (
          <table className="task_tabl ">
            <thead>
              <tr>
                <th>Contact Deal Point</th>
                <th>AI Risk Score</th>
                <th>This Contract Submission</th>
                <th>Your Company Standard</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(subcategoryScores).map((subcategory, index) => {
                const reasonText =
                  subcategoryReasons[subcategory]?.reason || "";
                const { reasonWithoutCitation, citation } =
                  extractReasonAndCitation(reasonText);

                return (
                  <tr key={index}>
                    <td>{subcategory}</td>
                    <td>
                      {(subcategoryScores[subcategory] * 100).toFixed(2)}%
                    </td>
                    <td>
                      {subcategoryScores[subcategory] === 0 ? (
                        "Meets Company Standard"
                      ) : (
                        <span>
                          {reasonWithoutCitation}{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => handleCitationClick(citation)}
                          >
                            1
                          </span>
                        </span>
                      )}
                    </td>
                    <td>
                      {subcategoryScores[subcategory] === 0
                        ? "Meets Company Standard"
                        : subcategoryReasons[subcategory]?.target}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );

      case "riskChart":
        return (
          <>
            <div className="row">
              <div className="col-6 "></div>
              <div className="col-6">
                <div class="container_chx ">
                  <input
                    type="checkbox"
                    onClick={() => setisChart(!isChart)}
                    class="checkbox_chx"
                    id="checkbox"
                  />
                  <label class="switch_chx" for="checkbox">
                    <span class="slider_chx"></span>
                  </label>
                  <label for="checkbox" className="charts_nms">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-bar-chart-2"
                    >
                      <line x1="18" y1="20" x2="18" y2="10"></line>
                      <line x1="12" y1="20" x2="12" y2="4"></line>
                      <line x1="6" y1="20" x2="6" y2="14"></line>
                    </svg>
                  </label>
                  <label for="checkbox" className="files_nms">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-file"
                    >
                      <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                      <polyline points="13 2 13 9 20 9"></polyline>
                    </svg>
                  </label>
                </div>
              </div>
            </div>
            {isChart && <RiskChart subcategoryScores={subcategoryScores} />}
            {!isChart && (
              <CompareApexChart subcategoryScores={subcategoryScores} />
            )}
          </>
        );

      case "moreInfo":
        return (
          <div className="mt-3">
            {moreInfoLoading ? (
              <div className="text-center">
                <BeatLoader
                  color={"#000"}
                  loading={moreInfoLoading}
                  size={10}
                />
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    moreInfoContent || "No additional information available.",
                }}
              />
            )}
          </div>
        );

      case "riskSummary":
        // Sort the subcategory scores in descending order and take the top 5
        const top5RiskEntries = Object.entries(subcategoryScores)
          .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
          .slice(0, 5);

        return (
          <>
            <h2 className="cards_headings mt-3">Top 5 Risk Scores</h2>
            <table className="task_tabl ">
              <thead>
                <tr>
                  <th>Contract Deal Point</th>
                  <th>AI Risk Score</th>
                  <th>This Contract Submission</th>
                  <th>Your Company Standard</th>
                </tr>
              </thead>
              <tbody>
                {top5RiskEntries.map(([subcategory, score], index) => {
                  const reasonText =
                    subcategoryReasons[subcategory]?.reason || "";
                  const { reasonWithoutCitation, citation } =
                    extractReasonAndCitation(reasonText);

                  return (
                    <tr key={index}>
                      <td>{subcategory}</td>
                      <td>{(score * 100).toFixed(2)}%</td>
                      <td>
                        {score === 0 ? (
                          "Meets Company Standard"
                        ) : (
                          <span>
                            {reasonWithoutCitation}{" "}
                            <span
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => handleCitationClick(citation)}
                            >
                              1
                            </span>
                          </span>
                        )}
                      </td>
                      <td>
                        {score === 0
                          ? "Meets Company Standard"
                          : subcategoryReasons[subcategory]?.target}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        );
    }
  };

  useEffect(() => {
    fetchFiles(currentPage);
  }, [currentPage, processingFiles, isFetchingQA]);

  const generateSummary = async () => {
    if (!clickedFile) return;
    setIsGeneratingSummary(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/user/generateSummary`,
        { file_id: clickedFile._id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === 1) {
        const updatedSummary = response.data.data.summary;
        // Update the clickedFile's summary
        setClickedFile((prev) => ({
          ...prev,
          summary: updatedSummary,
        }));
      } else {
        console.error("Error generating summary:", response.data.message);
      }
    } catch (error) {
      console.error("Error generating summary:", error);
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const renderDropdown = (file) => {
    if (!file) {
      return null;
    }
    const terms = contractTerms[file._id];
    return (
      <>
        {expandedFileIds.includes(file._id) && (
          <tr>
            <td colSpan="9" className="padzero">
              <div className="dropdown-details">
                <h5 className="mx-1 mb-3 mt-3">File Details</h5>
                {terms ? (
                  <table className="mt-0">
                    <tbody>
                      <tr>
                        <td>File Name:</td>
                        <td>{file?.filename ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>ACV:</td>
                        <td>{terms.acv ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Payment Terms:</td>
                        <td>{terms.payment_terms ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Billing Frequency:</td>
                        <td>{terms.billing_frequency ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Contract Term:</td>
                        <td>{terms.contract_term ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Auto price increase:</td>
                        <td>{terms.auto_price_increase ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Price increase %:</td>
                        <td>{terms.price_increase_percent ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Contract start date:</td>
                        <td>
                          {terms.contract_start_date
                            ? new Date(
                                terms.contract_start_date
                              ).toLocaleDateString()
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td>Contract end date:</td>
                        <td>
                          {terms.contract_end_date
                            ? new Date(
                                terms.contract_end_date
                              ).toLocaleDateString()
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td>Contract end date (multi-year deal):</td>
                        <td>
                          {terms.contract_end_date_multi_year
                            ? new Date(
                                terms.contract_end_date_multi_year
                              ).toLocaleDateString()
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td>Auto-Renew:</td>
                        <td>{terms.auto_renew ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>On short order form:</td>
                        <td>{terms.on_short_order_form ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Price increase cap - Y/N:</td>
                        <td>{terms.price_increase_cap ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Price increase cap amount:</td>
                        <td>{terms.price_increase_cap_amount ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Security terms:</td>
                        <td>{terms.security_terms ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Customer signatory:</td>
                        <td>{terms.customer_signatory ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Main customer contact:</td>
                        <td>{terms.main_customer_contact ?? "N/A"}</td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  // Show loading indicator if terms not yet loaded
                  <BeatLoader
                    color={"#000"}
                    loading={true}
                    cssOverride={override}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </div>
            </td>
          </tr>
        )}
      </>
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchFiles(page);
  };

  const fetchFileDetailAnswers = async (fileId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/getFileDetailAnswers`,
        { file_id: fileId },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === 1) {
        setsummary(response.data.custom_data.summary);
        const updatedFileData = response.data.data;
        setContractTerms((prev) => ({
          ...prev,
          [fileId]: updatedFileData,
        }));
        return updatedFileData;
      } else {
        console.error(
          "Error fetching file detail answers:",
          response.data.message
        );
        return null;
      }
    } catch (err) {
      console.error("Error fetching file detail answers:", err);
      return null;
    }
  };

  const handleFileCheckboxChange = (file) => {
    setSelectedFiles((prevSelected) => {
      if (prevSelected.includes(file._id)) {
        return prevSelected.filter((id) => id !== file._id);
      } else {
        return [...prevSelected, file._id];
      }
    });
  };

  const handleMoveToReadLine = async () => {
    if (selectedFiles.length > 0) {
      try {
        // Loop through selected files and move each to readline
        for (let fileId of selectedFiles) {
          const response = await axios.post(
            `${BASE_URL}/user/file/moveToReadline`,
            {
              file_id: fileId, // Pass the file ID to the endpoint
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          if (response.data.status === 1) {
            toast.success(`File ${fileId} successfully moved to Readline.`);
          } else {
            toast.error(
              response.data.message ||
                `Failed to move file ${fileId} to Readline.`
            );
          }
        }
      } catch (error) {
        console.error("Error moving files to Readline:", error);
        toast.error(
          error?.response?.data?.message || "Failed to move files to Readline."
        );
      }
    } else {
      toast.warning("Please select at least one file to move.");
    }
  };

  useEffect(() => {
    setLoader2(true);

    const fetchCompletedFiles = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/file/list`,
          {
            page: "1",
            size: "10",
            file_queue: "back-client",
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === 1) {
          setCompletedFiles(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching completed files:", error);
        setError("Failed to fetch completed files");
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/category/list`,
          {
            page: "0",
            size: "10",
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data && response.data.status === 1) {
          setCategories(response.data.categories);
        } else {
          setError(
            response.data?.data?.message || "Failed to fetch categories"
          );
        }
      } catch (error) {
        setError("Failed to fetch categories");
      }
    };

    fetchFiles(currentPage);
    fetchCompletedFiles();
    fetchCategories();
    //eslint-disable-next-line
  }, [userToken, userRole]);

  const fetchFiles = async (current) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/file/list`,
        {
          page: current,
          size: "10",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === 1) {
        settotalPages(response.data.totalPages);
        setfilepath(response.data.filepath);
        const fileList = response.data.data;
        const sorted = fileList.sort((a, b) => b.added_date - a.added_date);

        fetchAllFileDetails(sorted);
      } else {
        setError(response.data.message);
        setLoader2(false);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
      setError("Failed to fetch files");
    }
  };

  const fetchAllFileDetails = async (fileList) => {
    const fileDetailsPromises = fileList.map(async (file) => {
      const details = await fetchFileDetails(file._id);
      return { ...file, ...details, isLoading: false };
    });
    const allDetails = await Promise.all(fileDetailsPromises);
    const sorted = allDetails.sort((a, b) => b.added_date - a.added_date);
    setFileDetails(sorted);
    setLoader2(false);
  };

  const fetchFileDetails = async (fileId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/global/file-details/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const {
          fileAnswerMode,
          categoryName,
          qc_uid,
          discuss_point,
          risk_score,
          full_name,
          submitted_for_analysis,
          file_hash,
        } = response.data.data;

        return {
          buyerSeller: fileAnswerMode,
          category_name: categoryName,
          discuss_point: discuss_point,
          qc_uid: qc_uid,
          risk_score: risk_score || null,
          full_name: full_name || "Unknown",
          submitted_for_analysis,
          file_hash,
        };
      }
    } catch (error) {
      console.error("Error fetching file details:", error);
      return {};
    }
  };

  // Function to fetch existing QA data for a file hash
  const getExistingFileQA = async (fileHash) => {
    if (isFetchingQA) {
      alert("Data is already being fetched at the moment. Please wait.");
      return;
    }

    setIsLoadingQAData(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/user/existing-file-qa`,
        { file_hash: fileHash },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoadingQAData(false);

      if (response.data.status === 1 && response.data.data) {
        return response.data.data;
      } else {
        console.error(
          "No QA data found for this file hash:",
          response.data.message
        );
        return null;
      }
    } catch (error) {
      setIsLoadingQAData(false);
      alert(error?.response?.data?.message || "Failed to fetch data");
      console.error("Error fetching existing file QA:", error);
      return null;
    }
  };

  const handleRiskClick = async (file) => {
    if (isFetchingQA) {
      alert("Data is already being fetched at the moment. Please wait.");
      return;
    }
    setSelectedFile(file);
    setShowRiskModal(true);

    if (
      file.subcategory_scores &&
      Object.keys(file.subcategory_scores).length > 0
    ) {
      setSubcategoryScores(file.subcategory_scores);
      setSubcategoryReasons(file.subcategory_reasons);
    } else {
      setSubcategoryScores(null);
      setSubcategoryReasons(null);
      setIsLoadingQAData(true);

      const qaData = await getExistingFileQA(file.file_hash);

      if (qaData && qaData.subcategory_scores) {
        setSubcategoryScores(qaData.subcategory_scores);
        setSubcategoryReasons(qaData.subcategory_reasons);
      }

      setIsLoadingQAData(false);
    }
  };

  const handleFileClick = async (file) => {
    setClickedFile(file);
    const terms = await fetchFileDetailAnswers(file._id);
    setClickedFile((prev) => ({ ...prev, terms }));
  };

  const handleDownload = async (file, e) => {
    e.stopPropagation();
    try {
      const response = await axios.get(
        `${BASE_URL}/user/download/${file._id}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleSubmitForAnalysis = async (file) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/submit-for-analysis`,
        {
          file_id: file._id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      alert(response.data.message);
    } catch (error) {
      console.error("Error submitting file for analysis:", error);
      setError("Failed to submit file for analysis");
    }
  };

  const toggleFileExpansion = (fileId) => {
    setExpandedFileIds((prev) =>
      prev.includes(fileId)
        ? prev.filter((id) => id !== fileId)
        : [...prev, fileId]
    );

    // Fetch contract terms if they haven't been fetched yet
    if (!contractTerms[fileId]) {
      fetchFileDetailAnswers(fileId);
    }
  };

  const handleFixIt = async (file) => {
    const response = await fetch(filepath + "/" + file.filename_encoded);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", file.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    console.log("processing", processingFiles);
  }, [processingFiles]);
  const handleSendToCounsel = async (file) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/file/move-to-counsel`,
        {
          file_id: file._id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === 1) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting file for analysis:", error);
      setError("Failed to submit file for analysis");
    }
    // You can integrate with an email service or update file status
  };

  const handleSendForSignatures = (file) => {
    // Implement the logic for "Send for Signatures"
    console.log(`Send for Signatures clicked for file: ${file.filename}`);
    // You can integrate with a signature service like DocuSign
  };

  const extractReasonAndCitation = (reasonText) => {
    if (!reasonText) return { reasonWithoutCitation: "", citation: "" };

    // Replace underscores with quotation marks in the entire reasonText
    const formattedReasonText = reasonText.replace(/_/g, '"');

    // Split the formatted text into sentences
    const sentences = formattedReasonText.match(/[^.!?]+[.!?]?/g) || [
      formattedReasonText,
    ];

    let citationSentence = "";
    const otherSentences = [];

    sentences.forEach((sentence) => {
      if (sentence.includes("Citation:")) {
        citationSentence += sentence.trim();
      } else {
        otherSentences.push(sentence.trim());
      }
    });

    const reasonWithoutCitation = otherSentences.join(" ");

    return { reasonWithoutCitation, citation: citationSentence };
  };

  const handleCitationClick = (citation) => {
    if (citation) {
      setCitationContent(citation);
    } else {
      setCitationContent("No Citation available");
    }
    setShowCitationModal(true);
  };

  const handleCloseCitationModal = () => {
    setShowCitationModal(false);
    setCitationContent("");
  };

  const handleMoreInfoClick = async (file) => {
    setMoreInfoLoading(true);
    setSelectedFile(file);
    try {
      const response = await axios.post(
        `${BASE_URL}/user/getAnswersAboutFile`,
        { file_id: file._id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.status === 1) {
        const formattedResponse = response.data.data
          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
          .replace(/\n/g, "<br/>");
        setMoreInfoContent(formattedResponse);
      } else {
        setMoreInfoContent("Failed to fetch additional info.");
      }
    } catch (error) {
      console.error("Error fetching file info:", error);
      setMoreInfoContent("Error fetching additional info.");
    } finally {
      setMoreInfoLoading(false);
    }
  };

  const handleCloseMoreInfoModal = () => {
    setMoreInfoContent(null);
    setSelectedFile(null);
  };

  const createLink = async () => {
    if (is_password_required === "yes" && password) {
      LinkAPI();
    } else if (is_password_required === "yes" && !password) {
      toast.error("Please enter password");
    } else {
      LinkAPI();
    }
  };

  const LinkAPI = async () => {
    if (isURL) {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/files/link/edit`,
          {
            link_id: link_id,
            is_password_required: is_password_required,
            password: password,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === 1) {
          toast.success(response.data.message);
          fetchFiles(currentPage);
        } else {
          console.error("Error editing link:", response.data.message);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error editing link:", error);
      }
    } else {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/files/link/create`,
          {
            file_id: file_id,
            is_password_required: is_password_required,
            password: password,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === 1) {
          seturl(response.data.data.lunk_url);
          fetchFiles(currentPage);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error creating link:", error);
      }
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="content-area px-4 pt-3" style={{ marginLeft: "0px" }}>
          <header className="header-flex">
            <div></div>
            <Header />
          </header>

          {/* Action Files Section */}
          <div>
            <div className="container-fluid">
              {processingFiles?.length >= 0 && (
                <div className="row ">
                  <div className="col-12">
                    <div className="cards p-4">
                      <h5 className="cards_headings">Processing Files</h5>
                      <table className="task_tabl doc_namess_th">
                        <thead>
                          <tr>
                            <th className="">Document name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {processingFiles.length === 0 && (
                            <tr>
                              <td>No processing files</td>
                            </tr>
                          )}
                          {processingFiles.map((file) => (
                            <tr key={file._id}>
                              <td>{file.filename}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-12">
                  {/* Offcanvas Sidebar */}
                  {/* Left Offcanvas */}
                  <Offcanvas
                    show={showLeft}
                    onHide={handleCloseLeft}
                    placement="start"
                    className="wid_of_canvas2"
                  >
                    <Offcanvas.Body className="body_canv">
                      <div className="wrap_offcanv">
                        <div className="container">
                          <div className="row pt-2">
                            <div className="col-12 d-flex align-items-center">
                              <button
                                className="close_btn_canvas"
                                onClick={handleCloseLeft}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-arrow-left"
                                >
                                  <line x1="19" y1="12" x2="5" y2="12"></line>
                                  <polyline points="12 19 5 12 12 5"></polyline>
                                </svg>
                              </button>
                            </div>
                            <div className="col-12">
                              {documentUrl && (
                                <div
                                  style={{
                                    height: "100vh",
                                    overflow: "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {documentUrl.includes("pdf") && (
                                    <Document
                                      file={documentUrl}
                                      onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                      {Array.from(
                                        new Array(numPages),
                                        (el, index) => (
                                          <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            customTextRenderer={(textItem) =>
                                              highlightKeyword(
                                                textItem.str,
                                                currentSearch
                                              )
                                            }
                                          />
                                        )
                                      )}
                                    </Document>
                                  )}
                                  {documentUrl.includes("docx") && (
                                    <div style={{ marginTop: "20px" }}>
                                      <div
                                        ref={containerRef}
                                        style={{
                                          border: "0 solid #ccc",
                                          padding: "10px",

                                          overflow: "auto",

                                          whiteSpace: "pre-wrap",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                  <div
                    class="offcanvas offcanvas-end wid_of_canvas"
                    tabindex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                  >
                    <div class="offcanvas-body body_canv">
                      <div className="wrap_offcanv">
                        <div className="container">
                          <div className="row pt-2">
                            <div className="col-6 d-flex align-items-center">
                              <button
                                className="close_btn_canvas"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-arrow-right"
                                >
                                  <line x1="5" y1="12" x2="19" y2="12"></line>
                                  <polyline points="12 5 19 12 12 19"></polyline>
                                </svg>
                              </button>
                              <button className="btn_canvs">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-maximize-2"
                                >
                                  <polyline points="15 3 21 3 21 9"></polyline>
                                  <polyline points="9 21 3 21 3 15"></polyline>
                                  <line x1="21" y1="3" x2="14" y2="10"></line>
                                  <line x1="3" y1="21" x2="10" y2="14"></line>
                                </svg>
                              </button>
                              <button className="btn_canvs">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-link"
                                >
                                  <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                  <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                                </svg>
                              </button>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                              <button className="btn_canvs trsh_btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </button>
                              <button
                                className="btn_canvs"
                                onClick={() => handleFixIt(clickedFile)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-download"
                                >
                                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                  <polyline points="7 10 12 15 17 10"></polyline>
                                  <line x1="12" y1="15" x2="12" y2="3"></line>
                                </svg>
                              </button>
                              <button className="btn_canvs">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-repeat"
                                >
                                  <polyline points="17 1 21 5 17 9"></polyline>
                                  <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                                  <polyline points="7 23 3 19 7 15"></polyline>
                                  <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
                                </svg>
                              </button>
                              <button
                                className="view_docments_btn"
                                onClick={() => {
                                  handleShowLeft();
                                  setDocumentUrl(
                                    filepath +
                                      "/" +
                                      clickedFile.filename_encoded
                                  );
                                  console.log(clickedFile);
                                  if (
                                    clickedFile.filename_encoded.includes(
                                      "docx"
                                    )
                                  ) {
                                    if (!currentSearch) {
                                      localStorage.setItem(
                                        "currentSearch",
                                        "deepak"
                                      );
                                    }
                                    handleFileUpload(
                                      filepath +
                                        "/" +
                                        clickedFile.filename_encoded
                                    );
                                  } else {
                                    if (currentSearch == "deepak") {
                                      localStorage.removeItem("currentSearch");
                                    }
                                  }
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-file-text"
                                >
                                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                  <polyline points="14 2 14 8 20 8"></polyline>
                                  <line x1="16" y1="13" x2="8" y2="13"></line>
                                  <line x1="16" y1="17" x2="8" y2="17"></line>
                                  <polyline points="10 9 9 9 8 9"></polyline>
                                </svg>
                                &nbsp; View document
                              </button>
                            </div>
                          </div>
                          <div className="row px-1">
                            <div className="col-12 px-3 pt-3">
                              <h3 className="heading_convss">
                                {" "}
                                {clickedFile ? clickedFile.filename : ""}
                              </h3>
                            </div>
                            <div className="col-12 px-3 pt-1">
                              <span className="datetim_canv">
                                {" "}
                                Created on{" "}
                                {clickedFile
                                  ? new Date(
                                      clickedFile.added_date
                                    ).toLocaleDateString()
                                  : ""}
                              </span>
                              <span className="exten_canv">
                                {clickedFile
                                  ? clickedFile.filename
                                      .split(".")
                                      .pop()
                                      .toUpperCase()
                                  : "N/A"}
                              </span>
                            </div>
                            <div className="col-12 d-flex px-3 pt-2">
                              <span className="complet_tags">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-check-circle"
                                >
                                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                                &nbsp; Complete
                              </span>
                            </div>

                            {/* Summary Section */}
                            <div className="col-12 d-flex px-3 pt-3">
                              {!summary && (
                                <div className="summerycarrd w-100">
                                  <h2>Summary</h2>
                                  {/* Check if summary exists */}
                                  {isGeneratingSummary ? (
                                    <div className="loader">
                                      <ClipLoader
                                        color={"#000"}
                                        loading={true}
                                        size={35}
                                      />
                                    </div>
                                  ) : clickedFile && clickedFile.summary ? (
                                    <p>{clickedFile.summary}</p>
                                  ) : (
                                    <button
                                      className="view_docments_btn mt-2"
                                      onClick={generateSummary}
                                    >
                                      <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="far"
                                        data-icon="sparkles"
                                        class="svg-inline--fa fa-sparkles styles__icon_emz6K"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 128l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 128l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 64 426.8 7.5C425.1 3 420.8 0 416 0s-9.1 3-10.8 7.5L384 64 327.5 85.2zM9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5L26.3 277l8.1 3.7 .6 .3 88.3 40.8L164.1 410l.3 .6 3.7 8.1 7.9 17.1c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l7.9-17.1 3.7-8.1 .3-.6 40.8-88.3L346 281l.6-.3 8.1-3.7 17.1-7.9c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5l-17.1-7.9-8.1-3.7-.6-.3-88.3-40.8L217 99.1l-.3-.6L213 90.3l-7.9-17.1c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3l-7.9 17.1-3.7 8.1-.3 .6-40.8 88.3L35.1 228.1l-.6 .3-8.1 3.7L9.3 240zm83 14.5l51.2-23.6c10.4-4.8 18.7-13.1 23.5-23.5l23.6-51.2 23.6 51.2c4.8 10.4 13.1 18.7 23.5 23.5l51.2 23.6-51.2 23.6c-10.4 4.8-18.7 13.1-23.5 23.5l-23.6 51.2-23.6-51.2c-4.8-10.4-13.1-18.7-23.5-23.5L92.3 254.6zM384 384l-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 448l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 448l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 384l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L384 384z"
                                        ></path>
                                      </svg>
                                      &nbsp; Generate summary
                                    </button>
                                  )}
                                </div>
                              )}
                              {summary && (
                                <div className="summerycarrd">
                                  <h2>Summary</h2>
                                  <p>{summary}</p>
                                </div>
                              )}
                            </div>

                            <div className="col-12  px-3 pt-0">
                              <div className="px-2 border-bottom">
                                <table className="table_det_canv">
                                  <tr>
                                    <th colSpan={2}>Details</th>
                                  </tr>
                                  <tr>
                                    <td>Document Type</td>
                                    <td>
                                      {clickedFile
                                        ? clickedFile.category_name
                                        : ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Filename</td>
                                    <td>
                                      {clickedFile ? clickedFile.filename : ""}
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>

                            <div className="col-12  px-3 pt-0">
                              <div className="px-2 border-bottom">
                                {contractTerms[clickedFile?._id] && (
                                  <table className="table_det_canv">
                                    <thead>
                                      <tr>
                                        <th colSpan={2}>
                                          Document Standard Fields
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>ACV</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.acv ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Payment Terms</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.payment_terms ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Billing Frequency</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.billing_frequency ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Contract Term</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.contract_term ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Auto Price Increase</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.auto_price_increase ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Price Increase %</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.price_increase_percent ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Contract Start Date</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.contract_start_date
                                            ? new Date(
                                                contractTerms[
                                                  clickedFile._id
                                                ].contract_start_date
                                              ).toLocaleDateString()
                                            : "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Contract End Date</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.contract_end_date
                                            ? new Date(
                                                contractTerms[
                                                  clickedFile._id
                                                ].contract_end_date
                                              ).toLocaleDateString()
                                            : "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Contract End Date (Multi-Year Deal)
                                        </td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.contract_end_date_multi_year
                                            ? new Date(
                                                contractTerms[
                                                  clickedFile._id
                                                ].contract_end_date_multi_year
                                              ).toLocaleDateString()
                                            : "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Auto-Renew</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.auto_renew ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>On Short Order Form</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.on_short_order_form ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Price Increase Cap - Y/N</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.price_increase_cap ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Price Increase Cap Amount</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.price_increase_cap_amount ??
                                            "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Security Terms</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.security_terms ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Customer Signatory</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.customer_signatory ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Main Customer Contact</td>
                                        <td>
                                          {contractTerms[clickedFile._id]
                                            ?.main_customer_contact ?? "N/A"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Offcanvas Sidebar */}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="cards  mt-0 p-4">
                    <h5 className="cards_headings">Requires Action</h5>
                    <table className="task_tabl">
                      <thead>
                        <tr className="th_no_wrps">
                          <th>Select</th>
                          <th>Document name</th>
                          <th>Category</th>
                          <th>Submit Date</th>
                          <th>Risk Score(%)</th>
                          <th>Company Standard</th>
                          <th>Owned by</th>
                          <th className="wid_40pxls text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Loader2 && (
                          <tr>
                            <td colSpan={8}>
                              <BeatLoader
                                color={"#000"}
                                loading={loading}
                                cssOverride={override}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </td>
                          </tr>
                        )}
                        {!Loader2 && fileDetails.length === 0 && (
                          <tr>
                            <td colSpan={7}>
                              <p>No records found.</p>
                            </td>
                          </tr>
                        )}
                        {fileDetails.map((file) => (
                          <React.Fragment key={file._id}>
                            <tr>
                              <td>
                                <input
                                  id="activelistlbls"
                                  type="checkbox"
                                  onChange={() =>
                                    handleFileCheckboxChange(file)
                                  }
                                  checked={selectedFiles.includes(file._id)}
                                />
                                <label
                                  htmlFor="activelistlbls"
                                  className="activelistlbl"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-plus"
                                  >
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-check"
                                  >
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                  </svg>
                                </label>
                              </td>
                              <td
                                className="curs_point"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                                onClick={() => {
                                  handleFileClick(file);
                                  setsummary("");
                                }}
                              >
                                {file.filename}
                              </td>
                              <td>{file.category_name || "N/A"}</td>
                              <td>
                                {new Date(file.added_date).toLocaleDateString()}
                              </td>
                              <td className="btntds">
                                <button
                                  className="btn_td_tabs"
                                  onClick={() => handleRiskClick(file)}
                                >
                                  {file.ai_risk_score != null
                                    ? `Preview Score: ${(
                                        file.ai_risk_score * 100
                                      ).toFixed(2)}%`
                                    : "View Preview Score"}
                                </button>
                              </td>
                              <td>
                                <button className="btn_td_tabs">36.2%</button>
                              </td>
                              <td>{file.user_id?.fname || "Unknown"}</td>
                              <td className="btntds wid_40pxls text-right">
                                <button
                                  className="btn_td_tabs drp_btn_tds dropdown-toggle"
                                  type="button"
                                  id={`actionDropdown-${file._id}`}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-more-vertical"
                                  >
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="12" cy="5" r="1"></circle>
                                    <circle cx="12" cy="19" r="1"></circle>
                                  </svg>
                                </button>
                                <ul
                                  className="dropdown-menu actions_ulss"
                                  aria-labelledby={`actionDropdown-${file._id}`}
                                >
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => handleFixIt(file)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-download"
                                      >
                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                        <polyline points="7 10 12 15 17 10"></polyline>
                                        <line
                                          x1="12"
                                          y1="15"
                                          x2="12"
                                          y2="3"
                                        ></line>
                                      </svg>
                                      Fix It
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => handleSendToCounsel(file)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-send"
                                      >
                                        <line
                                          x1="22"
                                          y1="2"
                                          x2="11"
                                          y2="13"
                                        ></line>
                                        <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                      </svg>
                                      Send to Counsel
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleSendForSignatures(file)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-edit-3"
                                      >
                                        <path d="M12 20h9"></path>
                                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                      </svg>
                                      Send for Signatures
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        handleShowModal2();
                                        setis_password_required(
                                          file.link?.is_password_required
                                        );
                                        setlink_id(file.link?._id);
                                        setfile_id(file._id);
                                        setisURL(!!file.link?.url);
                                        seturl(file.link?.url);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-share-2"
                                      >
                                        <circle cx="18" cy="5" r="3"></circle>
                                        <circle cx="6" cy="12" r="3"></circle>
                                        <circle cx="18" cy="19" r="3"></circle>
                                        <line
                                          x1="8.59"
                                          y1="13.51"
                                          x2="15.42"
                                          y2="17.49"
                                        ></line>
                                        <line
                                          x1="15.41"
                                          y1="6.51"
                                          x2="8.59"
                                          y2="10.49"
                                        ></line>
                                      </svg>
                                      Share
                                    </button>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                            {renderDropdown(file)}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                    <br />
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                    {selectedFiles.length > 0 && (
                      <div className="mt-3">
                        <button
                          className="readline-btn"
                          onClick={handleMoveToReadLine}
                        >
                          Move to ReadLine
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Completed Files Section */}
              {completedFiles.length > 0 && (
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="cards" style={{ width: "80vw" }}>
                      <h3>Completed Files</h3>
                      <table>
                        <thead>
                          <tr>
                            <th>Document name</th>
                            <th>Document Type</th>
                            <th>Submit Date</th>
                            <th>Buyer/Seller</th>
                            <th>Risk Score</th>
                            <th>Owned by</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {completedFiles.map((file) => (
                            <tr key={file._id}>
                              <td>{file.filename}</td>
                              <td>{file.qc_id.name || "N/A"}</td>
                              <td>
                                {new Date(file.added_date).toLocaleDateString()}
                              </td>
                              <td>{file.file_answer_mode || "N/A"}</td>
                              <td>
                                {file.risk_score != null
                                  ? `${(file.risk_score * 100).toFixed(2)}%`
                                  : "N/A"}
                              </td>
                              <td>{file.user_id?.fname || "Unknown"}</td>
                              <td className="btntds">
                                <button
                                  onClick={(e) => handleDownload(file, e)}
                                  disabled={!file.filename}
                                  className={
                                    !file.filename
                                      ? "disabled-button btn_td_tabs"
                                      : "btn_td_tabs"
                                  }
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showRiskModal && selectedFile && (
        <div
          className="modal fade show"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content min-wid-100pr">
              <div
                className="modal-header p-0 border-btm-none"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h5 className="modal-title">
                  AI risk review for {selectedFile.filename}
                </h5>
                <button
                  type="button"
                  className="modle_close_btns"
                  onClick={() => setShowRiskModal(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
              <div className="modal-body px-0">
                {/* Tab Navigation */}
                <ul className="nav nav-tabs airisktables">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        selectedModalTab === "riskSummary" ? "active" : ""
                      }`}
                      onClick={() => setSelectedModalTab("riskSummary")}
                      style={{ color: "black" }}
                    >
                      Risk Summary
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        selectedModalTab === "subcategories" ? "active" : ""
                      }`}
                      onClick={() => setSelectedModalTab("subcategories")}
                      style={{ color: "black" }}
                    >
                      Contract Analysis
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        selectedModalTab === "riskChart" ? "active" : ""
                      }`}
                      onClick={() => setSelectedModalTab("riskChart")}
                      style={{ color: "black" }}
                    >
                      Risk Chart
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${
                        selectedModalTab === "moreInfo" ? "active" : ""
                      }`}
                      onClick={async () => {
                        handleMoreInfoClick(selectedFile);
                        setSelectedModalTab("moreInfo");
                      }}
                      style={{ color: "black" }}
                    >
                      Contract Summary
                    </button>
                  </li>
                </ul>

                {/* Tab Content */}
                <div className="tab-content tables_tab_conteents mt-0 p-0">
                  {isLoadingQAData ? (
                    <div className="text-center">
                      <BeatLoader
                        color={"#000"}
                        loading={isLoadingQAData}
                        size={10}
                      />
                    </div>
                  ) : (
                    renderTabContent()
                  )}
                </div>
              </div>
              <div className="modal-footer border-top-none p-0">
                <button
                  type="button"
                  className="btn_cls_mdl"
                  onClick={() => setShowRiskModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal2 && (
        <>
          <div
            className="modal fade show"
            id="documentModal"
            aria-labelledby="documentModalLabel"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content border-none">
                <div className="modal-body p-0">
                  <div className="container">
                    <div className="row">
                      <div className="col-8">
                        <h2 className="modal-headings pt-1 m-0">
                          Send Document
                        </h2>
                      </div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={() => {
                            setShowModal2(false);
                          }} // Call handleCloseModal to close
                          style={{ marginTop: "0px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3 d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={is_password_required == "yes" ? true : false}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setis_password_required("yes");
                            } else {
                              setis_password_required("no");
                            }
                          }}
                        ></input>
                        <p className="my-0 mx-2 paspro">Password Protected</p>
                      </div>
                      {is_password_required == "yes" && (
                        <div className="col-12 mt-2">
                          <label className="paslbl">Enter Password</label>
                          <input
                            className="nptpass"
                            type="password"
                            onChange={(e) => setpassword(e.target.value)}
                            placeholder="Please enter password"
                          ></input>
                        </div>
                      )}
                      {url && (
                        <div className="col-12 mt-2">
                          <label className="paslbl">Link</label>
                          <div className="linkgen">{url}</div>
                        </div>
                      )}

                      {url && (
                        <div className="col-12 mt-2 text-right">
                          <button
                            onClick={() => {
                              navigator.clipboard
                                .writeText(url)
                                .then(() => {
                                  // Reset after 2 seconds
                                  toast.success("Copied");
                                })
                                .catch((err) =>
                                  console.error("Failed to copy text: ", err)
                                );
                            }}
                            className="copylinks"
                          >
                            Copy Link
                          </button>
                        </div>
                      )}

                      <div className="col-12 mt-2">
                        <button onClick={createLink} className="go-back mx-0">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => {
              setShowModal2(false);
            }}
          ></div>
        </>
      )}

      {/* Citation Modal */}
      {showCitationModal && (
        <div
          className="modal fade show d-flex align-items-center justify-content-center"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Citation</h5>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseCitationModal}
                >
                  <FaTimes />
                </button>
              </div>
              <div className="modal-body">
                <p>{citationContent}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Additional Modals and Backdrops */}
      {showRiskModal || moreInfoContent || showCitationModal ? (
        <div className="modal-backdrop fade show"></div>
      ) : null}

      {/* ... Rest of your modals (e.g., showModal2) ... */}
    </>
  );
};

export default Task;
