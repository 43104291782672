import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import AuthContext from '../AuthContext'
import '../styles/WorkSpace.css'
import { BASE_URL } from '../config'
import DocumentViewer from './DocumentViewer'

export default function WorkSpace() {
  const [files, setFiles] = useState([])
  const [error, setError] = useState('')
  const { userToken, userRole } = useContext(AuthContext)
  const [selectedFile, setSelectedFile] = useState(null)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [showReassignModal, setShowReassignModal] = useState(false)
  const [avalilabeQaAnalyst, setAvalilabeQaAnalyst] = useState('')
  const [avalilabeQa, setAvalilabeQa] = useState('')
  const [selectedQAAnalyst, setSelectedQAAnalyst] = useState('')
  const [selectedQA, setSelectedQA] = useState('')
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [fileDetails, setFileDetails] = useState({})
  const { file_queue } = useParams('')
  // const [fileQueue, setFileQueue] = useState(null)
  // const navigate = useNavigate()

  const fetchFiles = async (page) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/file/list`,
        {
          page: page.toString(),
          size: '10',
          file_queue:
            file_queue === 'queue-completed' ? 'queue-completed' : undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        setFiles(response.data.data)
        setTotalPages(response.data.totalPages)
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to fetch files')
    }
  }
  useEffect(() => {
    // if (file_queue !== fileQueue) {
    //   setFileQueue(file_queue) // Update local state with new URL param
    fetchFiles(page)
    // }
    handleQueueChange('qa-analyst')
    handleQueueChange('qa')
  }, [file_queue, userToken, page])

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1)
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const handleEditClick = (file, e) => {
    e.stopPropagation()
    setSelectedFile(file)
  }

  const handleReassignClick = (file, e) => {
    e.stopPropagation()
    setSelectedFile(file)
    setShowReassignModal(true)
  }

  const handleMoreInfoClick = async (file, e) => {
    e.stopPropagation()
    try {
      const response = await axios.get(
        `${BASE_URL}/global/file-details/${file._id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.status === 200) {
        setFileDetails({
          documentName: file.filename,
          company: file.user_id.company_name,
          fullName: `${file.user_id.fname} ${file.user_id.lname}`,
          emailAddress: file.user_id.email,
          buyerSeller: response.data.data.fileAnswerMode,
          categoryType: response.data.data.categoryName,
          submitDate: new Date(file.added_date).toLocaleDateString(),
        })
        setShowInfoModal(true)
      }
    } catch (error) {
      console.error('Error fetching file details:', error)
      setError('Failed to fetch file details')
    }
  }

  const handleQueueChange = async (queueType) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/qa-admin/admins?admin_type=${queueType}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        if (queueType === 'qa-analyst') {
          setAvalilabeQaAnalyst(
            response.data.data.filter((admin) => admin.admin_status === 1)
          )
        } else if (queueType === 'qa') {
          setAvalilabeQa(
            response.data.data.filter((admin) => admin.admin_status === 1)
          )
        }
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to fetch available QA resources')
    }
  }

  const handleSubmitReassign = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/qa-admin/file/reassign`,
        {
          file_id: selectedFile._id,
          file_queue: 'qa-analyst',
          admin_analyst_id: selectedQAAnalyst || null,
          admin_qa_id: selectedQA || null,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        alert('File reassigned successfully')
        setShowReassignModal(false)
        fetchFiles(page)
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to reassign the file')
    }
  }

  const handleBack = () => {
    setSelectedFile(null)
  }

  // const resetFileQueue = () => {
  //   setFileQueue(null)
  //   navigate('/workspace')
  // }

  const handleReturnToClient = async (file) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/qa-admin/file/reassign`,
        {
          file_id: file._id,
          file_queue: 'back-client', // Set the file queue to "back-client"
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        alert('File returned to client successfully')
        fetchFiles(page) // Refresh the file list
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to return file to client')
    }
  }

  const handleMoveToAdmin = async (file) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/qa-admin/file/reassign`,
        {
          file_id: file._id,
          file_queue: 'qa-admin',
          admin_analyst_id: selectedQAAnalyst || null,
          admin_qa_id: selectedQA || null,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        alert('File returned to client successfully')
        fetchFiles(page) // Refresh the file list
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to return file to client')
    }
  }

  const handleReturnToAnalyst = async (file) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/qa-admin/file/reassign`,
        {
          file_id: file._id,
          file_queue: 'qa-analyst',
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        alert('File returned to analyst successfully')
        fetchFiles(page) // Refresh the file list
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      setError('Failed to return file to client')
    }
  }

  const renderFileActions = (file, section) => {
    if (section === 'toProcess') {
      return (
        <div className="file-actions">
          <button onClick={(e) => handleMoreInfoClick(file, e)}>
            More Info
          </button>
          {userRole === 'qa-admin' ? (
            <button onClick={(e) => handleReassignClick(file, e)}>
              Assign
            </button>
          ) : null}
        </div>
      )
    } else if (section === 'processing') {
      return (
        <div className="file-actions">
          <button onClick={(e) => handleEditClick(file, e)}>Edit</button>
          {userRole === 'qa-admin' ? (
            <button
              onClick={(e) => {
                e.stopPropagation()
                handleReturnToClient(file)
              }}
            >
              Return to Client
            </button>
          ) : null}
          {userRole === 'qa' ? (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  handleReturnToAnalyst(file)
                }}
              >
                Return to Analyst
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  handleMoveToAdmin(file)
                }}
              >
                Move to Admin
              </button>
            </>
          ) : null}
        </div>
      )
    }
  }

  const toProcessFiles = files.filter(
    (file) => file.file_queue === 'qa-assignment'
  )
  const processingFiles = files.filter((file) =>
    ['qa', 'qa-analyst', 'qa-admin', 'queue-completed', 'admin-stage'].includes(
      file.file_queue
    )
  )

  return (
    <div className="workspace">
      <h2>WorkSpace</h2>
      {selectedFile ? (
        <DocumentViewer file={selectedFile} handleBack={handleBack} />
      ) : (
        <>
          {userRole === 'qa-admin' && toProcessFiles.length > 0 && (
            <div className="section">
              <div className="section-head">To Process</div>
              <table className="file-table">
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Submit Date</th>
                    <th>File Queue</th>
                    <th>Company Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {toProcessFiles.map((file) => (
                    <tr key={file._id}>
                      <td>{file.filename}</td>
                      <td>{new Date(file.added_date).toLocaleDateString()}</td>
                      <td>{file.file_queue}</td>
                      <td>
                        {file.user_id ? file.user_id.company_name : 'N/A'}
                      </td>
                      <td>{renderFileActions(file, 'toProcess')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="section">
            <div className="section-head">Processing</div>
            <table className="file-table">
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Submit Date</th>
                  <th>File Queue</th>
                  <th>Company Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {processingFiles.map((file) => (
                  <tr key={file._id}>
                    <td>{file.filename}</td>
                    <td>{new Date(file.added_date).toLocaleDateString()}</td>
                    <td>{file.file_queue}</td>
                    <td>{file.user_id ? file.user_id.company_name : 'N/A'}</td>
                    <td>{renderFileActions(file, 'processing')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={page === 0}>
              Previous
            </button>
            <span>
              Page {page + 1} of {totalPages}
            </span>
            <button onClick={handleNextPage} disabled={page === totalPages - 1}>
              Next
            </button>
          </div>

          {error && <p className="error">{error}</p>}
        </>
      )}

      {showReassignModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Assign File</h3>

            <div style={{ marginBottom: '10px' }}>
              <label style={{ marginRight: '10px' }}>QA Analyst:</label>
              <select
                value={selectedQAAnalyst}
                onChange={(e) => {
                  setSelectedQAAnalyst(e.target.value)
                }}
              >
                <option value="">Select QA Analyst</option>
                {avalilabeQaAnalyst.map((qa) => (
                  <option key={qa.admin_uid} value={qa.admin_uid}>
                    {qa.username}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ marginBottom: '10px' }}>
              <label style={{ marginRight: '10px' }}>QA:</label>
              <select
                value={selectedQA}
                onChange={(e) => {
                  setSelectedQA(e.target.value)
                  handleQueueChange('qa')
                }}
              >
                <option value="">Select QA</option>
                {avalilabeQa.map((qa) => (
                  <option key={qa.admin_uid} value={qa.admin_uid}>
                    {qa.username}
                  </option>
                ))}
              </select>
            </div>

            <button onClick={handleSubmitReassign}>Submit</button>
            <button onClick={() => setShowReassignModal(false)}>Cancel</button>
          </div>
        </div>
      )}

      {showInfoModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>File Details</h3>
            <p>
              <strong>Document Name:</strong> {fileDetails.documentName}
            </p>
            <p>
              <strong>Company:</strong> {fileDetails.company}
            </p>
            <p>
              <strong>Full Name:</strong> {fileDetails.fullName}
            </p>
            <p>
              <strong>Email Address:</strong> {fileDetails.emailAddress}
            </p>
            <p>
              <strong>Buyer/Seller:</strong> {fileDetails.buyerSeller}
            </p>
            <p>
              <strong>Category Type:</strong> {fileDetails.categoryType}
            </p>
            <p>
              <strong>Submit Date:</strong> {fileDetails.submitDate}
            </p>
            <button onClick={() => setShowInfoModal(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  )
}
