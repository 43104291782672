import React, { useState, useEffect, useContext } from "react";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import AuthContext from "../AuthContext";
import { BASE_URL } from "../config";
import "../styles/main.css";
import { BeatLoader } from "react-spinners";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};
const ChartTwo = () => {
  const { userToken } = useContext(AuthContext);
  const { userRole } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({
    estimatedTime: [],
    completedTime: [],
    categories: [],
  });

  useEffect(() => {
    const fetchDocumentTimes = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/${userRole}/files/getTimeToCompleteDocuments`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.status === 1) {
          const estimatedTime = [];
          const completedTime = [];
          const categories = [];

          response.data.data.forEach((document) => {
            estimatedTime.push(parseFloat(document.estimatedTime).toFixed(2));
            completedTime.push(parseFloat(document.completedTime).toFixed(2));
            categories.push(document.documentName);
          });

          setChartData({
            estimatedTime,
            completedTime,
            categories,
          });
        }
      } catch (error) {
        console.error("Error fetching document times:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentTimes();
  }, [userToken, userRole]);

  const options = {
    series: [
      {
        name: "Estimated Time",
        data: chartData.estimatedTime,
      },
      {
        name: "Completed Time",
        data: chartData.completedTime,
      },
    ],
    chart: {
      type: "bar",
      height: 430,
    },
    plotOptions: {
      bar: {
        // horizontal: true,
        // dataLabels: {
        //   position: "top",
        // },
        borderRadius: 10,
        columnWidth: "20px",
      },
    },
    // dataLabels: {
    //   enabled: true,
    //   offsetX: -6,
    //   style: {
    //     fontSize: "12px",
    //     colors: ["#fff"],
    //   },
    // },
    // stroke: {
    //   show: true,
    //   width: 1,
    //   colors: ["#fff"],
    // },
    stroke: {
      width: 0,
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        rotate: -45,
      },
      // tickPlacement: "on",
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "light",
    //     type: "horizontal",
    //     shadeIntensity: 0.25,
    //     gradientToColors: undefined,
    //     inverseColors: true,
    //     opacityFrom: 0.85,
    //     opacityTo: 0.85,
    //     stops: [50, 0, 100],
    //   },
    // },
  };

  // Check if there's any data to display
  const hasData =
    chartData.estimatedTime.length > 0 && chartData.completedTime.length > 0;

  return (
    <div className="chart-wrapper">
      <h5 className="chart-title">
        Estimated Time of Completion for Each Document
      </h5>
      {loading && (
        <BeatLoader
          color={"#000"}
          loading={loading}
          cssOverride={override}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
      {!loading && (
        <div id="chart2" className="chart-container">
          {!hasData && (
            <div className="overlay">
              <p className="overlay-text">No data available</p>
            </div>
          )}
          <div className={hasData ? "" : "blur-chart"}>
            <ApexCharts
              options={options}
              series={options.series}
              type="bar"
              height={430}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartTwo;
