import React from 'react'
import logo from '../img/logo.svg'
import '../styles/Privacy.css'

const PrivacyPolicy = () => {
  return (
    <div
      className="privacy-container"
      style={{ marginLeft: '-400px', width: '1240px' }}
    >
      <img src={logo} alt="modCounsel Logo" className="logo" />
      <h1>Privacy Policy</h1>
      <p style={{ fontSize: '14px' }}>Last modified: May 28, 2024</p>

      <h2>Summary / (TL ; DR)</h2>
      <p>
        mCIO Next, Inc. (“Company,” “modCounsel,” “We,” or “Us”) is committed to
        protecting your privacy rights under this policy (“Policy”).
      </p>
      <p>
        This Policy represents how we respect privacy rights in the collection,
        use, maintenance, protection, storage, and disclosure of protected
        personal information. We do not sell your protected personal
        information. This Policy does not apply to the privacy practices of
        third parties made accessible from this website at{' '}
        <a href="https://www.modcounsel.com">https://www.modcounsel.com</a> and
        related web properties (this “Website”).
      </p>

      <h2>Collection Point / Activity</h2>
      <table>
        <thead>
          <tr>
            <th>Purpose of Use</th>
            <th>Information Collected / Sorted</th>
            <th>Technologies Used</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Website and other digital interaction (e.g., virtual event
              registrations, e-mail, LinkedIn™)(“Digital Interactions”)
            </td>
            <td>
              Browser, internet connection, IP address, location, browsing
              actions equipment used information as indicated on a form Records
              and copies of our correspondence
            </td>
            <td>
              Cookies, Digital forms, Google™ Analytics, Hubspot™ Analytics,
              Netlify™
            </td>
          </tr>
          <tr>
            <td>Account setup information (non-financial)</td>
            <td>
              Professional contact information, title, mailing and delivery
              address, and service notes
            </td>
            <td>Hubspot™ CRM</td>
          </tr>
          <tr>
            <td>Payment on Website or with services</td>
            <td>
              Credit card, banking information, address and payment contact
              information
            </td>
            <td>Paypal™</td>
          </tr>
          <tr>
            <td>
              Through services we provide to our clients (“Client Services”)
            </td>
            <td>
              Personal information such as name, address, phone number, email
              address, job title, employer, payment information, and any other
              personal information you provide or that we collect in connection
              with the provision of Client Services.
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              Through publicly available databases or marketing activities
            </td>
            <td>
              Personal information such as name, address, phone number, email
              address, job title, employer, payment information, and any other
              personal information you provide or that we collect in connection
              with the provision of Client Services.
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <p>
        In addition to the above, We may process your information for any other
        purpose with your consent.
      </p>
      <h2>How We Collect Personal Information</h2>
      <p>
        We collect personal information through your Digital Interactions or use
        of our Client Services.
      </p>
      <p>
        If you do not agree with our policies and practices, your choice is not
        to engage in Digital Interactions or use our Client Services. By
        accessing Digital Interactions or our Client Services, you agree to this
        Policy. This policy may change from time to time (see below: Changes to
        Our Privacy Policy). Your continued use of Digital Interactions or our
        Client Services after we make changes is deemed to be acceptance of
        those changes, so please check the policy periodically for updates.
      </p>

      <h2>Children Under the Age of 13</h2>
      <p>
        Our website and other Digital Interactions are not intended for children
        under 13 years of age or those under 16 years of age (“Audience”). No
        one under the age of 13 or 16 may provide any personal information to
        modCounsel. We do not knowingly collect personal information from
        children under 13 or under 16. If we learn we have collected or received
        personal information from a child under 13 or 16 without verification of
        parental consent, we will delete that information. If you believe we
        might have inadvertently processed any information from or about a child
        under 13 or 16, please contact us at{' '}
        <a href="mailto:email@protected">email@protected</a>.
      </p>

      <h2>Cookies and Other Tracking Technologies</h2>
      <p>
        <strong>Cookies:</strong> A cookie is a small file placed on the hard
        drive of your computer. You may refuse to accept browser cookies by
        activating the appropriate setting on your browser. However, if you
        select this setting, you may be unable to access certain parts of our
        Website. Unless you have adjusted your browser setting so that it will
        refuse cookies, our system will issue cookies when you direct your
        browser to our Website.
      </p>
      <p>
        <strong>Web Beacons:</strong> Pages of our Website may contain small
        electronic files known as web beacons (also referred to as clear gifs,
        pixel tags, and single-pixel gifs) that permit the Company, for example,
        to count users who have visited those pages or for other related website
        statistics.
      </p>

      <h2>Third-Party Use of Cookies and Other Tracking Technologies</h2>
      <p>
        Some content or applications, including advertisements, on the Website
        are served by third-parties, including advertisers, ad networks and
        servers, content providers, and application providers. These third
        parties may use cookies alone or in conjunction with web beacons or
        other tracking technologies to collect information about you when you
        use our website. The information they collect may be associated with
        your personal information or they may collect information, including
        personal information, about your online activities over time and across
        different websites and other online services. They may use this
        information to provide you with interest-based (behavioral) advertising
        or other targeted content.
      </p>

      <h2>Use of Personal Information</h2>
      <p>
        We and our service providers use personal information for legitimate
        business purposes, including:
      </p>
      <ul>
        <li>To provide the Digital Interactions or Client Services.</li>
        <li>To respond to your inquiries and fulfill your requests.</li>
        <li>
          To send administrative information to you, such as information about
          our services.
        </li>
        <li>
          To accomplish other business purposes, including but not limited to:
          analysis used to improve or enhance or expand our services; for
          audits; for fraud and security monitoring purposes; to meet our legal
          and regulatory obligations; to analyze usage trends and other business
          analytics, for determining the effectiveness of our Digital
          Interactions or Client Services; or any other legally valid purpose.
        </li>
      </ul>

      <h2>Disclosure of Your Information</h2>
      <p>
        <strong>
          We may disclose aggregated information about our users and information
          that does not identify any individual without restriction. We may also
          disclose personal information that we collect or you provide as
          described in this Policy:
        </strong>
      </p>
      <ul>
        <li>To our subsidiaries and affiliates.</li>
        <li>
          To contractors, service providers, and other third parties we use to
          support our business and who are bound by contractual obligations to
          keep personal information confidential and use it only for the
          purposes for which we disclose it to them.
        </li>
        <li>
          To a buyer or other successor in the event of a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of Company’s assets, whether as a going concern or as
          part of bankruptcy, liquidation, or similar proceeding, in which
          personal information held by modCounsel about our Website users is
          among the assets transferred.
        </li>
        <li>
          To third parties to market their products or services to you if you
          have consented to these disclosures to the extent you elect to permit
          these under our cookie policy. We contractually require these third
          parties to keep personal information confidential and use it only for
          the purposes for which we disclose it to them.
        </li>
        <li>
          To fulfill the purpose for which you provide it. For example, if you
          ask to join our newsletter or follow us on Twitter™. For any other
          purpose disclosed by us when you provide the information.
        </li>
        <li>With your consent</li>
      </ul>
      <h2>We may also disclose your personal information:</h2>
      <ul>
        <li>
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request.
        </li>
        <li>
          To enforce or apply our terms of use and other agreements, including
          for billing and collection purposes.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of modCounsel, our customers, or others.
          This includes exchanging information with other companies and
          organizations for the purposes of fraud protection and credit risk
          reduction.
        </li>
        <p>
          <strong>
            Choices About How We Use and Disclose Your InformationWe strive to
            provide you with choices regarding the personal information you
            provide to us. We have created mechanisms to provide you with the
            following control over your information:
          </strong>
        </p>
        <li>
          Tracking Technologies and Advertising. You can set your browser to
          refuse all or some browser cookies, or to alert you when cookies are
          being sent.
        </li>
        <li>
          Easy opt-out. If you do not wish to have your email address and other
          contact information used by the Company to promote our own or third
          parties’ products or services, you can opt-out by email at{' '}
          <a href="mailto:email@protected">email@protected</a>. If we have sent
          you a promotional email, you may send us a return email asking to be
          omitted from future email distributions.
        </li>
      </ul>
      <p>
        Access, Correct, or Delete Your InformationDepending on Your
        jurisdiction, You may have certain data subject rights; You may send us
        an email at [email protected] to request access to, correct or delete
        any personal information that you have provided to Us under such
        applicable laws. In some cases, We cannot delete your personal
        information except by also deleting your user account. We may not
        accommodate a request to change information if we believe the change
        would violate any law or legal requirement or cause the information to
        be incorrect.
      </p>
      <p>
        Your California Privacy RightsIf you are a California resident,
        California law may provide you with additional rights regarding our use
        of your personal information. To learn more about your California
        privacy rights, visit https://oag.ca.gov/privacy/ccpa.
      </p>
      <p>
        California’s “Shine the Light” law (Civil Code Section § 1798.83)
        permits users of our App that are California residents to request
        certain information regarding our disclosure of personal information to
        third parties for their direct marketing purposes. To make such a
        request, please send an email to{' '}
        <a href="mailto:email@protected">[email@protected]</a>.
      </p>

      <h2>Data Security</h2>
      <p>
        We have implemented measures designed to secure your personal
        information from accidental loss and from unauthorized access, use,
        alteration, and disclosure. All protected personal information is stored
        on our secure servers behind firewalls with reasonable security
        controls. Any payment transactions are processed by PayPal using their
        secure technology solutions.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to our Website. Any transmission of personal
        information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the Website.
      </p>
      <p>
        Retention of Personal InformationWe retain personal information as long
        as needed or permitted in light of the purpose(s) for which it was
        obtained and consistent with applicable law.
      </p>
      <p>
        Your Personal Information may be stored and processed in any country
        where we have facilities or in which we engage service providers. By
        using the Services you understand that your information will be
        transferred to countries outside of your country of residence, including
        the United States, which may have data protection rules that are
        different from those of your country. In certain circumstances, courts,
        law enforcement agencies, regulatory agencies or security authorities in
        those other countries may be entitled to access your Personal
        Information.
      </p>
      <p>
        Some countries outside of the European Economic Area (the “EEA”) are
        recognized by the European Commission as providing an adequate level of
        data protection according to EEA standards. The UK recognizes the EEA
        and the specified countries as providing an adequate level of data
        protection according to UK standards. For transfers from the EEA or the
        UK to countries not considered adequate by the European Commission or
        the UK government (as applicable), we have put in place adequate
        measures, such as standard contractual clauses adopted by the relevant
        authority, to protect your Personal Information.
      </p>

      <h2>Changes to Our Privacy Policy</h2>
      <p>
        It is our policy to post any changes we make to this Policy at{' '}
        <a href="https://www.modcounsel.com/privacy">
          https://www.modcounsel.com/privacy
        </a>
        . If we make material changes to how we treat our users’ personal
        information, we will notify you through email or a notice on the Website
        home page. You are responsible for ensuring We have an up-to-date active
        and deliverable email address for you, and for periodically visiting our
        Website and this Policy to check for any changes.
      </p>

      <h2>Contact Information</h2>
      <p>
        To ask questions or comment about this privacy policy and our privacy
        practices, contact us at{' '}
        <a href="mailto:email@protected">email@protected</a>. To register a
        complaint or concern, please email{' '}
        <a href="mailto:email@protected">[email@protected]</a>.
      </p>
    </div>
  )
}

export default PrivacyPolicy
