import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import "../styles/Accounts.css";
import AuthContext from "../AuthContext";
import { BASE_URL } from "../config";
import dayjs from "dayjs";
import { BeatLoader } from "react-spinners";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#000",
  justifyContent: "center",
};
const Accounts = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const { userToken, userEmail } = useContext(AuthContext); // Use the AuthContext to get the user token

  // Fetch team members on component mount
  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/team-members`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        });
        const { status, data } = response.data;

        if (status === 1) {
          const filteredData = data.filter(
            (member) => member.email !== userEmail
          );
          setTeamMembers(filteredData); // Set the team members data in state
        } else {
          console.error("Error fetching team members:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching team members:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTeamMembers();
  }, [userToken]);

  const handleRemoveUser = async (user_id) => {
    if (!window.confirm("Are you sure you want to remove this user?")) {
      return;
    }
    try {
      const response = await axios.delete(
        `${BASE_URL}/user/removeUser/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`, // Use the user's token for authorization
          },
        }
      );

      const { status, message } = response.data;
      if (status === 1) {
        // Remove the user from the teamMembers state if successful
        setTeamMembers((prevMembers) =>
          prevMembers.filter((member) => member._id !== user_id)
        );
        alert(message); // Notify user of successful removal
      } else {
        alert(message); // Notify user if there's an issue
      }
    } catch (error) {
      console.error("Error removing team member:", error);
    }
  };

  const handleInviteClick = () => {
    navigate("/invite"); // Redirect to the invite page
  };

  return (
    <div className="wrapper bg-trans px-5 pt-4">
      <div className="content-area bg-trans pt-0">
        <header className="header-flex p-0">
        <h3 className="cards_headings22 w-700  ">
              Team Members
            </h3>
          <button
            className="btn btn-gred btn-in-team"
            type="button"
            onClick={handleInviteClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-users"
            >
              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
            Invite a Team Member
          </button>
        </header>

        <div className="mx-2">
          <div className="team-overview">
         

            <div className="container">
              <div className="row px-0">
                <div className="col-12 px-0">
                  <table className="teamtable mt-0">
                    <tr>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Last Active</th>
                      <th>Actions</th>
                    </tr>
                    {loading && (
                      <tr>
                        <td colSpan={6}>
                          <BeatLoader
                            color={"#000"}
                            loading={loading}
                            cssOverride={override}
                            size={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </td>
                      </tr>
                    )}
                    {!loading && teamMembers.length == 0 && (
                      <tr>
                        <td colSpan={4}>No team member found.</td>
                      </tr>
                    )}
                    {!loading &&
                      teamMembers.map((member, index) => (
                        <tr key={index}>
                          <td>
                            <div className="inititab">
                              <span>
                                {member.fname[0]}
                                {member.lname[0]}
                              </span>
                              <div className="mx-2">
                                <p>
                                  {member.fname} {member.lname}
                                </p>
                                <b>{member.email}</b>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="roletags">
                              {member.permission}
                            </span>
                          </td>
                          <td>
                            {dayjs(member.added_date).format("DD MMM YYYY")}
                          </td>
                          <td>
                            <button
                              className="removebuttns"
                              style={{ backgroundColor: "transparent" }}
                              onClick={() => handleRemoveUser(member._id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trash"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accounts;
