import React, { useContext, useEffect, useState } from "react";
import logosl from "../img/slack.png";
import axios from "axios";
import AuthContext from "../AuthContext";
import { BASE_URL } from "../config";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const Procurement = () => {
  const [showModal, setshowModal] = useState(false);
  const { userToken } = useContext(AuthContext);
  const [questionList, setquestionList] = useState([]);
  const [head_data, sethead_data] = useState("");
  const [dataList, setdataList] = useState([]);
  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/user/discover/get-questions`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.data.status === 1) {
          const list = [];
          for (let index = 0; index < response.data.data.list.length; index++) {
            response.data.data.list[index].answer = "";
            list.push(response.data.data.list[index]);
          }
          setquestionList(list);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSavedQuestionQnswerList();
    fetchQuestion();
  }, []);
  const getSavedQuestionQnswerList = async (query) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/discover/get-saved-question-answer-list`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.status === 1) {
        console.log(response.data);
        sethead_data(response.data.data.head_data);
        setdataList(response.data.data.list);
      } else {
        //   alert(response.data?.data?.message || "Failed to fetch categories");
      }
    } catch (error) {
      // alert("Failed to fetch categories");
    } finally {
    }
  };
  const onSubmit = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/discover/save-question-answers`,
        {
          company_id: "",
          answers: questionList,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data.status === 1) {
        console.log(response.data);
        toast.success(response.data.message);
        setshowModal(false);
        fetchQuestionList();
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchQuestionList = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/discover/get-saved-question-answers`,

        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data.status === 1) {
        console.log(response.data);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="wrapper">
        <div className="content-area px-4 pt-3" style={{ marginLeft: "0px" }}>
          <div className="container-fluid">
            <div className="row pt-3">
              <div className="col-6 px-0">
                <h3 className="discover_headings mt-2">Request</h3>
              </div>
              <div className="col-6 text-right">
                <button
                  className="cre_requ_btn"
                  onClick={() => {
                    setshowModal(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-plus"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                  &nbsp; Create Request
                </button>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 border-bottom px-0">
                <h2 className="purchae_reqest mb-0">Purchase Request</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-4 px-0">
                <div className="card_procrt">
                  <p>Total Request&nbsp;</p>
                  <b>{head_data?.total_requests}</b>
                </div>
              </div>
              <div className="col-4 px-2">
                <div className="card_procrt">
                  <p>Request spend&nbsp;</p>
                  <b>${head_data?.request_spend}</b>
                </div>
              </div>
              <div className="col-4 px-0">
                <div className="card_procrt">
                  <p>No Activity in 30 Days&nbsp;</p>
                  <b>{head_data?.activity_days}</b>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12 px-0">
                <div className="card_procrt">
                  <div className="row">
                    <div className="col-9">
                      <p className="font_18px">Request</p>
                    </div>
                    <div className="col-3">
                      <label className="search_lbl_companies">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-search"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                        <input
                          type="search"
                          placeholder="Search companies"
                        ></input>
                      </label>
                    </div>
                  </div>

                  <table className="proctment_table">
                    <thead>
                      <tr>
                        <th>
                          <div>
                            Information
                            <span>0</span>
                          </div>
                        </th>
                        <th>
                          <div>
                            Negotiation
                            <span>0</span>
                          </div>
                        </th>
                        <th>
                          <div>
                            Approvals and Signatures
                            <span>0</span>
                          </div>
                        </th>
                        <th>
                          <div>
                            Closing
                            <span>0</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {dataList.map((item, key) => (
                            <div className="nigtiaton_cards mb-3">
                              <div>
                                <img src={item.icon}></img>
                                <u>{item.name}</u>
                              </div>
                              <section className="text-left">
                                <p>
                                  Anticipated Spend: ${item.anticipated_spend}{" "}
                                  USD
                                </p>
                                <p>
                                  Target Date:{" "}
                                  {dayjs(item.target_date).format(
                                    "MMM DD, YYYY"
                                  )}
                                </p>
                                <p>Department: {item.department}</p>
                              </section>
                            </div>
                          ))}
                        </td>

                        <td>
                          <div>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-file"
                              >
                                <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                                <polyline points="13 2 13 9 20 9"></polyline>
                              </svg>
                              &nbsp; No Requests
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-file"
                              >
                                <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                                <polyline points="13 2 13 9 20 9"></polyline>
                              </svg>
                              &nbsp; No Requests
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-file"
                              >
                                <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                                <polyline points="13 2 13 9 20 9"></polyline>
                              </svg>
                              &nbsp; No Requests
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}
        {showModal && (
          <>
            <div className="modal  fade show">
              <div className="modal-dialog  modal-xl modal-dialog-centered">
                <div className="modal-content max-heig-modls border-none p-3">
                  <div className="container">
                    <div className="row">
                      <div className="col-6 pt-1">
                        <h6 className="mb-0 retemodal-head">Create Request</h6>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={() => {
                            setshowModal(false);
                          }}
                          style={{ marginTop: "0px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="row pt-3">
                      <div className="col-12">
                        <form className="request_create_form">
                          {questionList.map((item, key) => (
                            <div className="mt-2" key={key}>
                              <label>{item.question}</label>
                              <input
                                type={item.type}
                                onChange={(e) => {
                                  item.answer = e.target.value;
                                }}
                              ></input>
                            </div>
                          ))}

                          <button
                            type="button"
                            onClick={onSubmit}
                            className="btn_sumit_forms"
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Procurement;
