import React, { useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../config'
import '../styles/Auth.css'
import { useNavigate } from 'react-router-dom'

export default function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [step, setStep] = useState(1)
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const navigate = useNavigate() // Initialize useNavigate for redirection

  const handleEmailSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(`${BASE_URL}/user/forgot-password`, {
        email,
      })
      if (response.data.status === 1) {
        setStep(2) // Proceed to OTP verification
        setSuccessMessage('OTP sent to your email.')
      } else {
        setError(response.data.message)
      }
    } catch (err) {
      setError('Failed to send OTP')
    }
  }

  const handleResetPassword = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(`${BASE_URL}/user/reset-password`, {
        email,
        otp,
        newPassword,
      })
      if (response.data.status === 1) {
        setSuccessMessage('Password reset successfully. You can log in now.')
        setTimeout(() => {
          navigate('/') // Redirect to sign-in page after successful reset
        }, 2000) // Optional delay before redirecting
      } else {
        setError(response.data.message)
      }
    } catch (err) {
      setError('Failed to reset password')
    }
  }

  return (
    <div>
      <h2>Forgot Password</h2>
      {error && <p className="error">{error}</p>}
      {successMessage && <p className="success">{successMessage}</p>}
      {step === 1 ? (
        <form onSubmit={handleEmailSubmit}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <button type="submit" className="auth-btn mx-0">
              Send OTP
            </button>
            <button
              type="button"
              className="auth-btn mx-0"
              onClick={() => navigate('/')}
            >
              Close
            </button>
          </div>
        </form>
      ) : (
        <form onSubmit={handleResetPassword}>
          <div className="form-group">
            <label>OTP:</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              placeholder="Enter OTP"
            />
          </div>
          <div className="form-group">
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              placeholder="Enter new password"
            />
          </div>
          <div className="form-group">
            <label>Confirm Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder="Confirm new password"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <button type="submit" className="auth-btn mx-0">
              Reset Password
            </button>
            <button
              type="button"
              className="auth-btn mx-0"
              onClick={() => setStep(1)}
            >
              Back
            </button>
          </div>
        </form>
      )}
    </div>
  )
}
